import clsx from "clsx";
import formStyles from "src/components/styles/form.module.css";
import { Button } from "@k8slens/lds";
import { lens5LoginExpireDate } from "../../../constants";
import TrackedAnchor from "../../TrackedAnchor/TrackedAnchor";
import styles from "./PleaseUpgradeSide.module.css";

export function PleaseUpgradeSide({ continueToLogin }: { continueToLogin: () => void }) {
  return (
    <div className={formStyles.size}>
      <div className={styles.upper}>
        <h1 className={formStyles.title}>Please Upgrade</h1>
      </div>
      <div className={formStyles.middle}>
        <p className={styles.subtitle}>There are two ways how to upgrade the Lens Desktop application:</p>
        <ol className={styles.methods} data-testid="upgrade-methods">
          <li>
            <strong className={styles.upgradeMethod}>Auto Upgrade</strong> &mdash; Open the main menu of Lens Desktop
            application and click &ldquo;
            <span className={styles.checkForUpdates}>Check for updates</span>
            &rdquo;. Proceed to perform the update once prompted.
          </li>
          <li>
            <strong className={styles.upgradeMethod}>Manual Upgrade</strong> &mdash; Download and install the latest
            version of Lens Desktop application from the official Lens website at{" "}
            <TrackedAnchor href="https://k8slens.dev/" rel="noreferrer" target="_blank" className={styles.link}>
              https://k8slens.dev
            </TrackedAnchor>
          </li>
        </ol>
      </div>
      <footer className={clsx(formStyles.bottom, styles.bottom)}>
        {Date.now() < new Date(lens5LoginExpireDate).getTime() && (
          <Button
            discreet
            label="Continue to Login"
            onClick={continueToLogin}
            className={styles.continueToLoginButton}
          />
        )}
        <TrackedAnchor
          href="https://k8slens.dev/"
          rel="noreferrer"
          className={styles.button}
          target="_blank"
          data-testid="manual-upgrade-button"
        >
          Manual Upgrade
        </TrackedAnchor>
      </footer>
    </div>
  );
}
