import queryString from "query-string";
import { useLocation } from "react-router-dom";
import type { LocationState } from "src/App";
import isString from "lodash/isString";
import { isDevelopment, isStagingOrTest } from "src/constants";

const minPortNumber = 1;
const maxPortNumber = 65535;

const isValidRedirectUri = (redirectUri: string | undefined) => {
  if (!isString(redirectUri)) {
    return false;
  }

  for (const whitelist of [
    "/support",
    "/forums",
    "https://checkout.k8slens.dev/auth/callback",
    ...(isStagingOrTest ? ["https://checkout.lc-staging1.staging-k8slens.cloud/auth/callback"] : []),
    ...(isDevelopment ? ["http://localhost:3000/auth/callback"] : []),
  ]) {
    if (redirectUri === whitelist) {
      return true;
    }
  }

  try {
    const redirectUrl = new URL(redirectUri);

    if (
      redirectUrl.origin === window.location.origin &&
      redirectUrl.pathname === "/signin/realms/lensCloud/protocol/openid-connect/auth"
    ) {
      return true;
    }

    if (redirectUrl.hostname === "localhost" && parseInt(redirectUrl.port)) {
      const port = parseInt(redirectUrl.port);

      return minPortNumber <= port && port <= maxPortNumber;
    }
  } catch {
    return false;
  }

  return false;
};

const includeRedirectParameters = (queryParams: queryString.ParsedQuery<string>) => Boolean(queryParams.include_params);

/**
 * Return a whitelisted redirect_uri from window.location.search (?redirect_uri=), or undefined otherwise
 */
export const useRedirectUri = () => {
  const location = useLocation<LocationState | undefined>();
  const queryParams = queryString.parse(location.search);
  const redirectUri = queryParams.redirect_uri?.toString();
  const state = queryParams.state?.toString();
  // These two are from "Lens Forum"
  const sso = queryParams.sso?.toString();
  const sig = queryParams.sig?.toString();

  if (!isValidRedirectUri(redirectUri)) {
    return undefined;
  }

  // In some cases we want to pass additional query parameters in redirect_uri
  // This is only done if include_params query parameter is set
  // Otherwise we return redirect_uri as is
  if (!includeRedirectParameters(queryParams)) {
    // keep the original state in query params
    if (state) {
      return `${redirectUri}?state=${encodeURIComponent(state)}`;
    }

    if (sso && sig) {
      return `${redirectUri}?sig=${encodeURIComponent(sig)}&sso=${encodeURIComponent(sso)}`;
    }

    return redirectUri;
  }

  // Remove redirect_uri and include_params from query params
  // because we don't want to pass them to the redirect_uri,
  // as they're unnecessary in it.
  delete queryParams.redirect_uri;
  delete queryParams.include_params;

  let redirectQueryParams = "";

  // keep rest of the queryParams in redirect uri if any
  if (Object.keys(queryParams).length > 0) {
    redirectQueryParams = `?${queryString.stringify(queryParams)}`;
  }

  const redirectUriWithQueryParams = `${redirectUri}${redirectQueryParams}`;

  return redirectUriWithQueryParams;
};
