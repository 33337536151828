import { useParams } from "react-router-dom";
import { useQuery } from "./useQuery";

export const businessChildInvitationTokenParameter = "businessChildInvitationToken";

/**
 * Get the invitation token from url parameter `businessChildrenInvitationTokenParameter`
 * or search query `?businessChildInvitationToken=value`
 */
export const useBusinessChildInvitationToken = () => {
  const params = useParams<{
    [businessChildInvitationTokenParameter]?: string;
  }>();

  // The invitation key can be defined with "?businessChildInvitationToken=value" in the url
  const query = useQuery();
  // added in `addPassThroughKeys`
  const invitationKeyQueryParameter = query.get(businessChildInvitationTokenParameter);

  const invitationKey = params.businessChildInvitationToken || invitationKeyQueryParameter || "";

  return invitationKey;
};
