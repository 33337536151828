import { useCallback, useContext, useState } from "react";
import clsx from "clsx";
import { Button, LoadingIndicator, Notification } from "@k8slens/lds";

import { useBusiness } from "src/hooks/useBusiness";
import { useAnalytics } from "src/hooks/useAnalytics";

import BusinessIdForm from "src/components/BusinessIdForm/BusinessIdForm";
import Avatar from "src/components/Avatar/Avatar";
import { BusinessContext } from "src/components/Business/Base";

import styles from "./BusinessProfile.module.css";
import { type BusinessUpdate } from "lens-platform-sdk";

const updateButtonLabel = "Save";

const BusinessProfile = () => {
  const { trackButtonClicked } = useAnalytics();
  const { businessId, business: initialBusiness, loading: loadingBusiness } = useContext(BusinessContext);
  const { updateBusiness, errorUpdatingBusiness, updatingBusiness, updateBusinessSuccess } = useBusiness(businessId);
  const [formValid, setFormValid] = useState(false);

  const [businessUpdate, setBusinessUpdate] = useState<BusinessUpdate>();

  const handleChange = useCallback((formData: BusinessUpdate, valid: boolean) => {
    setFormValid(valid);

    setBusinessUpdate(formData);
  }, []);

  const saveBusiness = () => {
    if (!businessUpdate) {
      return;
    }

    trackButtonClicked(updateButtonLabel);

    updateBusiness(businessUpdate);
  };

  return (
    <div className={styles.businessProfile}>
      {errorUpdatingBusiness ? (
        <Notification flashDuration={1000} level="error" message={errorUpdatingBusiness.message} type="flash" />
      ) : null}
      {updateBusinessSuccess ? (
        <Notification flashDuration={1000} level="success" message="Profile updated" type="flash" />
      ) : null}
      <header className={styles.heading}>
        <h2 className={clsx("lds-h2-caps", styles.welcome)}>Profile</h2>
        <p>
          Manage your Lens Business ID <strong>{`(${businessUpdate?.handle || "-"})`}</strong> profile information in
          here.
        </p>
      </header>
      <section className={styles.content}>
        {initialBusiness && (
          <>
            <BusinessIdForm
              title="Edit your new Lens Business ID details."
              onChange={handleChange}
              initialData={initialBusiness}
              singleColumn
            />
            <div className={styles.editAvatar}>
              <span>Profile Image</span>
              <Avatar name={businessUpdate?.name || initialBusiness.name} className={styles.avatar} />
            </div>
            <div>
              <Button
                disabled={!formValid}
                loading={loadingBusiness || updatingBusiness}
                label={updateButtonLabel}
                id="save"
                onClick={saveBusiness}
                primary
              />
            </div>
          </>
        )}
      </section>
      {(updatingBusiness || loadingBusiness) && <LoadingIndicator size="xxl" className={styles.loadingIndicator} />}
    </div>
  );
};

export default BusinessProfile;
