import { useCallback, useState } from "react";
import clsx from "clsx";
import { useKeycloak } from "@react-keycloak/web";
import { Button, LoadingIndicator, ButtonBar, Notification } from "@k8slens/lds";
import { misc } from "@k8slens/lds-icons";

import { useAnalytics } from "src/hooks/useAnalytics";

import styles from "./Feedback.module.css";

const { ThumbDownIcon, ThumbUpIcon } = misc;

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  category: string;
  showSpinner?: boolean;
}

const Feedback: React.FC<Props> = ({ category, title, showSpinner, ...props }) => {
  const { keycloak } = useKeycloak();
  const { trackFeedback } = useAnalytics();

  const userId = keycloak.tokenParsed?.sub;

  const loading = !userId;

  const [error, setError] = useState<string | null>(null);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const sendFeedback = useCallback(
    (type: "positive" | "negative") => {
      setError(null);

      if (feedbackSent || !userId) {
        setError("Something went wrong. Please try again later.");

        return false;
      }

      try {
        trackFeedback({ category, type });

        setFeedbackSent(true);

        return true;
      } catch (err) {
        setError("Something went wrong. Please try again later.");

        return false;
      }
    },
    [feedbackSent, userId, category, trackFeedback],
  );

  const sendPositiveFeedback = () => sendFeedback("positive");
  const sendNegativeFeedback = () => sendFeedback("negative");

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={clsx(styles.feedback, props?.className, {
        [styles.loading]: loading,
        [styles.thankYou]: feedbackSent,
      })}
    >
      {error !== null && (
        <Notification
          className={styles.error}
          level="error"
          type="flash"
          message={error}
          onClose={() => setError(null)}
        />
      )}
      {feedbackSent && "Thank you for your feedback!"}
      {!feedbackSent && (
        <>
          <div>{title}</div>
          <ButtonBar type="grid">
            <Button
              disabled={feedbackSent}
              type="ok"
              label="Good"
              icon={ThumbUpIcon}
              onClick={sendPositiveFeedback}
              discreet
            />
            <Button
              disabled={feedbackSent}
              type="danger"
              label="Bad"
              icon={ThumbDownIcon}
              onClick={sendNegativeFeedback}
              discreet
            />
          </ButtonBar>
        </>
      )}
      {showSpinner && loading && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

export default Feedback;
