import { BillingInfo, LensSDKException } from "lens-platform-sdk";
import { misc } from "@k8slens/lds-icons";
import { openUrl } from "src/utils/url";
import { Notification } from "@k8slens/lds";

import TrackedButton from "src/components/Button/TrackedButton";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useHistory } from "react-router-dom";
import { getUpdateBillingDetailUrl } from "src/services/billing/billingUrl";
import { useContext, useState } from "react";
import styles from "./BillingDetails.module.css";
import { BusinessContext } from "src/components/Business/Base";

const { CreditCardIcon, ReceiptIcon } = misc;

const formatValidity = (paymentMethod: BillingInfo["paymentMethod"]) =>
  paymentMethod.expYear && paymentMethod.expMonth
    ? `${paymentMethod.expMonth}/${paymentMethod.expYear?.toString().slice(2, 4)}`
    : "n/a";
const formatValidityLong = (paymentMethod: BillingInfo["paymentMethod"]) =>
  paymentMethod.expYear && paymentMethod.expMonth
    ? new Date(
        // We need to set type as number because it was set as Number in the SDK
        paymentMethod.expYear as number,
        (paymentMethod.expMonth as number) - 1,
      ).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: undefined,
      })
    : "";

export interface BillingProps {
  billingInfo?: BillingInfo;
  billingInfoUrl: string | undefined;
}

export const getCreditCardNumberDisplayString = ({ paymentMethod }: BillingInfo) => {
  if (paymentMethod?.lastFour) {
    return `${paymentMethod.cardType} ***${paymentMethod?.lastFour}`;
  }

  return `${paymentMethod.cardType} ${paymentMethod?.firstSix}***`;
};

export const getCreditCardAreaLabelText = ({ paymentMethod }: BillingInfo) => {
  let creditCardNumber = "";

  if (paymentMethod?.lastFour) {
    creditCardNumber = `ending with ${paymentMethod.lastFour}`;
  } else if (paymentMethod.firstSix) {
    creditCardNumber = `starting with ${paymentMethod.firstSix}`;
  }

  return `${paymentMethod.cardType} ${creditCardNumber}, expiring on ${formatValidityLong(paymentMethod)}.`;
};

export const getAddressString = (address?: BillingInfo["address"]) => {
  if (address) {
    return [address.street1, address.street2, address.city, address.region, address.postalCode, address.country]
      .filter((x) => x)
      .join(", ");
  }

  return "";
};

export const Billing = ({ billingInfo, billingInfoUrl }: BillingProps) => {
  const history = useHistory();
  const lensPlatformClient = useLensPlatformClient();
  const { businessId, business } = useContext(BusinessContext);
  const [error, setError] = useState<LensSDKException>();

  const updatePaymentMethod = () => {
    if (!billingInfoUrl) {
      if (!businessId) {
        history.push("/");

        return;
      }
      lensPlatformClient.business
        .createBillingPageToken(businessId)
        .then(({ hostedLoginToken }) => {
          const updateBillingDetailUrl = getUpdateBillingDetailUrl(hostedLoginToken);

          openUrl(updateBillingDetailUrl, true);
        })
        .catch((error: any) => {
          setError(error);
        });

      return;
    }
    openUrl(billingInfoUrl, true);
  };

  const addressString = getAddressString(billingInfo?.address);

  return (
    <div>
      {billingInfo?.paymentMethod?.cardType && (
        <div className={styles.creditCard}>
          <CreditCardIcon className={styles.paymentType} size="xxl" />
          <div className={styles.creditCardDetails}>
            <span aria-label={getCreditCardAreaLabelText(billingInfo)}>
              {getCreditCardNumberDisplayString(billingInfo)}
            </span>
            <span aria-hidden>Valid until: {formatValidity(billingInfo.paymentMethod)}</span>
          </div>
        </div>
      )}
      {!billingInfo?.paymentMethod?.cardType && business?.external ? (
        <div className={styles.paperInvocing}>
          <ReceiptIcon className={styles.paymentType} size="xxl" />{" "}
          <div className={styles.paperInvocingDetails}>
            <strong>Paper Invoicing</strong>
            {addressString ? <span className={styles.address}>{addressString}</span> : null}
          </div>
        </div>
      ) : null}
      {error ? (
        <Notification
          level="error"
          message={error?.message ?? "unknown"}
          type="flash"
          className={styles.errorNotification}
        />
      ) : null}
      {business?.external ? (
        <TrackedButton
          label="Update payment method"
          disabled={true}
          className={styles.updatePaymentMethodButton}
          title="Cannot change payment method for this account. Please contact your sales representative to modify the payment method."
        />
      ) : (
        <TrackedButton
          label="Update payment method"
          onClick={updatePaymentMethod}
          className={styles.updatePaymentMethodButton}
        />
      )}
    </div>
  );
};
