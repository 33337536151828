import type { Step } from "@k8slens/lds-tips/lib/es/Tips/Steps";

import { TipContent } from "./TipContent";

import styles from "./Tip.module.css";

export const manageSeatsStepId = "manage-seats";
export const manageSeatsTipId = `${manageSeatsStepId}-step`;

export const ManageSeatsStep: Step = {
  id: manageSeatsStepId,
  selector: `#${manageSeatsTipId}`,
  theme: "important",
  pointerClassName: styles.pointer,
  popupClassName: styles.popup,
  placement: "right-end",
  pointerOffset: [0, -5],
  content: (
    <TipContent
      title="Manage Seats For Your Team!"
      content={[
        "After creating a Subscription you should assign your team Members to its seats. Go here to get started assigning and managing seats for your team.",
      ]}
    />
  ),
};
