import { useParams } from "react-router-dom";
import { encodeBusinessInvitationKey } from "src/services/businessIdInvitationKey";
import { useQuery } from "./useQuery";

export const businessInvitationKeyParameter = "business-invitation-key";

/**
 * Get the invitation id from query string parameter businessInvitationId
 * and separate query string parameters businessId and company
 */
export const useBusinessInvitationKey = () => {
  // First look for data in query

  const query = useQuery();
  const params = useParams<
    | {
        businessInvitationKey: string;
      }
    | undefined
  >();

  const businessInvitationKeyRouteParam = params?.businessInvitationKey;

  const subscriptionId = query.get("subscriptionId");

  // request is about subscription activation if subscriptionId is present. Ignoring these requests
  if (subscriptionId) {
    return "";
  }

  const invitationKeyQueryParameter = query.get(businessInvitationKeyParameter);

  const invitationKey = invitationKeyQueryParameter || businessInvitationKeyRouteParam || "";

  if (invitationKey) {
    return invitationKey;
  }

  return encodeBusinessInvitationKey({
    invitationId: query.get("invitationId"),
    company: query.get("company"),
    businessId: query.get("businessId"),
  });
};
