import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import Page from "src/components/Page/Page";
import ZendeskSSO from "./ZendeskSSO";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";

const Support = () => {
  const keycloakTimeout = useKeycloakTimeout();

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  return (
    <Page>
      <ZendeskSSO />
    </Page>
  );
};

export default Support;
