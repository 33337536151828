import clsx from "clsx";
import { LoadingIndicator, SideNavGroup } from "@k8slens/lds";
import { type Business, type UserWithEmail } from "lens-platform-sdk";

import { manageSeatsTipId } from "src/components/Tips/manage-seats";

import MainNavigation from "src/components/MainNavigation/MainNavigation";
import MainNavLink from "src/components/MainNavigation/MainNavigationItem";

import styles from "./Layout.module.css";
import { useContext, useEffect } from "react";
import { TipsContext } from "src/providers/tip-provider";
import { SideNavSettings } from "./SideNavSettings";

interface Props {
  className?: string;
  children: React.ReactNode;
  logout: () => void;
  profile: UserWithEmail | null;
  businessId?: string;
  businessRequestCount?: number;
  business?: Business;
}

export default function Layout({
  className,
  children,
  logout,
  profile,
  business,
  businessId,
  businessRequestCount,
}: Props) {
  const { setStepIsVisible } = useContext(TipsContext);

  useEffect(() => {
    setStepIsVisible?.(manageSeatsTipId, true);
  }, [setStepIsVisible]);

  if (!profile) {
    return (
      <div className={clsx(styles.layout, className)}>
        <LoadingIndicator size="xxl" />
      </div>
    );
  }

  return (
    <div className={clsx(styles.layout, className)}>
      <MainNavigation
        profile={profile}
        logout={logout}
        className={styles.sidebar}
        business={business}
        businessId={businessId}
      >
        <SideNavGroup aria-label="Navigation" className={styles.homeLink}>
          <MainNavLink to={businessId ? `/business/${businessId}` : "/home"} title="Home" exact />
        </SideNavGroup>
        <SideNavSettings business={business} businessId={businessId} businessRequestCount={businessRequestCount} />
      </MainNavigation>
      <main className={styles.main}>{children}</main>
    </div>
  );
}
