import { Button } from "@k8slens/lds";

type Props = {
  navDisabled: boolean;
  isLoading: boolean;
  errorMessage?: string;
  submitButtonLabel: string;
};

const SubmitButton = ({ navDisabled, isLoading, submitButtonLabel, errorMessage }: Props) => (
  <Button
    buttonType="submit"
    disabled={isLoading || navDisabled}
    label={submitButtonLabel}
    loading={isLoading}
    type={errorMessage ? "danger" : "default"}
    primary
  />
);

export default SubmitButton;
