import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

import styles from "./PublicLayout.module.css";
import { base } from "@k8slens/lds-icons";

const { LogoIcon } = base;

type Size = "sm" | "md" | "lg" | "xl";

interface Props {
  title?: string;
  className?: string;
  footer?: ReactNode;
  size?: Size;
}

const PublicLayout = ({
  title = "Lens ID",
  className = "",
  size = "lg",
  footer,
  children,
}: PropsWithChildren<Props>) => (
  <div className={clsx(styles.layout, className, size)}>
    <header>
      <h1 className={styles.logo}>
        <LogoIcon color="accent" />
        <span className={styles.logoText}>{title}</span>
      </h1>
    </header>
    <div className={styles.wrapper}>
      <main>{children}</main>
      {footer && <footer role="contentinfo">{footer}</footer>}
    </div>
  </div>
);

export default PublicLayout;
