import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Panel, ButtonBar, Notification } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";
import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import { Business } from "lens-platform-sdk";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedLinkButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import CurrentUser from "src/components/CurrentUser/CurrentUser";

import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";
import { useBusinessChildInvitationToken } from "src/hooks/useBusinessChildInvitationToken";
import { NewBusiness, useBusiness } from "src/hooks/useBusiness";
import CreateBusinessModal from "src/components/MainNavigation/CreateBusinessModal";
import OpenModalButton from "src/components/MainNavigation/OpenModalButton";
import IDSelectItem from "src/components/MainNavigation/IDSelectItem";

import Select from "src/components/Select/Select";
import { useHistory } from "react-router-dom";
import IDSelectStyles from "src/components/MainNavigation/IDSelect.module.css";
import styles from "./AcceptBusinessChildrenInvitation.module.css";

const { ArrowDownIcon } = navigation;

/**
 * The shape of the option data in the selector
 */
interface Option {
  id: Business["id"];
  label: Business["name"];
}

export const AcceptBusinessChildrenInvitation = () => {
  const history = useHistory();
  const { acceptChildInvitation, errorAcceptingChildInvitation, acceptingChildInvitationSuccess } =
    useBusinessChildInvitation();
  const token = useBusinessChildInvitationToken();
  const {
    businesses,
    loadingBusinesses,
    createBusiness,
    creatingBusiness,
    createBusinessSuccess,
    newlyCreatedBusiness,
    errorCreatingBusiness,
  } = useBusiness();

  const selectButtonRef = useRef<HTMLButtonElement>(null);

  const [selected, setSelected] = useState<Option>({
    id: "loading",
    label: "loading",
  });
  const options = useMemo<Option[]>(
    () =>
      businesses.length > 0
        ? businesses
            .map(({ id, name }) => ({ id, label: name }))
            .filter(({ id }) => id !== selected?.id)
            // sort by label alphabetically
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [businesses, selected],
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setCreateModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  // select the first business on mount
  useEffect(() => {
    if (businesses.length > 0 && selected.id === "loading") {
      setSelected({
        id: businesses[0].id,
        label: businesses[0].name,
      });
    }
  }, [businesses, selected]);

  useEffect(() => {
    if (newlyCreatedBusiness && createBusinessSuccess) {
      setSelected({
        id: newlyCreatedBusiness.id,
        label: newlyCreatedBusiness.name,
      });
    }
  }, [createBusinessSuccess, newlyCreatedBusiness]);

  const handleAccept = () => {
    if (!selected) {
      throw new Error("No business selected");
    }
    acceptChildInvitation({ token, childBusinessId: selected.id });
  };

  useEffect(() => {
    if (acceptingChildInvitationSuccess === true && selected?.id) {
      history.push(`/business/${selected.id}/account`);
    }
  }, [acceptingChildInvitationSuccess, selected, history]);

  return (
    <PublicLayout size="xl" className={styles.acceptInvitation} footer={<CurrentUser />}>
      <Panel
        className={styles.panel}
        header={
          <>
            <h2>Accept Invitation</h2>
          </>
        }
        footer={
          <ButtonBar type="grid" gridSize={4}>
            <LinkButton to="/home" label="Cancel" discreet />
            <div />
            <div />
            <Button
              label="Accept"
              onClick={handleAccept}
              disabled={loadingBusinesses || !selected || acceptingChildInvitationSuccess}
              loadingStateOnPromise
              primary
            />
          </ButtonBar>
        }
      >
        <>
          {errorAcceptingChildInvitation && (
            <div className="mt-2 mb-5">
              <Notification level="error" message={errorAcceptingChildInvitation.message} type="flash" />
            </div>
          )}
          {businesses.length > 0 ? (
            <>
              <p>
                You have been invited to join a Lens Business ID as a child account. Select or create a Lens Business ID
                to accept this invitation.
              </p>
              <div className="sm:mx-40 mx-auto">
                <Select
                  className={IDSelectStyles.idSelect}
                  optionsClassName={IDSelectStyles.options}
                  onChange={(value) => {
                    setSelected(value);
                  }}
                  loading={loadingBusinesses}
                  value={selected}
                  options={options}
                  renderButton={(item, open) => (
                    <Listbox.Button
                      ref={selectButtonRef}
                      className={clsx(
                        IDSelectStyles.item,
                        {
                          [styles.selectOpen]: open,
                        },
                        styles.resetItem,
                      )}
                      data-testid="id-select-button"
                    >
                      <IDSelectItem item={item} />
                      <ArrowDownIcon className={IDSelectStyles.dropDownIcon} />
                    </Listbox.Button>
                  )}
                  buttonRef={selectButtonRef}
                  optionClassName={(state) =>
                    clsx({
                      [IDSelectStyles.selected]: state?.selected,
                      [IDSelectStyles.active]: state?.active,
                    })
                  }
                  renderOption={(option) => <IDSelectItem item={option} />}
                  renderAfterLastOption={() => <OpenModalButton handleClick={() => openModal()} />}
                  renderEmptyOption={() => (
                    <OpenModalButton
                      handleClick={() => {
                        openModal();
                      }}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <>
              <p>
                You have been invited to join a Lens Business ID as a child account. You do not have any Lens Businesses
                ID yet. Please create a Lens Business ID to accept this invitation.{" "}
              </p>
              <ButtonBar type="grid" className="sm:w-max mx-auto">
                <Button label="Create Lens Business ID" onClick={() => openModal()} primary />
              </ButtonBar>
            </>
          )}
          <CreateBusinessModal
            open={createModalOpen}
            disabled={creatingBusiness}
            loading={creatingBusiness}
            error={errorCreatingBusiness}
            onModalClose={() => {
              closeModal();
            }}
            onClickCreate={(business) => {
              createBusiness(business as NewBusiness);
              closeModal();
            }}
          />
        </>
      </Panel>
    </PublicLayout>
  );
};
