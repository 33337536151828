import type { CellContext, ColumnMeta } from "@tanstack/react-table";
import clsx from "clsx";
import { misc, base } from "@k8slens/lds-icons";
import { Popover } from "@headlessui/react";
import { type BusinessSubscription } from "lens-platform-sdk";

import { dateToString } from "src/utils/date";

import styles from "./QuantityCell.module.css";

const { LockIcon } = misc;
const { InfoIcon } = base;

const QuantityCell = <T extends BusinessSubscription, R extends number>({
  getValue,
  row,
  column,
  isUpgradable,
  lockedText,
}: CellContext<T, R> & { isUpgradable: boolean; lockedText: string }) => {
  const { meta } = column.columnDef;
  const subscription = row.original;

  return (
    <div
      className={clsx(styles.quantity, {
        [styles.center]: meta?.align === "center",
        [styles.end]: meta?.align === "end",
      })}
    >
      {isUpgradable ? null : (
        <span className={styles.locked} title={lockedText}>
          <LockIcon size="sm" />
        </span>
      )}
      <span>{getValue()}</span>
      {subscription?.pendingChange?.quantity && subscription?.pendingChange?.activateAt && (
        <span className={styles.pendingChange}>
          <Popover>
            <Popover.Button className={styles.pendingChangeButton}>
              <InfoIcon size="sm" color="caution" />
            </Popover.Button>
            <Popover.Panel className={styles.pendingChangePopoverContent}>
              {subscription?.pendingChange?.quantity} from{" "}
              {dateToString(new Date(subscription?.pendingChange?.activateAt))}
            </Popover.Panel>
          </Popover>
        </span>
      )}
    </div>
  );
};

export const quantityMeta: ColumnMeta<BusinessSubscription, number> | undefined = { align: "end" };

export default QuantityCell;
