import { useContext, useMemo, useReducer, useState } from "react";
import { BusinessSsoOidcDto, BusinessSsoSamlDto, SSOType } from "lens-platform-sdk";
import { ssoTypeOptions, useBusinessSSO } from "src/hooks/useBusinessSSO";
import { Notification } from "@k8slens/lds";
import {
  type SAMLFormFieldKey,
  type SAMLFormField,
  SamlSSOProviderForm,
} from "src/components/SamlSSOForm/SamlSSOProviderForm";
import {
  type OIDCFormFieldKey,
  type OIDCFormField,
  OIDCSSOProviderForm,
} from "src/components/OIDCSSOForm/OIDCSSOProviderForm";

import Select from "src/components/Select/Select";

import { BusinessSSOInfo } from "./BusinessSSOInfo";

import { BusinessContext } from "./Base";
import { BusinessSSOEmailAddressDomain, unSelected } from "./BusinessSSOEmailAddressDomain";
import { ImportSAML } from "src/components/SamlSSOForm/ImportSAML";
import { ImportOIDC } from "src/components/OIDCSSOForm/ImportOIDC";
import { BusinessSSOTypeSelectWrapper } from "./BusinessSSOTypeSelect";
import { BusinessSSOUrl } from "./BusinessSSOUrl";
import { useBusiness } from "src/hooks/useBusiness";

const inputDefault = { value: "", valid: false };

const samlReducer = (
  state: SAMLFormField,
  {
    key,
    ...value
  }: {
    key: SAMLFormFieldKey;
    value: SAMLFormField[SAMLFormFieldKey]["value"];
    valid: boolean;
  },
) => ({
  ...state,
  [key]: value,
});

const oidcReducer = (
  state: OIDCFormField,
  {
    key,
    ...value
  }: {
    key: OIDCFormFieldKey;
    value: OIDCFormField[OIDCFormFieldKey]["value"];
    valid: boolean;
  },
) => ({
  ...state,
  [key]: value,
});

export const BusinessSSO = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { updateBusiness, errorUpdatingBusiness } = useBusiness(businessId);
  const { businessSSO, createBusinessSSO, errorCreatingBusinessSSO } = useBusinessSSO(businessId);
  const [selectedSSOType, setSelectedSSOType] = useState(ssoTypeOptions[0]);
  const [selectedVerifiedDomain, setSelectedVerifiedDomain] = useState(unSelected);

  const [samlfields, samlDispatch] = useReducer(samlReducer, {
    samlSSOServiceUrl: inputDefault,
    samlSSOIdpEntityId: inputDefault,
  });

  const [oidcfields, oidcDispatch] = useReducer(oidcReducer, {
    authorizationUrl: inputDefault,
    tokenUrl: inputDefault,
    jwksUrl: inputDefault,
    userInfoUrl: inputDefault,
    logoutUrl: inputDefault,
    issuer: inputDefault,
    clientSecret: inputDefault,
    clientId: inputDefault,
    oidcDiscoveryUrl: inputDefault,
  });

  const addBusinessSSO = (settings: BusinessSsoSamlDto | BusinessSsoOidcDto) => {
    if (!businessId) {
      return;
    }

    createBusinessSSO({
      businessId,
      ssoSettings: {
        config: settings,
      },
    });
  };

  const verifiedDomains = useMemo(() => business?.verifiedDomains || [], [business?.verifiedDomains]);

  // true if the selected verified domain is a new one
  const newVerifiedDomain: boolean =
    !verifiedDomains.map(({ domain }) => domain).includes(selectedVerifiedDomain.id) &&
    selectedVerifiedDomain.id !== unSelected.id;

  return (
    <>
      {errorCreatingBusinessSSO && (
        <Notification level="error" message={errorCreatingBusinessSSO.message} type="closable" className="my-5" />
      )}
      {errorUpdatingBusiness && (
        <Notification level="error" message={errorUpdatingBusiness.message} type="closable" className="my-5" />
      )}
      <BusinessSSOInfo />
      {!businessSSO && (
        <div className="mt-10">
          <section>
            <BusinessSSOEmailAddressDomain
              selectedVerifiedDomain={selectedVerifiedDomain}
              setSelectedVerifiedDomain={setSelectedVerifiedDomain}
            />
          </section>
          <section className="mt-8">
            <BusinessSSOTypeSelectWrapper>
              <Select
                value={selectedSSOType}
                onChange={(value) => {
                  setSelectedSSOType(value);
                }}
                options={ssoTypeOptions}
              />
              {selectedSSOType.id === SSOType.SAML ? <ImportSAML dispatch={samlDispatch} /> : null}
              {selectedSSOType.id === SSOType.OIDC ? (
                <ImportOIDC dispatch={oidcDispatch} oidcDiscoveryUrl={oidcfields.oidcDiscoveryUrl.value} />
              ) : null}
            </BusinessSSOTypeSelectWrapper>
          </section>
          <section>
            <BusinessSSOUrl ssoType={selectedSSOType.id} />
          </section>
          {selectedSSOType.id === SSOType.SAML ? (
            <SamlSSOProviderForm
              onSubmit={() => {
                addBusinessSSO({
                  singleSignOnServiceUrl: samlfields.samlSSOServiceUrl.value,
                  idpEntityId: samlfields.samlSSOIdpEntityId.value,
                  type: SSOType.SAML,
                });

                updateBusiness({
                  ssoAutoJoin: true,
                });

                if (newVerifiedDomain) {
                  updateBusiness({
                    verifiedDomains: [
                      {
                        domain: selectedVerifiedDomain.id,
                      },
                    ],
                  });
                }
              }}
              samlSSOServiceUrl={samlfields.samlSSOServiceUrl}
              dispatch={samlDispatch}
              samlSSOIdpEntityId={samlfields.samlSSOIdpEntityId}
            />
          ) : null}
          {selectedSSOType.id === SSOType.OIDC ? (
            <OIDCSSOProviderForm
              fields={oidcfields}
              dispatch={oidcDispatch}
              onSubmit={() => {
                addBusinessSSO({
                  authorizationUrl: oidcfields.authorizationUrl.value,
                  tokenUrl: oidcfields.tokenUrl.value,
                  jwksUrl: oidcfields.jwksUrl.value,
                  userInfoUrl: oidcfields.userInfoUrl.value,
                  logoutUrl: oidcfields.logoutUrl.value,
                  issuer: oidcfields.issuer.value,
                  clientSecret: oidcfields.clientSecret.value,
                  clientId: oidcfields.clientId.value,
                  type: SSOType.OIDC,
                });

                updateBusiness({
                  ssoAutoJoin: true,
                });

                if (newVerifiedDomain) {
                  updateBusiness({
                    verifiedDomains: [
                      {
                        domain: selectedVerifiedDomain.id,
                      },
                    ],
                  });
                }
              }}
            />
          ) : null}
        </div>
      )}
    </>
  );
};
