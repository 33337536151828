import { NumberBadge, SideNavGroup } from "@k8slens/lds";
import { type Business } from "lens-platform-sdk";

import { useBillingPageUrl, useBusinessBillingPageUrl } from "src/hooks/useBillingPageUrl";
import { useGetLicense } from "src/hooks/useGetLicense";
import { features } from "src/utils/license";
import { manageSeatsTipId, manageSeatsStepId } from "src/components/Tips/manage-seats";

import MainNavLink from "src/components/MainNavigation/MainNavigationItem";

import styles from "./Layout.module.css";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { useContext, useEffect } from "react";
import { TipsContext } from "src/providers/tip-provider";
import { useUsername } from "src/hooks/useUsername";

interface Props {
  businessId?: string;
  businessRequestCount?: number;
  business?: Business;
}

export const SideNavSettings = ({ business, businessId, businessRequestCount }: Props) => {
  const username = useUsername();
  const isExternal = business?.external === true;
  const { setStepIsVisible, skipStepIfActive } = useContext(TipsContext);
  // No need to fetch urls for user if we have businessId
  const billingPageUser = businessId ? undefined : username;

  const { accountUrl: userAccountUrl } = useBillingPageUrl(billingPageUser);
  const { accountUrl: businessAccountUrl } = useBusinessBillingPageUrl(businessId);
  const accountUrl = businessAccountUrl || userAccountUrl;
  const license = useGetLicense();

  const { businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(businessSubscriptions);
  const shouldShowBusinessAuth = enterpriseFeaturesEnabled;

  useEffect(() => {
    setStepIsVisible?.(manageSeatsTipId, true);
  }, [setStepIsVisible]);

  const isSupportEnabled = license?.features?.includes?.(features.support);

  return (
    <SideNavGroup label="Settings">
      <MainNavLink to={businessId ? `/business/${businessId}/profile` : "/profile"} title="Profile" />
      {!businessId && <MainNavLink to="/account" title="Account" />}
      {!businessId && <MainNavLink to="/connected-accounts" title="Connected Accounts" />}
      {shouldShowBusinessAuth ? <MainNavLink to={`/business/${businessId}/auth`} title="Authentication" /> : null}
      {businessId && <MainNavLink to={`/business/${businessId}/feature-flag-settings`} title="Feature Management" />}
      {businessId ? <MainNavLink to={`/business/${businessId}/billing-details`} title="Billing Details" /> : null}
      {!businessId && (accountUrl || isExternal) ? <MainNavLink to="/billing-details" title="Billing Details" /> : null}
      {businessId ? null : <MainNavLink to="/emails" title="Emails" />}
      {businessId ? (
        <MainNavLink
          to={`/business/${businessId}/users`}
          title={
            <span
              className={styles.businessUsers}
              id={manageSeatsTipId}
              onClick={() => {
                skipStepIfActive?.(manageSeatsStepId);
              }}
            >
              <span>Users & Seats</span>
              <NumberBadge
                className={styles.badge}
                type="error"
                value={businessRequestCount}
                title={`${businessRequestCount} pending request(s).`}
              />
            </span>
          }
        />
      ) : null}
      {businessId ? (
        <MainNavLink to="/support" title="Support" />
      ) : (
        <MainNavLink to="/support" title="Support" disabled={!isSupportEnabled} />
      )}
    </SideNavGroup>
  );
};
