import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import { type Props } from "@k8slens/lds/lib/es/Button/shared.d";

import styles from "./LinkButton.module.css";

export interface LinkButtonProps extends Props, Omit<LinkProps, "type"> {
  mimeType?: LinkProps["type"];
}

const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      label,
      primary,
      discreet,
      textTransform,
      disabled = false,
      className,
      "aria-label": ariaLabel,
      type = "default",
      size,
      icon: Icn,
      iconPosition = "left",
      mimeType,
      children,
      ...props
    },
    ref,
  ) => {
    const onClick = disabled
      ? (e: React.MouseEvent) => {
          e.preventDefault();
        }
      : undefined;

    return (
      <Link
        ref={ref}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type={mimeType}
        className={clsx(
          "lds-button",
          type,
          {
            [type]: type && type !== "default",
            "lds-button--uppercase": textTransform === "uppercase",
            "lds-button--capitalize": textTransform === "capitalize",
            disabled,
            "lds-button--discreet": discreet,
            [`lds-button--${size}`]: Boolean(size),
            primary,
          },
          styles.linkButton,
          className,
        )}
        aria-disabled={disabled}
        aria-label={ariaLabel || label}
      >
        {Icn && iconPosition === "left" && <Icn className="lds-button--icon" />}
        <span className="lds-button--label">{label}</span>
        {children}
        {Icn && iconPosition === "right" && <Icn className="lds-button--icon lds-button--icon-r" />}
      </Link>
    );
  },
);

export default LinkButton;
