import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import { Business, type BusinessSubscription } from "lens-platform-sdk";
import { Button } from "@k8slens/lds";

import { getPlanName, isControlCenterPlan, isLensEnterprisePlan, isLensProPlan } from "src/services/plans";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import LinkButton from "src/components/Button/TrackedLinkButton";
import { stateColDef } from "src/components/TableCells/StateCell";
import ActionsCell, { actionsColDef } from "src/components/TableCells/ActionsCell";
import QuantityCell, { quantityMeta } from "src/components/TableCells/QuantityCell";
import { subscriptionIdColDef } from "src/components/TableCells/SubscriptionIdCell";
import { dateColDef } from "src/components/TableCells/DateCell";

const columnHelper = createColumnHelper<BusinessSubscription>();

interface Props {
  loading?: boolean;
  businessSubscriptions: BusinessSubscription[];
  business: Business | undefined;
}

const caption = "Subscriptions";

/**
 * The business subscriptions table used in business root page (/business/:businessId)
 */
const SubscriptionTable = ({ loading, businessSubscriptions, business }: Props) => {
  const { sorting, onSortingChange } = useTrackedTableSort({ table: caption });
  const history = useHistory();
  const { businessId } = useParams<{ businessId?: string }>();
  const handleOpenSubscriptionModal = useCallback(
    (subscription: BusinessSubscription) => {
      history.push(`/business/${businessId}/subscriptions/${subscription.id}`);
    },
    [businessId, history],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        ...subscriptionIdColDef,
      }),
      columnHelper.accessor("planCode", {
        header: () => <span>Plan</span>,
        cell: ({ getValue }) => <span>{getPlanName(getValue())}</span>,
      }),
      columnHelper.accessor("currentPeriodEndsAt", {
        ...dateColDef,
        header: () => <span>Next Renewal Date</span>,
      }),
      columnHelper.accessor("seats", {
        meta: quantityMeta,
        header: () => <span>Quantity</span>,
        cell: (props) => {
          const subscription = props.row.original;
          const isSubscriptionUpgradable = !(business?.external || subscription.state === "expired");
          const isControlCenterSubscription = isControlCenterPlan(subscription.planCode);

          return (
            <QuantityCell
              {...props}
              isUpgradable={isSubscriptionUpgradable || isControlCenterSubscription}
              lockedText="Cannot change seats for expired subscriptions or this subscription is not auto renewable. Please contact your sales representative to modify the quantity."
            />
          );
        },
      }),
      columnHelper.accessor("state", {
        ...stateColDef,
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor("id", {
        ...actionsColDef,
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          const subscription = row.original;
          const isSubscriptionUpgradable =
            (isLensEnterprisePlan(subscription.planCode) || isLensProPlan(subscription.planCode)) &&
            !(business?.external || subscription.state === "expired");
          const whySubscriptionIsNotUpgradable = business?.external
            ? "Please contact your sales representative to modify the subscription."
            : "Subscription expired";

          if (subscription.state === "expired") {
            return null;
          }

          const buttonLabel = "+/- Seats";

          return (
            <ActionsCell>
              {
                <Button
                  label={buttonLabel}
                  onClick={() => handleOpenSubscriptionModal(subscription)}
                  disabled={!isSubscriptionUpgradable}
                  size="xs"
                  discreet
                  primary
                  title={whySubscriptionIsNotUpgradable}
                />
              }
              {(isLensProPlan(subscription.planCode) || isLensEnterprisePlan(subscription.planCode)) &&
              !business?.external ? (
                <LinkButton
                  label="Manage"
                  aria-label="Open Manage Seats"
                  title={"Open Manage Seats"}
                  to={`/business/${businessId}/users`}
                  size="xs"
                  primary
                  discreet
                />
              ) : null}
            </ActionsCell>
          );
        },
      }),
    ],
    [business?.external, handleOpenSubscriptionModal, businessId],
  );

  const table = useReactTable({
    data: businessSubscriptions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const tableRowModel = table.getRowModel();
  const tableRows = useMemo(() => tableRowModel.rows, [tableRowModel]);

  return (
    <>
      <Table<BusinessSubscription>
        caption="Subscriptions"
        rows={tableRows}
        columns={table.getAllColumns()}
        headerGroups={table.getHeaderGroups()}
        loading={loading}
        noDataText="No subscriptions for your Lens Business ID!"
        borderless={false}
      />
    </>
  );
};

export default SubscriptionTable;
