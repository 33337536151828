import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import type { Business } from "lens-platform-sdk";
import { Switch } from "@headlessui/react";
import { Button, LoadingIndicator, Panel, ButtonBar, Notification } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";

import { type NewBusiness, useCreateBusiness } from "src/hooks/useCreateBusiness";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useGetBusinesses } from "src/hooks/useGetBusinesses";

import { RadioGroup, RadioGroupOption } from "src/components/RadioGroup/RadioGroup";
import BusinessIdForm from "src/components/BusinessIdForm/BusinessIdForm";
import Avatar from "src/components/Avatar/Avatar";
import DrawerTransition from "src/components/DrawerTransition/DrawerTransition";
import { License, LicenseType } from "src/components/pages/Home/license";

import OrderSummary from "./OrderSummary";

import styles from "./BusinessID.module.css";
import { useQueryNewSubscriptionForBusiness } from "src/hooks/useQueryNewSubscriptionForBusiness";

const { CloseIcon } = navigation;

const createNewLabel = "Create a new Business ID…";
const continueButtonLabel = "Continue to Purchase";

export interface Props {
  license: License | null | undefined;
  loading?: boolean;
  planCode: "pro-yearly" | "pro-monthly";
  quantity: number;
  onPurchase(planCode: "pro-yearly" | "pro-monthly", accountCode?: string): Promise<boolean>;
}

const notExternal = (business: Business) => business.external !== true;

const BusinessID: React.FC<Props> = ({ license, loading, planCode, quantity, onPurchase }) => {
  const { businesses, error: errorFetchingBusinesses } = useGetBusinesses(notExternal);
  const { createBusiness } = useCreateBusiness();
  const { track, trackError, trackButtonClicked } = useAnalytics();

  const [loadingNext, setLoadingNext] = useState(false);
  const [error, setError] = useState<string>();
  // will be a string if user is from "Create New Subscriptions" on LBID home page.
  const newSubscriptionForBusiness = useQueryNewSubscriptionForBusiness();
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);

  const enforceBusinessID =
    quantity > 1 || license?.type === LicenseType.pro || typeof newSubscriptionForBusiness === "string";
  const [useBusinessID, setUseBusinessID] = useState(enforceBusinessID);
  const handleUseBusinessIDChange = useCallback(
    (next: boolean) => {
      setUseBusinessID(next);
      useBusinessID !== next && track("Toggle Use Business ID", { value: next });
    },
    [track, useBusinessID],
  );

  useEffect(() => {
    // Enforce
    if (enforceBusinessID && !useBusinessID) {
      setUseBusinessID(true);
    }
  }, [enforceBusinessID, useBusinessID]);

  useEffect(() => {
    // Reset Business ID selection
    if (!useBusinessID) {
      setSelectedBusinessId(null);
    }

    // If newSubscriptionForBusiness is a string, user is coming from "Create New Subscriptions" on LBID home page.
    // We should automatically select the business, if user has not selected a business ID yet.
    if (useBusinessID && typeof newSubscriptionForBusiness === "string" && !selectedBusinessId) {
      setSelectedBusinessId(newSubscriptionForBusiness);
    }
  }, [useBusinessID, newSubscriptionForBusiness, selectedBusinessId]);

  const handleBusinessIdChange = useCallback(
    (id: string | null) => {
      setSelectedBusinessId(id);

      if (id === "new") {
        trackButtonClicked(createNewLabel);
      } else {
        track("Choose Business ID", { businessId: id });
      }
    },
    [track, trackButtonClicked, setSelectedBusinessId],
  );

  const [newBusiness, setNewBusiness] = useState<Partial<NewBusiness>>();
  const [newBusinessFormValid, setNewBusinessFormValid] = useState(false);
  const handlePurchase = useCallback(async () => {
    setLoadingNext(true);
    setError(undefined);

    let result = false;
    let businessId: string | undefined;

    if (useBusinessID && selectedBusinessId && selectedBusinessId !== "new") {
      businessId = selectedBusinessId;
      trackButtonClicked(continueButtonLabel, { currentBusinessID: businessId });
    } else if (useBusinessID && selectedBusinessId === "new" && newBusiness && newBusinessFormValid) {
      try {
        trackButtonClicked(continueButtonLabel, { currentBusinessID: null });
        // If it's valid, we can assume it's a NewBusiness, not a partial one
        businessId = (await createBusiness(newBusiness as NewBusiness))?.id;

        if (businessId) {
          track("BusinessID created", { businessId });
        }
      } catch (error: any) {
        trackError("BusinessID creation failed");
        setError(error.message);
      }
    }

    if (!useBusinessID || businessId) {
      try {
        result = await onPurchase(planCode, businessId);
      } catch (error: any) {
        setError(error.message);
      }
    }

    setLoadingNext(false);

    return result;
  }, [
    useBusinessID,
    planCode,
    selectedBusinessId,
    newBusiness,
    onPurchase,
    newBusinessFormValid,
    createBusiness,
    trackButtonClicked,
    track,
    trackError,
  ]);

  const buttonsDisabled =
    loadingNext || (useBusinessID && (!selectedBusinessId || (selectedBusinessId === "new" && !newBusinessFormValid)));
  const errorMessage = typeof errorFetchingBusinesses === "string" ? errorFetchingBusinesses : error;
  const loadingBusinesses = businesses === undefined && errorFetchingBusinesses === null;

  return (
    <Panel
      className={styles.businessId}
      footer={
        <>
          <div />
          <Button
            loading={loading}
            label={continueButtonLabel}
            primary
            textTransform="uppercase"
            loadingStateOnPromise
            disabled={buttonsDisabled}
            onClick={handlePurchase}
          />
        </>
      }
      loading={loading}
      footerComponent={ButtonBar}
      footerProps={{
        as: "footer",
        className: "mt-0",
      }}
    >
      <>
        <section>
          <h4>Purchase for Lens Business ID</h4>
          <div className={styles.useBusinessID}>
            <p>Make purchases to your team members or organization for centralized subscription management.</p>
            <Switch.Group as="div" className={styles.useBusinessIdSwitch}>
              <Switch
                id="business-id-switch"
                checked={useBusinessID}
                onChange={handleUseBusinessIDChange}
                aria-label="Purchase for Business ID"
                disabled={loading || enforceBusinessID}
              >
                <span>{!useBusinessID && <CloseIcon />}</span>
              </Switch>
            </Switch.Group>
            <ul>
              <li>
                <em>Purchasing subscription for yourself?</em> You don’t have to select this option.
              </li>
              <li>
                <em>Purchasing subscription for somebody else?</em> Please choose this option.
              </li>
              {/* eslint-disable-next-line max-len */}
              <li>
                When purchasing multiple seats, the purchase is made automatically to Lens Business ID and this option
                is selected by default.
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h4>Choose Business ID</h4>
          <p>Choose the Lens Business ID to assign your new subscription(s).</p>
          <RadioGroup
            className={styles.radioGroup}
            value={selectedBusinessId}
            onChange={handleBusinessIdChange}
            aria-live={loadingBusinesses && "polite"}
            aria-busy={loadingBusinesses && "true"}
          >
            {(businesses || []).map((business) => (
              <RadioGroupOption key={business.id} value={business.id} disabled={!useBusinessID}>
                <Avatar name={business.name} className={styles.avatar} />
                {business.name}
              </RadioGroupOption>
            ))}
            <RadioGroupOption
              key="new"
              value="new"
              disabled={!useBusinessID || loadingBusinesses}
              className={({ checked }) => clsx({ [styles.checked]: checked })}
            >
              <div aria-hidden className={clsx(styles.avatar, styles.addBusinessIdIcon)}>
                +
              </div>
              {createNewLabel}
            </RadioGroupOption>
            {loadingBusinesses && <LoadingIndicator className={styles.loading} />}
          </RadioGroup>
        </section>
        {errorMessage && <Notification type="flash" level="error" message={errorMessage} />}
        <DrawerTransition open={selectedBusinessId === "new"}>
          <section>
            <BusinessIdForm
              title="Create a new Business ID"
              onChange={(newBusiness, valid) => {
                setNewBusiness(newBusiness);
                setNewBusinessFormValid(valid);
              }}
            />
          </section>
        </DrawerTransition>
        <OrderSummary
          quantity={quantity}
          planCode={planCode}
          purchasingDisabled={buttonsDisabled}
          onPurchase={handlePurchase}
          loading={loading}
        />
      </>
    </Panel>
  );
};

export default BusinessID;
