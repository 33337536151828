import { useKeycloak } from "@react-keycloak/web";
import { usePassThroughKeys } from "./usePassThroughKeys";
import { useQuery } from "./useQuery";
import { githubParam, googleParam } from "../components/SignUp/SignUp";
import { addPassThroughKeys } from "../services/passThroughKey";

export const useGetSignUpRedirectPath = () => {
  const passThroughKeys = usePassThroughKeys();
  const query = useQuery();
  const { keycloak } = useKeycloak();
  const hasPassThroughKeys = Object.values(passThroughKeys).some((value) => value.length > 0);

  let type: "github" | "google" | undefined;

  if (query.get(githubParam)) {
    type = "github";
  } else if (query.get(googleParam)) {
    type = "google";
  }
  const signingUpFromIdp = Boolean(type);
  const isAuthenticatedWithIDP = !signingUpFromIdp && keycloak?.authenticated;

  if (hasPassThroughKeys) {
    return addPassThroughKeys("/home", passThroughKeys);
  }

  if (isAuthenticatedWithIDP) {
    return "/signup/success";
  }

  return "/signup/signin";
};
