import { Panel } from "@k8slens/lds";
import { Props as StepBarProps } from "src/components/StepBar/StepBar";

import styles from "./Checkout.module.css";

export const knownStep = ["configure", "login", "payment-details", "choose-business-id", "thank-you"] as const;
export type Step = (typeof knownStep)[number];

export interface Props {
  currentStep?: StepBarProps<Step>["currentStep"];
  steps?: StepBarProps<Step>["steps"];
  children?: React.ReactNode;
  allowNavigation?: boolean;
}

const Checkout: React.FC<Props> = ({ children }) => (
  <div className={styles.checkout}>
    <Panel header="Checkout" headerComponent="h2" headerProps={{ className: "lds-h1" }}>
      <article>{children}</article>
    </Panel>
  </div>
);

export default Checkout;
