import { useContext } from "react";
import Layout from "src/components/Layout/Layout";
import { ProfileContext } from "src/providers/profile-provider";
import { useLogout } from "src/hooks/useLogout";

import { UserBillingDetails } from "./UserBillingDetails";

export const BillingDetails = () => {
  const { profile } = useContext(ProfileContext);
  const logout = useLogout();

  return (
    <Layout className="bg-secondary" profile={profile} logout={logout}>
      <UserBillingDetails />
    </Layout>
  );
};
