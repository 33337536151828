import { useCallback, useState } from "react";
import clsx from "clsx";
import { Redirect, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import queryString from "query-string";

import type { LocationState } from "src/App";
import formStyles from "src/components/styles/form.module.css";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { PassThroughKeys, usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { useFromEmailVerificationPage } from "src/hooks/useFromEmailVerificationPage";
import Page from "src/components/Page/Page";
import { useRedirectUri } from "src/hooks/useRedirectUri";
import SignIn, { GetRedirectUri } from "src/components/SignIn/SignIn";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import TrackedLink from "src/components/TrackedLink/TrackedLink";

import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";

import Subtitle from "./Subtitle";

import styles from "./SignInPage.module.css";
import { getSignInRedirectUri } from "../../../utils/url";
import { WarningSide } from "./WarningSide";
import { PleaseUpgradeSide } from "./PleaseUpgradeSide";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { useOauthDanceQueries } from "src/hooks/useOauthDanceQueries";
import { useQuery } from "src/hooks/useQuery";
import { useParams } from "react-router-dom";

const getAuthenticatedUri = (fromPathname: string | undefined, passThroughKeys: PassThroughKeys) => {
  if (fromPathname) {
    return addPassThroughKeys(fromPathname, passThroughKeys);
  }

  return addPassThroughKeys("/home", passThroughKeys);
};

const SignInPage = () => {
  const passThroughKeys = usePassThroughKeys();
  const query = useQuery();
  const redirectUriQuery = query.get("redirect_uri");
  const oauthDanceQueries = useOauthDanceQueries();
  const params = useParams<{ realm?: string }>();
  const isOauthDance = oauthDanceQueries.includes("client_id") && params.realm === "lensCloud";

  const fromEmailVerificationPage = useFromEmailVerificationPage();
  const location = useLocation<LocationState | undefined>();

  const inApp = queryString.parse(location.search).inApp?.toString() === "true";
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(inApp);
  const defaultRedirectUri = getAuthenticatedUri(location.state?.from?.pathname, passThroughKeys);
  const getKeyCloakRedirectUri: GetRedirectUri = useCallback(
    (redirectUri, loginOrigin) => getSignInRedirectUri(redirectUri, loginOrigin, passThroughKeys),
    [passThroughKeys],
  );

  /**
   * Handle redirects outside wrapper to prevent rendering glitches
   */
  const { keycloak } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const keycloakLoading = useKeycloakLoading();
  const redirectUriParam = useRedirectUri();

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  if (keycloak?.authenticated && !redirectUriParam) {
    return <Redirect to={defaultRedirectUri} />;
  }

  return (
    <Page showLogo={!inApp}>
      <div className={formStyles.bg}>
        <div className={styles.sideBySide}>
          {inApp ? (
            <WarningSide />
          ) : (
            <div className={styles.spinningGradientBackground}>
              <div className={styles.welcomeTextBlock}>
                <h1 className={styles.welcomeTitle}>What is Lens ID?</h1>
                <p className={styles.welcomeText}>
                  Your Lens ID is your unique identity within Lens. It is used to register our products such as Lens
                  Desktop and access our cloud services. You can sign in to all Lens services with a single Lens ID.
                </p>
                <p className={styles.welcomeText}>
                  After you log in, you can use
                  <TrackedAnchor
                    href="https://k8slens.dev/desktop.html"
                    target="_blank"
                    className={formStyles.linkWhite}
                    rel="noreferrer"
                  >
                    Lens Desktop,
                  </TrackedAnchor>
                  <TrackedAnchor
                    href="https://k8slens.dev/teamwork.html"
                    target="_blank"
                    className={formStyles.linkWhite}
                    rel="noreferrer"
                  >
                    Lens Teamwork,
                  </TrackedAnchor>
                  manage your Lens ID details and more.
                </p>
                <p className={styles.welcomeText}>
                  Learn more about
                  <TrackedAnchor
                    href="https://docs.k8slens.dev/lens-id/"
                    target="_blank"
                    className={formStyles.linkWhite}
                    rel="noreferrer"
                  >
                    Lens ID here.
                  </TrackedAnchor>
                </p>
              </div>
              <div className={styles.screenShotShare} />
              <div className={styles.screenShotMembers} />
              <div className={styles.screenShotTeams} />
            </div>
          )}
          {showUpgradeMessage ? (
            <PleaseUpgradeSide continueToLogin={() => setShowUpgradeMessage(false)} />
          ) : (
            <div className={clsx(formStyles.size, styles.signInForm)} data-testid="login-form">
              <div className={styles.upper}>
                <h1 className={formStyles.title}>Lens ID</h1>
                {inApp ? (
                  <p className={formStyles.subtitle}>
                    Login with your Lens ID to unlock all features and continue working with your projects.
                  </p>
                ) : (
                  <Subtitle
                    fromEmailVerificationPage={fromEmailVerificationPage}
                    invitationKey={passThroughKeys.invitationKey || ""}
                  />
                )}
              </div>
              <SignIn
                inApp={inApp}
                redirectUri={defaultRedirectUri}
                getKeyCloakRedirectUri={getKeyCloakRedirectUri}
                formWrapperClassName={styles.middle}
                footerClassName={styles.bottom}
                disabled={keycloakLoading}
              />
            </div>
          )}
          {!inApp && (
            <span className={styles.new}>
              New to Lens?
              <TrackedLink
                // pass redirect_uri to sign up page if it was passed to login page
                to={
                  redirectUriQuery
                    ? `${addPassThroughKeys(
                        isOauthDance ? "/signup/realms/lensCloud/protocol/openid-connect/auth" : "/signup",
                        passThroughKeys,
                      )}${oauthDanceQueries}`
                    : addPassThroughKeys("/signup", passThroughKeys)
                }
                target={inApp ? "_blank" : undefined}
                className={formStyles.link}
              >
                Create your Lens ID
              </TrackedLink>
            </span>
          )}
        </div>
      </div>
    </Page>
  );
};

export default SignInPage;
