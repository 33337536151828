import { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";

import { addPassThroughKeys } from "src/services/passThroughKey";

import { usePassThroughKeys } from "./usePassThroughKeys";

const getKeycloakLogOutUrl = (keycloak: Keycloak.KeycloakInstance): URL => {
  const logOutUrl = new URL(keycloak.createLogoutUrl());
  // for Keycloak < 19.0.0, we can use `redirect_uri`, e.g.
  // ```ts
  // logOutUrl.searchParams.append(
  //    "redirect_uri", `${window.location.origin}${addPassThroughKeys("/signin", passThroughKeys
  // )}`);
  // ```
  // but for Keycloak >= 19.0.0, we need to use the `post_logout_redirect_uri` + `id_token_hint`
  // see https://www.keycloak.org/docs/latest/upgrading/index.html#openid-connect-logout

  // need to remove it or Keycloak will complain without
  // --spi-login-protocol-openid-connect-legacy-logout-redirect-uri
  logOutUrl.searchParams.delete("redirect_uri");
  logOutUrl.searchParams.append("id_token_hint", keycloak.idToken ?? "");

  return logOutUrl;
};

export const useLogout = () => {
  const passThroughKeys = usePassThroughKeys();
  const { keycloak } = useKeycloak();

  const logout = useCallback(async () => {
    const hasPassThroughKeys = Object.values(passThroughKeys).some((value) => value.length > 0);

    const logOutUrl = getKeycloakLogOutUrl(keycloak);

    logOutUrl.searchParams.append(
      "post_logout_redirect_uri",
      hasPassThroughKeys
        ? `${window.location.origin}${addPassThroughKeys("/signin", passThroughKeys)}`
        : `${window.location.origin}/signin`,
    );

    window.location.replace(logOutUrl.href);

    // @ts-expect-error missing type definitions, but window.PasswordCredential is valid in Chrome
    if (window.PasswordCredential) {
      const credential = await window.navigator.credentials?.get?.();

      if (credential) {
        // prevent the user from being automatically signed back in if we have saved the password
        // see https://web.dev/security-credential-management/#sign-out
        await navigator.credentials?.preventSilentAccess?.();
      }
    }
  }, [keycloak, passThroughKeys]);

  return logout;
};
