import { useContext, useCallback, useState } from "react";
import clsx from "clsx";

import Layout from "src/components/Layout/Layout";
import { useLogout } from "src/hooks/useLogout";
import { ProfileContext } from "src/providers/profile-provider";
import { ButtonBar, Notification } from "@k8slens/lds";
import { useAddEmails } from "src/hooks/useAddEmails";
import { getUniqueEmailsArray } from "src/services/email";
import TrackedButton from "src/components/Button/TrackedButton";
import { useGetEmails } from "src/hooks/useGetEmails";
import { useDeleteEmails } from "src/hooks/useDeleteEmails";
import { useSetPrimaryEmail } from "src/hooks/useSetPrimaryEmail";
import { useSendVerificationEmail } from "src/hooks/useSendVerificationEmail";

import { AddEmailModal } from "./AddEmailModal";
import { EmailTable } from "./EmailTable";

import styles from "./Emails.module.css";

const AddEmailLabel = "Add Email Address";

export const Emails = () => {
  const logout = useLogout();
  const { profile } = useContext(ProfileContext);
  const { emails, reload: reloadEmails } = useGetEmails();
  const [addEmailModalOpen, setAddEmailModalOpen] = useState(false);
  const { addEmails, error: addEmailError, loading: addingEmails } = useAddEmails();
  const { deleteEmails, error: deleteEmailError } = useDeleteEmails();
  const { setPrimaryEmail, error: setPrimaryEmailError } = useSetPrimaryEmail();
  const { sendVerificationEmail, error: sendVerificationEmailError } = useSendVerificationEmail();

  const openInviteAdministrationModal = useCallback(() => {
    setAddEmailModalOpen(true);
  }, []);
  const closeAddEmailModal = useCallback(() => {
    setAddEmailModalOpen(false);
  }, []);
  const onClickAddEmails = useCallback(
    async (newEmails: string) => {
      const emailArray = getUniqueEmailsArray(newEmails);

      const exception = await addEmails(emailArray);

      // if successfully added email, close the modal
      if (!exception) {
        await reloadEmails();
        closeAddEmailModal();
      }
    },
    [reloadEmails, closeAddEmailModal, addEmails],
  );
  const onSendVerificationEmail = useCallback(
    async (email: string) => {
      await sendVerificationEmail(email);
    },
    [sendVerificationEmail],
  );
  const onSetPrimaryEmail = useCallback(
    async (email: string) => {
      const exception = await setPrimaryEmail(email);

      if (!exception) {
        await reloadEmails();
      }
    },
    [setPrimaryEmail, reloadEmails],
  );
  const onDeleteEmails = useCallback(
    async (email: string[]) => {
      const exception = await deleteEmails(email);

      if (!exception) {
        await reloadEmails();
      }
    },
    [deleteEmails, reloadEmails],
  );

  const requestError = deleteEmailError ?? setPrimaryEmailError ?? sendVerificationEmailError;

  return (
    <Layout className="bg-secondary" logout={logout} profile={profile}>
      <header className={styles.home}>
        <h2 className={clsx("lds-h2-caps", styles.welcome)}>Emails</h2>
        <p>All email addresses associated with your account are listed in here.</p>
      </header>
      {requestError ? (
        <Notification
          level="error"
          message={requestError?.message ?? "Something went wrong"}
          type="flash"
          className={styles.errorNotification}
        />
      ) : null}
      <EmailTable
        emails={emails}
        onSendVerificationEmail={onSendVerificationEmail}
        onSetPrimaryEmail={onSetPrimaryEmail}
        onDeleteEmails={onDeleteEmails}
      />
      <ButtonBar type="grid" className={styles.buttonBar}>
        <TrackedButton
          label={AddEmailLabel}
          onClick={() => {
            openInviteAdministrationModal();
          }}
          primary
        />
      </ButtonBar>
      <AddEmailModal
        open={addEmailModalOpen}
        addingEmails={addingEmails}
        addEmailError={addEmailError}
        onModalClose={closeAddEmailModal}
        onClickAddEmails={onClickAddEmails}
      />
    </Layout>
  );
};
