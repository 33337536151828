import { useState, useMemo, PropsWithChildren, useCallback, useRef, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import queryString from "query-string";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";

import { LocationState } from "src/App";
import { sendEmailConfirmation } from "src/services/email";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import { useRedirectUri } from "src/hooks/useRedirectUri";
import { useAnalytics } from "src/hooks/useAnalytics";

import formStyles from "src/components/styles/form.module.css";

import SignInForm from "./SignInForm";
import SSOSignIn from "./SSOSignIn";

import styles from "./SignIn.module.css";
import { Business } from "lens-platform-sdk";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";

const verifyEmailError = "You need to verify your email address to activate your account.";
const resendButtonLabel = "Resend";

export type LoginOrigin = "github" | "google" | Business["id"];
export type GetRedirectUri = (redirectUri: string | undefined, loginOrigin: LoginOrigin) => string | undefined;

type Props = PropsWithChildren<{
  // Redirect when authenticated
  redirectUri: string;

  getKeyCloakRedirectUri: GetRedirectUri;
  inApp?: boolean;
  disabled?: boolean;
  loginButtonLabel?: string;
  formWrapperClassName?: string;
  footerClassName?: string;
  hideSSO?: boolean;
}>;

const loggedInPath = "/logged-in";

const SignIn: React.FC<Props> = ({
  redirectUri,
  getKeyCloakRedirectUri,
  inApp = false,
  loginButtonLabel = "Login",
  disabled,
  formWrapperClassName = "",
  footerClassName = "",
  hideSSO = false,
}) => {
  const { trackButtonClicked, trackRedirect } = useAnalytics();
  const location = useLocation<LocationState | undefined>();
  const { keycloak, initialized } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const lensPlatformClient = useLensPlatformClient();

  const [isSSOLoggingIn, setIsSSOLoggingIn] = useState(false);
  const [isGitHubLoggingIn, setIsGitHubLoggingIn] = useState(false);
  const [isGoogleLoggingIn, setIsGoogleLoggingIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginDisabled, setLogInDisabled] = useState(true);
  const redirectUriParam = useRedirectUri();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const scope = queryString.parse(location.search).scope?.toString();

  const loggedInUri = `${window.location.origin}${loggedInPath}`;
  const ssoLabel = "Login with SSO";
  const githubLabel = isGitHubLoggingIn ? "Redirecting to Github" : "Login with Github";
  const googleLabel = isGoogleLoggingIn ? "Redirecting to Google" : "Login with Google";

  // redirectUri isn't used here, but redirection is done "manually" after iframe loaded
  const loginKeycloakUrl = useMemo(
    () =>
      initialized
        ? keycloak.createLoginUrl(
            scope
              ? {
                  redirectUri: `${loggedInUri}`,
                  scope,
                }
              : {
                  redirectUri: `${loggedInUri}`,
                },
          )
        : "",
    [initialized, keycloak, loggedInUri, scope],
  );
  const [loginError, setLoginError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMeEnabled, setRememberMeEnabled] = useState(false);
  const loginIframe = useRef<HTMLIFrameElement>(null);
  const logInIframeLoading = loginIframe.current === null;
  const [emailVerificationNeeded, setEmailVerificationNeeded] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    if (
      username.length === 0 ||
      password.length === 0 ||
      isLoggingIn ||
      isGitHubLoggingIn ||
      isGoogleLoggingIn ||
      emailVerificationNeeded ||
      isSendingEmail ||
      isRedirecting ||
      logInIframeLoading
    ) {
      setLogInDisabled(true);
    } else {
      setLogInDisabled(false);
    }
  }, [
    username,
    password,
    isLoggingIn,
    isGitHubLoggingIn,
    isGoogleLoggingIn,
    emailVerificationNeeded,
    isSendingEmail,
    isRedirecting,
    logInIframeLoading,
  ]);

  useEffect(() => {
    let times = 0;
    // hack to detect chrome autofill
    const interval = setInterval(() => {
      try {
        const autoFilledInputs = document.querySelectorAll("input:-webkit-autofill");

        times += 1;

        if (autoFilledInputs?.length > 0) {
          // eslint-disable-next-line no-console
          console.info("Found input:-webkit-autofill, set LogInDisabled as false.");
          setLogInDisabled(false);
          clearInterval(interval);
        } else if (times > 10) {
          // eslint-disable-next-line no-console
          console.info(`Can't find input:-webkit-autofill, tried ${times} times.`);
          clearInterval(interval);
        }
      } catch {
        // ignore SyntaxError: unknown pseudo-class selector
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const getKeycloakLoginOptions = useCallback(
    (redirectUri: string | undefined, loginOrigin: LoginOrigin): Keycloak.KeycloakLoginOptions => ({
      idpHint: loginOrigin,
      redirectUri: getKeyCloakRedirectUri(redirectUri, loginOrigin),
    }),
    [getKeyCloakRedirectUri],
  );

  const loginSSO = useCallback(() => {
    trackButtonClicked(ssoLabel);
    setIsSSOLoggingIn(true);
  }, [trackButtonClicked, ssoLabel]);

  const cancelLoginSSO = useCallback(() => {
    setIsSSOLoggingIn(false);
  }, []);

  const redirectToSSO = useCallback(
    (domain: string) =>
      lensPlatformClient.sso.getSSOByDomain(domain).then((ssoData) => {
        if (ssoData && ssoData.identityProviderID) {
          const loginUrl = keycloak.createLoginUrl({
            idpHint: ssoData.identityProviderID,
            redirectUri: getKeyCloakRedirectUri(redirectUri, ssoData.identityProviderID),
          });
          const loginOptions = getKeycloakLoginOptions(redirectUriParam, ssoData.identityProviderID);

          trackRedirect(loginUrl);

          keycloak?.login(loginOptions);
        } else {
          throw new Error("No SSO data found");
        }
      }),
    [
      lensPlatformClient,
      keycloak,
      trackRedirect,
      getKeyCloakRedirectUri,
      redirectUri,
      getKeycloakLoginOptions,
      redirectUriParam,
    ],
  );

  const loginGitHub = useCallback(() => {
    const loginOptions = getKeycloakLoginOptions(redirectUriParam, "github");

    if (inApp) {
      const url = keycloak?.createLoginUrl(loginOptions);
      const target = "_blank";

      trackButtonClicked(githubLabel, {
        href: url,
        target,
      });
      window.open(url, target);
    } else {
      trackButtonClicked(githubLabel);
      setIsGitHubLoggingIn(true);
      keycloak?.login(loginOptions);
    }
  }, [trackButtonClicked, githubLabel, redirectUriParam, keycloak, inApp, getKeycloakLoginOptions]);

  const loginGoogle = useCallback(() => {
    const loginOptions = getKeycloakLoginOptions(redirectUriParam, "google");

    if (inApp) {
      const url = keycloak?.createLoginUrl(loginOptions);
      const target = "_blank";

      trackButtonClicked(googleLabel, {
        href: url,
        target,
      });
      window.open(url, target);
    } else {
      trackButtonClicked(googleLabel);
      setIsGoogleLoggingIn(true);
      keycloak?.login(loginOptions);
    }
  }, [trackButtonClicked, googleLabel, redirectUriParam, keycloak, inApp, getKeycloakLoginOptions]);

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoginError("");
      setIsLoggingIn(true);

      const contentDocument = loginIframe.current?.contentDocument;

      (contentDocument?.querySelector("input#username") as HTMLInputElement).value = username;
      (contentDocument?.querySelector("input#password") as HTMLInputElement).value = password;

      if (rememberMeEnabled) {
        // Make sure remember me is checked if enabled
        (contentDocument?.querySelector("input#rememberMe") as HTMLInputElement).checked = true;
      }
      (contentDocument?.querySelector("form") as HTMLFormElement).submit();
    },
    [password, username, rememberMeEnabled],
  );

  const handleUsernameChange = useCallback((username: string) => {
    setUsername(username);
  }, []);
  const handlePasswordChange = useCallback((password: string) => {
    setPassword(password);
  }, []);
  const handleSendEmail = async () => {
    trackButtonClicked(resendButtonLabel);

    try {
      setIsSendingEmail(true);
      await sendEmailConfirmation(username);

      // After email is sent, clear verification needed and login error so user can try to login
      // again (after presumably user has confirmed the email)
      setEmailVerificationNeeded(false);
      setLoginError("");

      // Reload the iframe to display the login form again inside the iframe,
      // not just the error message.
      loginIframe.current?.contentWindow?.location.replace(loginKeycloakUrl);
    } catch (error) {
      setLoginError("");
      // Wait for the reset error message to render to give visual que to the user
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));
      setLoginError(error instanceof Error ? error.message : "");
    } finally {
      setIsSendingEmail(false);
    }
  };

  const [resetPasswordUrl, setResetPasswordUrl] = useState<string | null | undefined>();

  const handleLoginIframeLoad = useCallback(() => {
    if (loginIframe.current?.contentWindow?.location.pathname === loggedInPath) {
      setLoginError("");
      setIsLoggingIn(false);

      if (redirectUriParam) {
        setIsRedirecting(true);
        // we may redirect the user to his origin after signin (in main window!)
        // we take code param from login iframe query params and set it into redirectUri
        // useRedirectUri should already contain the original state in redirectUri
        const iframeQueryParams = queryString.parse(loginIframe.current?.contentWindow?.location.search);
        const code = iframeQueryParams.code?.toString();
        const iss = iframeQueryParams.iss;

        let redirectCodeParam = redirectUriParam.includes("?") ? `&code=${code}` : `?code=${code}`;

        redirectCodeParam += iss ? `&iss=${encodeURIComponent(iss.toString())}` : "";
        window.location.replace(`${redirectUriParam}${redirectCodeParam}`);
        // likely not needed as window is refreshed, but just in case
        setIsRedirecting(false);
      } else {
        // if no redirect uri was received we will show the private "/home"
        window.location.reload();
      }
    } else if (
      // The error message DOM element in lenscloud keycloak theme
      loginIframe.current?.contentDocument?.querySelector(".alert, .alert-warning") ||
      // The error message DOM element in keycloak base theme
      loginIframe.current?.contentDocument?.querySelector("#input-error")
    ) {
      setIsLoggingIn(false);
      // If instead we see an error after login, display it for the user
      // eslint-disable-next-line xss/no-mixed-html
      const loginError =
        loginIframe.current?.contentDocument?.querySelector(".kc-feedback-text, .alert-warning > span")?.innerHTML ??
        loginIframe.current?.contentDocument?.querySelector("#input-error")?.innerHTML.trim?.() ??
        "";

      setLoginError(loginError);

      if (loginError === verifyEmailError) {
        setEmailVerificationNeeded(true);
      }
    }
    setResetPasswordUrl(
      loginIframe.current?.contentDocument?.querySelector("#reset-password-link")?.getAttribute("href"),
    );

    // Check if Keycloak remember me option is enabled
    const rememberMeCheckbox = loginIframe.current?.contentDocument?.querySelector("input#rememberMe");
    const kcRememberMeEnabled = Boolean(rememberMeCheckbox);

    setRememberMeEnabled(kcRememberMeEnabled);

    if (kcRememberMeEnabled) {
      // Set remember me to true if it is enabled
      (rememberMeCheckbox as HTMLInputElement).checked = true;
    }
  }, [redirectUriParam]);

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  if (keycloak?.authenticated && !redirectUriParam) {
    return <Redirect to={redirectUri} />;
  }

  if (!hideSSO && isSSOLoggingIn) {
    return (
      <SSOSignIn
        formClassName={formWrapperClassName}
        footerClassName={footerClassName}
        onSSOLogin={redirectToSSO}
        onCancel={cancelLoginSSO}
      />
    );
  }

  return (
    <>
      <div className={formWrapperClassName}>
        <SignInForm
          disabled={disabled}
          inApp={inApp}
          loginButtonLabel={loginButtonLabel}
          loginDisabled={loginDisabled}
          isLoggingIn={isLoggingIn}
          isRedirecting={isRedirecting}
          loginError={loginError}
          username={username}
          password={password}
          onUsernameChange={handleUsernameChange}
          onPasswordChange={handlePasswordChange}
          onLogin={handleLogin}
          resetPasswordUrl={resetPasswordUrl}
          resendEmailButton={
            emailVerificationNeeded ? (
              <button type="button" onClick={handleSendEmail} disabled={isSendingEmail}>
                {resendButtonLabel}
              </button>
            ) : null
          }
        />
      </div>
      <iframe
        className={styles.iframe}
        ref={loginIframe}
        src={loginKeycloakUrl}
        title="LensCloudLogin"
        onLoad={handleLoginIframeLoad}
      />
      <div className={footerClassName}>
        <div className={hideSSO ? formStyles.btnSideBySide : formStyles.btnThreeSideBySide}>
          {!hideSSO && (
            <button
              type="submit"
              disabled={disabled || logInIframeLoading}
              className={styles.ssoBtn}
              aria-label={ssoLabel}
              onClick={loginSSO}
            >
              {ssoLabel}
            </button>
          )}
          <button
            type="submit"
            disabled={disabled || isGitHubLoggingIn || logInIframeLoading}
            className={styles.githubBtn}
            aria-label={githubLabel}
            onClick={loginGitHub}
          >
            {githubLabel}
          </button>
          <button
            type="submit"
            disabled={disabled || isGoogleLoggingIn || logInIframeLoading}
            className={styles.googleBtn}
            aria-label={googleLabel}
            onClick={loginGoogle}
          >
            {googleLabel}
          </button>
        </div>
      </div>
    </>
  );
};

export default SignIn;
