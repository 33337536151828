import { PropsWithChildren } from "react";
import styles from "./BusinessSSOTypeSelect.module.css";
import clsx from "clsx";

export const BusinessSSOTypeSelectWrapper = ({ children }: PropsWithChildren) => {
  return (
    <>
      <h3 className={clsx("lds-h3", styles.businessSSOTypeSelectLabel)}>SSO Provider Type</h3>
      <div className={styles.businessSSOTypeSelectWrapper}>{children}</div>
    </>
  );
};
