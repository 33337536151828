import { useHistory } from "react-router-dom";
import { Panel, ButtonBar, Notification } from "@k8slens/lds";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedLinkButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { useQuery } from "src/hooks/useQuery";
import { useCallback, useMemo } from "react";
import { useVerifySecondaryEmail } from "src/hooks/useVerifySecondaryEmail";
import decode from "jwt-decode";
import styles from "./VerifySecondaryEmail.module.css";

const VerifySecondaryEmail = () => {
  const history = useHistory();
  const { verifySecondaryEmail, error } = useVerifySecondaryEmail();
  const query = useQuery();
  const token = useMemo(() => query.get("token"), [query]);
  const tokenPayload = useMemo(() => {
    if (token) {
      try {
        return decode<{ username: string; email: string }>(token);
      } catch {
        return undefined;
      }
    }

    return undefined;
  }, [token]);

  const verify = useCallback(async () => {
    if (token) {
      const exception = await verifySecondaryEmail(token);

      if (!exception) {
        history.push("/emails");
      }
    }
  }, [token, history, verifySecondaryEmail]);

  return (
    <PublicLayout size="xl" className={styles.layout}>
      <Panel
        className={styles.panel}
        header={
          <>
            <h2>{`Verify ${tokenPayload?.email ?? "Email"}`}</h2>
          </>
        }
        footer={
          <ButtonBar type="grid" gridSize={4}>
            <LinkButton to="/home" label="Cancel" discreet />
            <div />
            <div />
            <Button label="Verify" onClick={verify} loadingStateOnPromise primary disabled={!token} />
          </ButtonBar>
        }
      >
        <>
          {token && tokenPayload && (
            <div className={styles.info}>
              <p>Click the button below to verify the email address.</p>
            </div>
          )}
          {error && <Notification level="error" message={error.message ?? "Unknown Error"} type="flash" />}
          {(!token || !tokenPayload) && (
            <div className={styles.error}>
              <h4>Something went wrong</h4>
              <p>There seems to be some issues with your verification token.</p>
              <p>
                Try re-opening the link in your email or trigger the verification process again. If that doesn&apos;t
                help, please contact us at{" "}
                <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
              </p>
            </div>
          )}
        </>
      </Panel>
    </PublicLayout>
  );
};

export default VerifySecondaryEmail;
