import { useContext } from "react";
import clsx from "clsx";
import { ButtonBar } from "@k8slens/lds";

import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";

import LinkButton from "src/components/Button/TrackedLinkButton";
import { BusinessContext } from "src/components/Business/Base";
import SubscriptionTable from "src/components/Business/SubscriptionTable";

import styles from "src/components/Business/Business.module.css";

/**
 * The "root" page for the business, rendered at /business/:businessId
 */
const Business = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { businessSubscriptions, loading: loadingBusinessSubscriptions } = useGetBusinessSubscriptions(businessId);

  const addManageSeatsDisabled = businessSubscriptions.length === 0;
  const manageSeatsLabel = "Manage Seats";

  return (
    <>
      <header className={styles.home}>
        <h2 className={clsx("lds-h2", styles.welcome)}>Manage your Lens Business ID</h2>
        <p>
          You can use Lens Business ID <strong>{`(${business?.handle || "-"})`}</strong> to manage subscriptions for
          your team.
        </p>
      </header>
      <section className={styles.subscriptions}>
        <h3 className={styles.tableTitle}>Purchased Subscriptions</h3>
        <SubscriptionTable
          businessSubscriptions={businessSubscriptions}
          loading={loadingBusinessSubscriptions}
          business={business}
        />
        <ButtonBar type="grid" className={styles.buttonBar}>
          <LinkButton
            label={manageSeatsLabel}
            to={`/business/${businessId}/users`}
            primary
            disabled={addManageSeatsDisabled}
          />
        </ButtonBar>
      </section>
    </>
  );
};

export default Business;
