import { useCallback, useContext } from "react";
import { TokenContext } from "src/providers/token-provider";
import { createOfflineActivationCodeDownload } from "src/utils/activation-code";
import { useLensPlatformClient } from "./useLensPlatformClient";

export const useDownloadActivationCode = () => {
  const { idToken, refreshToken, accessToken } = useContext(TokenContext);
  const platformClient = useLensPlatformClient();

  const downloadActivationCode = useCallback(
    async (username: string, subscriptionSeatId: string) => {
      if (!accessToken || !idToken || !refreshToken) {
        return null;
      }

      const data = await platformClient.user.getSubscriptionSeatOfflineActivationCode({
        username,
        subscriptionSeatId,
        activationCodeData: { accessToken, idToken, refreshToken },
      });

      if (data?.activationCode) {
        createOfflineActivationCodeDownload(data.activationCode);
      }

      return null;
    },
    [idToken, refreshToken, accessToken, platformClient.user],
  );

  return downloadActivationCode;
};
