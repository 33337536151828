import { useCallback, useState } from "react";
import { FormInput, common } from "@k8slens/lds-form";
import { Button, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";

import formStyles from "src/components/styles/form.module.css";

import styles from "./SignIn.module.css";
import { validateEmail } from "src/services/email";

const SSOSignIn: React.FC<{
  formClassName?: string;
  footerClassName?: string;
  onSSOLogin: (businessHandle: string) => Promise<unknown>;
  onCancel: () => void;
}> = ({ formClassName, footerClassName, onSSOLogin, onCancel }) => {
  const ssoLoginLabel = "Continue";
  const ssoCancelLabel = "← Back";

  const { trackButtonClicked } = useAnalytics();

  const [emailAddress, setEmailAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const onEmailAddressChange = useCallback(
    (email: string) => {
      setIsValid(validateEmail(email));
      setEmailAddress(email);
    },
    [setEmailAddress, setIsValid],
  );

  const handleCancelClick = useCallback(() => {
    trackButtonClicked(ssoCancelLabel);
    setEmailAddress("");
    onCancel();
  }, [trackButtonClicked, onCancel]);

  const handleLoginClick = useCallback(() => {
    setError(null);

    if (!isValid) {
      setError(new Error("SSO provider not found"));

      return Promise.resolve(false);
    }

    trackButtonClicked(ssoLoginLabel);
    const domain = emailAddress.split("@")[1];

    return onSSOLogin(domain).catch((err) => {
      setError(err);
    });
  }, [isValid, trackButtonClicked, emailAddress, onSSOLogin, setError]);

  return (
    <>
      {error && <Notification type="flash" level="error" message={error?.message} onClose={() => setError(null)} />}
      <div className={formClassName}>
        <FormInput
          id="email"
          name="email"
          label="Email Address"
          value={emailAddress}
          onChange={onEmailAddressChange}
          errorParser={common.defaultErrorParser}
        />
      </div>
      <div className={footerClassName}>
        <div className={formStyles.btnThreeSideBySide}>
          <button className={styles.backButton} type="button" onClick={handleCancelClick}>
            {ssoCancelLabel}
          </button>
          <div />
          <Button
            type="primary"
            label={ssoLoginLabel}
            onClick={handleLoginClick}
            loadingStateOnPromise
            disabled={!isValid}
          />
        </div>
      </div>
    </>
  );
};

export default SSOSignIn;
