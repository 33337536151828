import { FormInput } from "@k8slens/lds-form";

import formStyles from "src/components/styles/form.module.css";

interface ProfileType {
  company?: {
    value?: string;
    valid?: boolean;
  };
}

type Props = {
  profile: ProfileType;
  onProfileChange: (name: string, value: string, valid: boolean) => void;
};

const Profile = ({ profile, onProfileChange }: Props) => (
  <>
    <FormInput
      label="Company"
      type="text"
      name="company"
      required
      value={profile.company?.value ?? ""}
      onChange={(value, valid) => onProfileChange("company", value, valid)}
      className={formStyles.input}
      autoComplete="organization"
    />
  </>
);

export type { ProfileType };
export default Profile;
