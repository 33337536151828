import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import clsx from "clsx";
import { FormInput } from "@k8slens/lds-form";
import { Notification } from "@k8slens/lds";

import { useUploadAvatar } from "src/hooks/useUploadAvatar";
import { ProfileContext } from "src/providers/profile-provider";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useLogout } from "src/hooks/useLogout";
import { useAnalytics } from "src/hooks/useAnalytics";

import Layout from "src/components/Layout/Layout";
import Button from "src/components/Button/TrackedButton";

import { ProfileAvatar } from "./ProfileAvatar";

import styles from "./Profile.module.css";
import { FormSwitchGroup } from "@k8slens/lds-form";
import { SwitchValue } from "@k8slens/lds-form/lib/es/FormSwitchGroup/FormSwitchGroup";
import { useUserCommunicationPreferences } from "src/hooks/useUserCommunicationPreferences";

type FieldValues = {
  firstName: { value: string; valid?: boolean };
  lastName: { value: string; valid?: boolean };
  company: { value: string; valid?: boolean };
};

type ReducerAction<Key extends keyof FieldValues> = {
  key: Key;
  value: FieldValues[Key]["value"];
  valid: boolean;
};

const valueReducer = (state: FieldValues, { key, ...value }: ReducerAction<keyof FieldValues>) => ({
  ...state,
  [key]: value,
});

const emailCommunicationTitle = "Email Communication Preferences";
const marketingLabel = "Mirantis Marketing Email Communications";
const onboardingLabel = "Lens Product Information, Updates & Onboarding Assistance";

export const Profile = () => {
  const { track, trackError } = useAnalytics();
  const { profile, updateProfile } = useContext(ProfileContext);
  const logout = useLogout();
  const {
    uploadAvatar,
    loading: uploadingAvatar,
    error: uploadAvatarError,
    response: avatarUploadResult,
  } = useUploadAvatar();
  const lensPlatformClient = useLensPlatformClient();
  const [profileError, setProfileError] = useState<null | string>(null);
  const [profileSuccessMessage, setProfileSuccessMessage] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const flashDuration = 2000;
  const [emailCommunicationSwitches, setEmailCommunicationSwitches] = useState<Array<SwitchValue>>();
  const {
    userCommunicationPreferences,
    errorUpdateUserCommunictaionPreferences,
    updateUserCommunicationPreferences,
    refetchUserCommunicationPreferences,
  } = useUserCommunicationPreferences();

  // Store form fields values and validation status
  const [fields, setField] = useReducer(valueReducer, {
    firstName: { value: "" },
    lastName: { value: "" },
    company: { value: "" },
  });

  const formValid = useMemo(() => {
    const fieldValues = Object.values(fields);

    return Boolean(fieldValues.length) && fieldValues.every((v) => v.valid);
  }, [fields]);

  // user attributes returned from get endpoint have a different interface comparing to update endpoint.
  // TODO: Update user attributes to the same format for both endpoints.
  useEffect(() => {
    if (!profile) {
      return;
    }

    const company = profile.userAttributes?.find((attribute) => attribute.name === "company");

    setField({ key: "company", value: company ? company.value : "", valid: Boolean(company) });
    setField({ key: "firstName", value: profile.firstName || "", valid: true });
    setField({ key: "lastName", value: profile.lastName || "", valid: true });
  }, [profile]);

  useEffect(() => {
    setEmailCommunicationSwitches([
      {
        id: "onboarding",
        label: onboardingLabel,
        checked: userCommunicationPreferences.onboarding,
      },
      {
        id: "marketing",
        label: marketingLabel,
        checked: userCommunicationPreferences.marketing,
      },
    ]);
  }, [userCommunicationPreferences]);

  const handleSwitchChange = useCallback(
    async (nextSwitches: Array<SwitchValue>) => {
      const previousSwitches = emailCommunicationSwitches || [];

      setEmailCommunicationSwitches(nextSwitches);

      if (profile?.username) {
        try {
          await updateUserCommunicationPreferences({
            onboarding: nextSwitches[0].checked,
            marketing: nextSwitches[1].checked,
          });
        } catch {
          setTimeout(() => {
            // revert switch to previous state
            setEmailCommunicationSwitches(previousSwitches);
          }, 500);
        }
      }
    },
    [emailCommunicationSwitches, profile?.username, updateUserCommunicationPreferences],
  );

  const handleUploadAvatar = async (file: File) => {
    if (profile && profile.username && file) {
      const uploaded = await uploadAvatar(file);

      if (uploaded) {
        updateProfile();
      }

      return uploaded;
    }

    return undefined;
  };

  const handleAvatarError = (error: Error) => {
    setProfileError(error?.message);
  };

  const saveProfile = async () => {
    if (profile && profile.username && formValid) {
      const updatedProfile = { ...profile };

      // API doesn't allow these fields.
      delete updatedProfile.userAttributes;
      delete updatedProfile.fullname;

      try {
        // Clear error and success messages first
        setProfileSuccessMessage(null);
        setProfileError(null);

        setIsLoading(true);

        await lensPlatformClient.user.updateOne(profile.username, {
          ...updatedProfile,
          attributes: {
            company: fields.company.value,
          },
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
        });

        setProfileSuccessMessage("Profile updated");

        updateProfile();

        track("Profile updated");
        setIsLoading(false);

        // remove success test
        setTimeout(() => {
          setProfileSuccessMessage(null);
        }, flashDuration);
      } catch (error: unknown) {
        setIsLoading(false);

        trackError("Profile update failed");

        if (error instanceof Error) {
          const fields: { [key: string]: string } = {
            "attributes.company": "Company",
            firstName: "First name",
            lastName: "Last name",
          };
          const errors = (Array.isArray(error.message) ? error.message : [error.message]).map((message) => {
            const match = message.match(/([a-zA-Z.]*) should not be empty$/);

            if (match) {
              const key: string = match[1];

              return message.replace(key, fields[key] || "");
            }

            return message;
          });

          setProfileError(errors.join("\n"));
        } else {
          setProfileError("Something went wrong");
        }
      }
    }
  };

  return (
    <Layout className="bg-secondary" profile={profile} logout={logout}>
      {profile && (
        <div className={styles.profile}>
          {(profileError || uploadAvatarError || errorUpdateUserCommunictaionPreferences) && (
            <Notification
              flashDuration={flashDuration}
              level="error"
              message={profileError || uploadAvatarError?.message || errorUpdateUserCommunictaionPreferences?.message}
              type="flash"
            />
          )}
          {profileSuccessMessage && (
            <Notification
              className={styles.serverResponseMessage}
              flashDuration={flashDuration}
              level="success"
              message={profileSuccessMessage}
              type="flash"
            />
          )}
          {!uploadAvatarError && avatarUploadResult && (
            <Notification
              className={styles.serverResponseMessage}
              flashDuration={flashDuration}
              level="success"
              message="User avatar successfully updated!"
              type="flash"
            />
          )}
          <section className="mb-6">
            <section className="mb-6">
              <h2 className={clsx("lds-h2-caps", styles.welcome)}>Profile</h2>
              <p>This is your public profile. Information in here may be displayed publicly to other users.</p>
            </section>
            <section className="mb-6">
              <div className="grid xl:grid-cols-2 grid-cols-1">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveProfile();
                  }}
                >
                  <FormInput
                    label="First name"
                    value={fields.firstName.value}
                    onChange={(value, valid) => setField({ key: "firstName", value, valid })}
                    name="firstName"
                  />
                  <FormInput
                    label="Last name"
                    value={fields.lastName.value}
                    onChange={(value, valid) => setField({ key: "lastName", value, valid })}
                    name="lastName"
                  />
                  <FormInput
                    label="Company"
                    value={fields.company.value}
                    onChange={(value, valid) => setField({ key: "company", value, valid })}
                    name="company"
                    required
                  />
                  <p>
                    Personally Identifiable Information (PII) on this page is optional and can be deleted at any time,
                    and by filling them out, you&apos;re giving us consent to share this data wherever your user profile
                    appears. Please see our privacy policy to learn more about how we use this information.
                  </p>
                  <Button
                    loading={isLoading}
                    label="Update Profile"
                    id="update-profile"
                    buttonType="submit"
                    disabled={!formValid}
                    primary
                  />
                  <section className={styles.communicationPreferences}>
                    <FormSwitchGroup
                      label={emailCommunicationTitle}
                      values={emailCommunicationSwitches || []}
                      onChange={handleSwitchChange}
                    />
                  </section>
                </form>
                <div className="grid xl:grid-cols-2 grid-cols-1">
                  <div className="m-6 ">
                    <ProfileAvatar
                      onAvatarError={handleAvatarError}
                      user={profile}
                      loading={uploadingAvatar}
                      onAvatarUpload={handleUploadAvatar}
                    />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      )}
    </Layout>
  );
};
