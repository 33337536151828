import clsx from "clsx";
import type { Step } from "@k8slens/lds-tips/lib/es/Tips/Steps";

import { TipContent } from "./TipContent";

import styles from "./Tip.module.css";

export const selectIdStepId = "id-select";
export const selectIdTipId = `${selectIdStepId}-step`;

export const IdSelectStep: Step = {
  id: selectIdStepId,
  selector: `#${selectIdTipId}`,
  theme: "important",
  pointerClassName: styles.pointer,
  popupClassName: clsx(styles.popup, styles.selectIdPopup),
  placement: "right-end",
  pointerOffset: [-25, -19],
  content: (
    <TipContent
      title={
        <span>
          Switch between <u>your</u> Lens ID and Lens Business IDs
        </span>
      }
      content="Lens Business ID is for managing Lens subscriptions, seats and settings for your organization. Switch between your personal Lens ID and your Lens Business IDs easily from here!"
    />
  ),
};
