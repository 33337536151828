import React from "react";
import type { UserWithEmail } from "lens-platform-sdk";

import { getUserAvatar } from "src/utils/user";
import useAvatarUrl from "src/hooks/useAvatarUrl";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";

import Avatar from "src/components/Avatar/Avatar";

import styles from "./IDSelectItem.module.css";

interface Props {
  /** The data of item passed from parent <Select /> */
  item?: {
    id: string;
    label: string;
    subtitle?: string;
  };
  profile?: UserWithEmail;
}

/**
 * id to be used in the select options
 */
const usernameOptionId = "usernameOptionId";

/**
 * The item component to be used in `<IDSelect />`
 */
const IDSelectItem: React.FC<Props> = ({ item, profile }) => {
  const id = item?.id;
  const title = item?.label ?? "";
  const platformClient = useLensPlatformClient();
  const avatarImage = profile ? getUserAvatar(profile) : undefined;
  const avatar = useAvatarUrl(avatarImage && id === usernameOptionId ? avatarImage : undefined);

  // the item is a "Lens Business ID"
  let subTitle = item?.subtitle ?? "Lens Business ID";
  let avatarName = title.replace(/^@/, ""); // strip @

  // the item is username
  if (id === usernameOptionId && profile) {
    subTitle = platformClient.user.getUserFullName(profile);
    avatarName = profile.username || "";
  }

  return (
    <>
      <Avatar image={avatar} name={avatarName} className={styles.avatar} />
      <div className={styles.label}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subTitle}</div>
      </div>
    </>
  );
};

export default IDSelectItem;
