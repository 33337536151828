import { useKeycloak } from "@react-keycloak/web";
import { useCallback, useEffect, useRef, useState } from "react";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";

export const useGetSSOByBusinessHandle = (businessHandle: string | undefined) => {
  const { keycloak } = useKeycloak();
  const lensPlatformClient = useLensPlatformClient();
  const [loginUrl, setLoginUrl] = useState("");
  const [error, setError] = useState<LensSDKException | null>(null);
  const [loading, setLoading] = useState(false);

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getSSOByBusinessHandle = useCallback(
    async (businessHandle: string | undefined) => {
      if (!businessHandle) {
        return;
      }

      try {
        if (mounted.current) {
          setLoading(true);
        }

        const ssoData = await lensPlatformClient.sso.getSSOByBusinessHandle(businessHandle);

        if (mounted.current && ssoData && ssoData.identityProviderID) {
          const loginUrl = keycloak.createLoginUrl({
            idpHint: ssoData.identityProviderID,
            redirectUri: window.location.origin,
          });

          setLoginUrl(loginUrl);
          setError(null);
        }
      } catch (exception) {
        if (mounted.current && exception instanceof LensSDKException) {
          setError(exception);
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [keycloak, lensPlatformClient.sso],
  );

  useEffect(() => {
    getSSOByBusinessHandle(businessHandle);
  }, [getSSOByBusinessHandle, businessHandle]);

  return { loginUrl, loading, error };
};
