import { useCallback, useEffect, useMemo, useState } from "react";
import { getData } from "countries-data-list";
import { Notification } from "@k8slens/lds";
import { FormInput } from "@k8slens/lds-form";
import { base } from "@k8slens/lds-icons";

import type { NewBusiness } from "src/hooks/useCreateBusiness";
import { Option } from "src/components/Combobox/Combobox";
import { FormCombobox } from "src/components/FormComponents/FormCombobox";
import clsx from "clsx";
import { dataToFieldValues, FieldDefaultMap } from "src/utils/field-value";

import { businessHandleValidation } from "lens-platform-sdk";
import { Popover } from "@headlessui/react";
import styles from "./BusinessIdForm.module.css";
import { defaultErrorParser } from "../FormComponents/FormSelect";

const { InfoIcon } = base;

const countryOptions: Array<Option> = getData().map(({ code, name }) => ({
  id: code,
  label: name,
}));

export interface BusinessIdFormProps {
  title: string;
  initialData?: NewBusiness;
  onChange(newAccount: Partial<NewBusiness>, isValid: boolean): void;
  error?: Error | null;
  singleColumn?: boolean;
  disabled?: boolean;
}

type BusinessForm = {
  [K in keyof Omit<NewBusiness, "country">]: NewBusiness[K];
} & {
  country: Option | null | undefined;
  additionalAddress: string | undefined;
  state: string | undefined;
};

type BusinessFormField = FieldDefaultMap<NewBusiness, BusinessForm>;
const dataFieldDefaults: BusinessFormField = {
  name: { value: "", valid: false },
  phoneNumber: { value: "", valid: false },
  address: { value: "", valid: false },
  additionalAddress: { value: "" },
  country: {
    value: undefined,
    valid: false,
    transform: (value: undefined | string) => countryOptions.find(({ label }) => label === value),
  },
  state: { value: "" },
  city: { value: "", valid: false },
  handle: { value: "", valid: false },
  zip: { value: "", valid: false },
  websiteUrl: { value: "", valid: false },
  department: { value: "", valid: false },
  verifiedDomains: { value: [] },
  reseller: { value: false },
  automaticSeatAssignment: { value: true },
};

const BusinessIdForm: React.FC<BusinessIdFormProps> = ({
  title,
  initialData: _initialData,
  onChange,
  error,
  disabled = false,
  singleColumn = false,
}) => {
  const initialData = useMemo(() => dataToFieldValues(_initialData, dataFieldDefaults), [_initialData]);

  const [name, setName] = useState<BusinessFormField["name"]>(initialData.name);
  const [websiteUrl, setWebsiteUrl] = useState<BusinessFormField["websiteUrl"]>(initialData.websiteUrl);
  const [department, setDepartment] = useState<BusinessFormField["department"]>(initialData.department);
  const [phoneNumber, setPhoneNumber] = useState<BusinessFormField["phoneNumber"]>(initialData.phoneNumber);
  const [address, setStreet] = useState<BusinessFormField["address"]>(initialData.address);
  const [additionalAddress, setAdditionalAddress] = useState<BusinessFormField["additionalAddress"]>(
    initialData.additionalAddress,
  );
  const [country, setCountry] = useState<BusinessFormField["country"]>(initialData.country);
  const [state, setState] = useState<BusinessFormField["state"]>(initialData.state);
  const [city, setCity] = useState<BusinessFormField["city"]>(initialData.city);
  const [zip, setZip] = useState<BusinessFormField["zip"]>(initialData.zip);
  const [handle, setHandle] = useState<BusinessFormField["handle"]>(initialData.handle);

  const value: Partial<NewBusiness> = useMemo(
    () => ({
      name: name.value,
      phoneNumber: phoneNumber.value,
      address: address.value,
      additionalAddress: additionalAddress.value,
      country: country.value?.label,
      city: city.value,
      state: state.value,
      zip: zip.value,
      websiteUrl: websiteUrl.value,
      department: department.value,
      handle: handle.value,
    }),
    [name, phoneNumber, address, additionalAddress, country, state, city, zip, websiteUrl, department, handle],
  );

  const valid = useMemo(
    () =>
      [name, phoneNumber, address, additionalAddress, country, state, city, zip, department, websiteUrl, handle].every(
        ({ valid }) => valid !== false,
      ),
    [name, phoneNumber, address, additionalAddress, country, state, city, zip, department, websiteUrl, handle],
  );

  const handleInputError = useCallback((value: string, error: string) => {
    if (error === "patternMismatch") {
      return "Allowed characters A-Z, a-z, 0-9, -, Minimum length is 3 and maximum length is 15 characters. Should start with a letter.";
    }

    return defaultErrorParser(
      value,
      error,
      {
        minLength: 3,
      },
      "",
    );
  }, []);

  useEffect(() => {
    onChange(value, valid);
  }, [value, onChange, valid]);

  const validateHandle = useCallback((value: string) => {
    if (!businessHandleValidation.test(value)) {
      return "patternMismatch";
    }

    return undefined;
  }, []);

  return (
    <form aria-label={title} autoComplete="on" lang="en">
      <fieldset className={clsx(styles.gridForm, styles.businessHandle)}>
        <FormInput
          label="Business ID"
          name="handle"
          className={styles.businessHandleInput}
          value={handle.value}
          validate={validateHandle}
          errorParser={handleInputError}
          onChange={(value, valid) => setHandle({ value, valid })}
          required
          disabled={disabled}
        />
        <Popover>
          <Popover.Button data-testid="businessHandlePreview" className={styles.infoButton}>
            <InfoIcon size="sm" color="ok" />
          </Popover.Button>
          <Popover.Panel className={styles.infoContent}>
            <dl>
              <dd>Business ID is used to uniquely identify your business on Lens applications</dd>
            </dl>
          </Popover.Panel>
        </Popover>
      </fieldset>
      <fieldset className={clsx(styles.gridForm, { [styles.twoCol]: !singleColumn })}>
        <FormInput
          label="Organization Name"
          name="organization"
          autoComplete="organization"
          value={name.value}
          onChange={(value, valid) => setName({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="Department Name"
          name="department"
          autoComplete="department"
          value={department.value}
          onChange={(value, valid) => setDepartment({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="Website URL"
          name="websiteUrl"
          autoComplete="websiteUrl"
          value={websiteUrl.value}
          onChange={(value, valid) => setWebsiteUrl({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="Address Line"
          name="address-line1"
          autoComplete="address-line1"
          value={address.value}
          onChange={(value, valid) => setStreet({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="Address Line 2"
          name="address-line2"
          autoComplete="address-line2"
          value={additionalAddress.value}
          onChange={(value, valid) => setAdditionalAddress({ value, valid })}
          disabled={disabled}
        />
        <FormCombobox
          label="Country"
          name="country-name"
          options={countryOptions}
          value={country.value}
          onChange={(value, valid) => setCountry({ value, valid })}
          autoComplete="off"
          translate="no"
          required
          disabled={disabled}
        />
        <FormInput
          label="City"
          name="address-level2"
          autoComplete="address-level2"
          value={city.value}
          onChange={(value, valid) => setCity({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="State / Province"
          name="address-level1"
          autoComplete="address-level1"
          value={state.value}
          onChange={(value, valid) => setState({ value, valid })}
          disabled={disabled}
        />
        <FormInput
          label="Postal / Zip code"
          name="postal-code"
          autoComplete="postal-code"
          value={zip.value}
          onChange={(value, valid) => setZip({ value, valid })}
          required
          disabled={disabled}
        />
        <FormInput
          label="Telephone number"
          name="tel"
          autoComplete="tel"
          type="tel"
          value={phoneNumber.value}
          onChange={(value, valid) => setPhoneNumber({ value, valid })}
          required
          disabled={disabled}
        />
      </fieldset>
      {error?.message && <Notification level="error" message={error.message} type="flash" />}
    </form>
  );
};

export default BusinessIdForm;
