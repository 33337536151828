import styles from "./KeycloakLoading.module.css";

const DefaultContent: React.FC<{}> = () => (
  <div className={styles.bg}>
    <span data-testid="keycloak-not-initialized-text" />
  </div>
);

export const KeycloakLoading = ({ content: Content = DefaultContent }) => {
  return <Content />;
};
