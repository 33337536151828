import clsx from "clsx";
import { Panel, ButtonBar } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getPriceData, PlanCode } from "src/services/plans";
import { formatCurrency } from "src/utils/currency";

import Button from "src/components/Button/TrackedButton";

import Aside, { panelProps, AsideProps } from "./Aside";

import styles from "./OrderSummary.module.css";

const getCheckoutActionLabel = (planCode: PlanCode) => {
  switch (planCode) {
    case "pro-yearly":
    case "pro-monthly":
      return "Continue";
    case "pro-trial":
      return "Start your 14-day trial";
    case "personal":
      return "Start using for free";
    default:
      return "";
  }
};

export interface OrderSummaryProps {
  panelClassName?: AsideProps["panelClassName"];
  loading?: boolean;
  planCode: PlanCode;
  quantity: number;
  purchasingDisabled?: boolean;
  onPurchase?(planCode: PlanCode): Promise<boolean>;
}

const tax = 0; // TODO: Calculate tax?

const OrderSummary: React.FC<OrderSummaryProps> = ({
  panelClassName,
  loading,
  planCode,
  quantity: seatCount,
  purchasingDisabled,
  onPurchase,
}) => {
  const { track } = useAnalytics();
  const priceData = getPriceData(planCode);

  const handlePurchase = onPurchase ? () => onPurchase(planCode) : undefined;

  // Helpers for calculating price
  const seatCountPlural = seatCount > 1;
  const totalExcludingTax = seatCount * priceData.value;
  const total = totalExcludingTax + tax;

  return (
    <Aside
      className={styles.aside}
      panelClassName={clsx(styles.asidePanel, panelClassName)}
      toggleClassName={styles.asideToggle}
      openedLabel="Show less"
      closedLabel={
        <span className={styles.closedLabel}>
          <strong>
            Total: {formatCurrency(total)} {priceData.perText}
          </strong>
          <span>Show more</span>
        </span>
      }
      onToggle={(open) => track("Toggle Order Summary", { open })}
    >
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...panelProps}
        className={clsx(panelProps.className, styles.shoppingCart)}
        header={<h5>Order Summary</h5>}
        footerComponent={ButtonBar}
        footerProps={{
          as: "footer",
          type: "stacked",
        }}
        footer={
          handlePurchase && (
            <Button
              loading={loading}
              label={getCheckoutActionLabel(planCode)}
              primary
              textTransform="uppercase"
              loadingStateOnPromise
              disabled={purchasingDisabled}
              onClick={handlePurchase}
            />
          )
        }
      >
        <>
          <div role="heading" aria-level={6} aria-flowto="seats total tax">
            {priceData.title}
          </div>
          <p aria-live="polite" aria-atomic="true">
            <span
              id="seats"
              className={styles.priceBreakdown}
              aria-label={`${seatCount} ${seatCountPlural ? "seats" : "seat"} at ${formatCurrency(priceData.value)} ${
                seatCountPlural ? "each " : ""
              }`}
            >
              <span aria-hidden>
                {seatCount} {seatCountPlural ? "seats" : "seat"}
              </span>
              <span aria-hidden>{formatCurrency(totalExcludingTax)}</span>
            </span>
            <span
              id="tax"
              className={styles.priceBreakdown}
              aria-label={`including vat / sales tax of ${formatCurrency(tax)} `}
            >
              <span aria-hidden>VAT / Sales Tax</span>
              <span aria-hidden>{formatCurrency(tax)}</span>
            </span>
            <span
              id="total"
              className={clsx(styles.priceBreakdown, styles.total)}
              aria-label={`for the total price of ${formatCurrency(total)} ${priceData.perText} `}
            >
              <strong aria-hidden>Total</strong>
              <strong aria-hidden>
                {formatCurrency(total)} {priceData.perText}
              </strong>
            </span>
          </p>
          <p className={styles.taxDescription}>
            All prices without VAT / Sales Tax. The VAT / Sales Tax amount will be added at the checkout.
          </p>
        </>
      </Panel>
    </Aside>
  );
};

export default OrderSummary;
