import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Panel } from "@k8slens/lds";

import { usePurchaseQuantity } from "src/hooks/usePurchaseQuantity";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { PlanCode } from "src/services/plans";
import { useQuery } from "src/hooks/useQuery";
import { useAnalytics } from "src/hooks/useAnalytics";

import { LocationState } from "src/App";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import SignUp, { githubParam, googleParam } from "src/components/SignUp/SignUp";
import { FormState, GetSubmitButtonLabel } from "src/components/SignUp/form";
import SinglePageForm from "src/components/SignUp/SinglePageForm";

import { KeycloakLoadingContent } from "./KeycloakLoadingContent";
import OrderSummary from "./OrderSummary";

import styles from "./SignUpStep.module.css";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { KeycloakLoading } from "src/components/KeycloakLoading/KeycloakLoading";

const getSubmitButtonLabel: GetSubmitButtonLabel = ({ formState, signupState }) => {
  switch (formState) {
    case "complete":
      if (signupState === "return-from-github" || signupState === "return-from-google") {
        return "Log In to purchase";
      }

      return "Resend Email Verification";
    default:
      return "Sign up to purchase";
  }
};

export interface Props {
  showCart: boolean;
  planCode: PlanCode;
  onSignup(planCode: PlanCode, type?: "github" | "google"): Promise<boolean>;
  redirectPath?: string;
}

const loginButtonLabel = "Login with Lens ID";

const SignUpStep: React.FC<Props> = ({ showCart, planCode, onSignup, redirectPath }) => {
  const { trackButtonClicked } = useAnalytics();
  const history = useHistory<LocationState | undefined>();
  const { keycloak } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const keycloakLoading = useKeycloakLoading();

  const passThroughKeys = usePassThroughKeys();
  const quantity = usePurchaseQuantity() || 1;
  const signinPath = addPassThroughKeys("login", passThroughKeys);

  const [formState, setFormState] = useState<FormState | undefined>();
  const handleFormStateChange = useCallback((state: FormState) => setFormState(state), []);

  const {
    location: { pathname },
  } = history;
  const query = useQuery();
  let type: "github" | "google" | undefined;

  if (query.get(githubParam)) {
    type = "github";
  } else if (query.get(googleParam)) {
    type = "google";
  }

  const signingUpFromIdp = Boolean(type);
  const isAuthenticated = !signingUpFromIdp && keycloak?.authenticated;
  const [signingUp, setSigningUp] = useState<boolean>(false);

  useEffect(() => {
    if (!signingUp && isAuthenticated) {
      // TODO: Give user some feedback, because the operation might be async
      onSignup(planCode, type);
      setSigningUp(true);
    }

    return () => {};
  }, [isAuthenticated, type, planCode, onSignup, signingUp]);

  if (keycloakTimeout) {
    return (
      <Panel className={styles.login} contentComponent="div">
        <KeycloakTimeout />
      </Panel>
    );
  }

  if (keycloakLoading) {
    return (
      <Panel className={styles.signup} contentComponent="div">
        <KeycloakLoading content={KeycloakLoadingContent} />
      </Panel>
    );
  }

  return (
    <Panel className={styles.signup} contentComponent="div">
      {formState !== "complete" && (
        <>
          <h3 className="lds-h1">Sign up with Lens ID</h3>
          <aside className={styles.info}>
            Already have Lens ID?{" "}
            <button
              type="button"
              onClick={() => {
                trackButtonClicked(loginButtonLabel);
                history.replace(signinPath);
              }}
            >
              {loginButtonLabel}
            </button>
          </aside>
        </>
      )}
      <SignUp
        disabled={isAuthenticated}
        signinPath={signinPath}
        redirectPath={redirectPath}
        githubRedirectPath={addPassThroughKeys(`${window.location.origin}${pathname}`, passThroughKeys, {
          [githubParam]: true,
        })}
        googleRedirectPath={addPassThroughKeys(`${window.location.origin}${pathname}`, passThroughKeys, {
          [googleParam]: true,
        })}
        form={SinglePageForm}
        wrapperClassName={styles.signupForm}
        getSubmitButtonLabel={getSubmitButtonLabel}
        onFormStateChange={handleFormStateChange}
      />
      {showCart && <OrderSummary quantity={quantity} planCode={planCode} />}
    </Panel>
  );
};

export default SignUpStep;
