import { useEffect, useState, useRef, useCallback } from "react";
import type { Business, LensPlatformClient } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Type for creating a new business entity.
 */
export type NewBusiness = Parameters<LensPlatformClient["business"]["createOne"]>[0];

/**
 * Hook for creating business entities (Lens Business IDs)
 */
export const useCreateBusiness = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [error, setError] = useState<LensSDKException | undefined>();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Business | undefined>();

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const createBusiness = useCallback(
    async (business: NewBusiness) => {
      try {
        if (mounted.current) {
          setLoading(true);
        }
        const response = await lensPlatformClient.business.createOne(business);

        if (mounted.current) {
          setResponse(response);
          setError(undefined);
        }

        return response;
      } catch (exception) {
        if (mounted.current && exception instanceof LensSDKException) {
          setError(exception);
        }

        return undefined;
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [lensPlatformClient.business],
  );

  return {
    createBusiness,
    response,
    loading,
    error,
  };
};
