import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useRef, useState } from "react";

const defaultWaitFor = 6_000;

/**
 * @returns true if keycloak is not initialized after timeout
 */
export const useKeycloakTimeout = (waitFor = defaultWaitFor) => {
  const [keycloakTimeout, setKeycloakTimeout] = useState(false);
  const { initialized } = useKeycloak();

  // useRef is needed to reference the latest value in setTimeout
  const initializedRef = useRef(initialized);

  initializedRef.current = initialized;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!initializedRef.current) {
        setKeycloakTimeout(true);
      }
    }, waitFor);

    return () => clearTimeout(timer);
  }, [waitFor]);

  return keycloakTimeout;
};
