import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Panel, ButtonBar, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import { ProfileContext } from "src/providers/profile-provider";
import { useAcceptBusinessInvitation } from "src/hooks/useAcceptBusinessInvitation";
import { useBusinessInvitation } from "src/hooks/useBusinessInvitation";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedLinkButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import CurrentUser from "src/components/CurrentUser/CurrentUser";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import styles from "./AcceptBusinessInvitation.module.css";
import { useKeycloak } from "@react-keycloak/web";

export const AcceptBusinessInvitation = () => {
  const { keycloak } = useKeycloak();
  const { track } = useAnalytics();
  const history = useHistory();
  const onAcceptSuccess = useCallback(
    (role: string, businessId: string, invitationId: string) => {
      void keycloak.updateToken(-1);
      track("Business Invitation Accepted");

      if (role === "Administrator") {
        history.push(`/business/${businessId}`, {
          invitationId,
        });
      } else {
        history.push("/home", {
          invitationId,
        });
      }
    },
    [history, track, keycloak],
  );

  const {
    acceptBusinessInvitation,
    error: invitationError,
    isLoading,
    isNoSeat,
  } = useAcceptBusinessInvitation(onAcceptSuccess);
  const { profile } = useContext(ProfileContext);
  const { invitation, error } = useBusinessInvitation();
  const username = profile?.username;

  const handleAccept = () => {
    if (invitation?.businessId && invitation?.invitationId) {
      acceptBusinessInvitation({
        businessId: invitation?.businessId,
        invitationId: invitation?.invitationId,
      });
    }
  };

  const invalidKey = Boolean(error) || !invitation;

  return (
    <PublicLayout size="xl" className={styles.acceptInvitation} footer={<CurrentUser />}>
      <Panel
        className={styles.panel}
        header={
          <>
            <h2>Accept Business ID Invitation</h2>
          </>
        }
        footer={
          <ButtonBar type="grid" gridSize={4}>
            <LinkButton to="/home" label="Cancel" discreet disabled={isNoSeat} />
            <div />
            <div />
            {isNoSeat ? (
              <Button
                label="Ok"
                onClick={() => {
                  void keycloak.updateToken(-1);
                  history.push("/home", {
                    invitationId: invitation?.invitationId,
                  });
                }}
                loading={isLoading}
                disabled={isLoading}
                primary
              />
            ) : (
              <Button label="Accept" onClick={handleAccept} loading={isLoading} disabled={isLoading} primary />
            )}
          </ButtonBar>
        }
      >
        <>
          {!invalidKey && (
            <p>
              This invitation will add <strong>{username}</strong> to the Lens Business ID{" "}
              <strong>{invitation?.company}</strong>.
            </p>
          )}
          {invitationError && <Notification level="error" message={invitationError?.message} type="closable" />}
          {invalidKey && (
            <div className={styles.error}>
              <h4>Something went wrong</h4>
              <p>There seems to be some issues with your invitation.</p>
              <p>
                Try re-opening the link in your invitation email. If that doesn&apos;t help, please contact us at{" "}
                <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
              </p>
            </div>
          )}
        </>
      </Panel>
    </PublicLayout>
  );
};
