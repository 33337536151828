/* eslint-disable react/jsx-props-no-spreading */
import { useInvitation } from "src/hooks/useInvitation";
import formStyles from "src/components/styles/form.module.css";

type Props = {
  fromEmailVerificationPage: boolean;
  invitationKey: string;
};

const Subtitle = ({ fromEmailVerificationPage, invitationKey }: Props) => {
  const { invitation } = useInvitation();

  if (fromEmailVerificationPage) {
    return (
      <>
        Your <b>email</b> has been <b>verified</b>. Please login to continue.
      </>
    );
  }

  if (invitationKey.length > 0) {
    return (
      <>
        You have been invited to join space <b>{invitation?.spaceName}</b> by <b>{invitation?.createdBy?.username}</b>.
      </>
    );
  }

  return <>Manage your Lens identity.</>;
};

const SubtitleWrapper = (props: Props) => (
  <h2 className={formStyles.subtitle}>
    <Subtitle {...props} />
  </h2>
);

export default SubtitleWrapper;
