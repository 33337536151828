import { useCallback, useState } from "react";
import clsx from "clsx";
import { ButtonBar, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import Credentials from "./Credentials";
import Profile from "./Profile";
import Complete from "./Complete";
import IDPButtons from "./IDPButtons";
import Terms from "./Terms";
import { FormComponentProps } from "./form";
import getSubmitButtonLabel from "./getSubmitButtonLabel";
import getNavDisabled from "./getNavDisabled";
import SubmitButton from "./SubmitButton";

import styles from "./SinglePageForm.module.css";
import MarketingConsent from "./MarketingConsent";

const SinglePageForm: React.FC<FormComponentProps> = ({
  disabled,
  wrapperClassName,
  formState,
  credentials,
  setCredentials,
  profile,
  setProfile,
  marketing,
  setMarketing,
  onSubmit,
  onResendEmailVerification,
  onLogin,
  resendingEmail,
  signupState,
  signUpWithGithub,
  signUpWithGoogle,
  errorMessage,
  getSubmitButtonLabel: _getSubmitButtonLabel,
}) => {
  const { track } = useAnalytics();

  const returningFromIdp = signupState === "return-from-github" || signupState === "return-from-google";
  /**
   * User don't need to agree TOS when signing up from IDP because they have already agreed on
   * Keycloak's side `terms.ftl` page.
   *
   * @see https://github.com/lensapp/lenscloud/issues/3662
   * @see https://github.com/lensapp/lenscloud/pull/3673
   * @see https://github.com/lensapp/lenscloud/pull/3675
   */
  const showTerms = !returningFromIdp;
  const termsAccepted = true;

  const handleSetMarketingConsent = useCallback(
    (value: boolean) => {
      setMarketing(value);
      track("Update Subsribe Newsletter", { value });
    },
    [track, setMarketing],
  );

  const submitButtonLabel = (_getSubmitButtonLabel || getSubmitButtonLabel)({
    fetchErrorMessage: errorMessage,
    formState,
    signupState,
    resendingEmail,
  });
  const navDisabled = getNavDisabled({
    formState,
    signupState,
    credentials,
    termsAccepted,
    error: errorMessage,
    resendingEmail,
  });

  const onCredentialsChange = (name: string, value: string, valid: boolean) => {
    setCredentials((credentials) => ({
      ...credentials,
      [name]: {
        value,
        valid,
      },
    }));
  };
  const onProfileChange = (name: string, value: string, valid: boolean) => {
    setProfile((profile) => ({
      ...profile,
      [name]: {
        value,
        valid,
      },
    }));
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      // prevents full-page refreshing as we are using <form />
      event.preventDefault();
      setIsLoading(true);

      if (formState === "credentials") {
        onSubmit().finally(() => setIsLoading(false));
      }

      if (formState === "complete") {
        if (returningFromIdp) {
          onLogin().finally(() => setIsLoading(false));

          return;
        }
        onResendEmailVerification().finally(() => setIsLoading(false));
      }
    },
    [formState, returningFromIdp, onLogin, onSubmit, onResendEmailVerification],
  );

  return (
    <form
      onSubmit={handleSubmit}
      data-testid="signup-form"
      className={wrapperClassName}
      aria-label="Signup form"
      autoComplete="on"
    >
      <fieldset disabled={disabled}>
        {formState !== "complete" && (
          <div className="signup-credentials">
            {errorMessage && (
              <Notification className={styles.error} level="error" type="flash" message={errorMessage} />
            )}
            <div className={styles.gridForm}>
              <Credentials
                credentials={credentials}
                onCredentialsChange={onCredentialsChange}
                signingUpFromGithub={signupState === "return-from-github"}
                signingUpFromGoogle={signupState === "return-from-google"}
              />
              <Profile profile={profile} onProfileChange={onProfileChange} />
            </div>
            <MarketingConsent
              className={styles.marketing}
              marketingSubscribed={marketing}
              setMarketingSubscribed={handleSetMarketingConsent}
            />
            {showTerms ? <Terms className={styles.acceptTerms} termsAccepted={termsAccepted} /> : null}
            <ButtonBar as="footer" className={styles.gridButtons} type="grid" gridSize={2}>
              <SubmitButton navDisabled={navDisabled} isLoading={isLoading} submitButtonLabel={submitButtonLabel} />
            </ButtonBar>
          </div>
        )}
        {formState === "complete" && (
          <div className={clsx(styles.complete, "signup-complete")}>
            <Complete
              signingUpFromGithub={signupState === "return-from-github"}
              signingUpFromGoogle={signupState === "return-from-google"}
              errorMessage={errorMessage}
            />
            <ButtonBar as="footer" type="centered">
              <SubmitButton navDisabled={navDisabled} isLoading={isLoading} submitButtonLabel={submitButtonLabel} />
            </ButtonBar>
          </div>
        )}
        {formState === "credentials" && !returningFromIdp && (
          <footer>
            <IDPButtons
              disabled={isLoading || disabled}
              signupState={signupState}
              signUpWithGithub={signUpWithGithub}
              signUpWithGoogle={signUpWithGoogle}
            />
          </footer>
        )}
      </fieldset>
    </form>
  );
};

export default SinglePageForm;
