import { ComponentType, PropsWithChildren } from "react";
import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import clsx from "clsx";

import styles from "./RadioGroup.module.css";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

type ClassName = string | ((d: { active: boolean; checked: boolean; disabled: boolean }) => string);

export type RadioGroupProps = ExtractProps<typeof HeadlessRadioGroup>;
export type RadioGroupOptionProps = Pick<
  PropsWithChildren<ExtractProps<typeof HeadlessRadioGroup.Option>>,
  "as" | "value" | "disabled" | "children"
> & {
  className?: ClassName;
  radioClassName?: string;
};
export const RadioGroup: React.FC<RadioGroupProps> = ({ className, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <HeadlessRadioGroup {...props} className={clsx(styles.radioGroup, className)}>
    {children}
  </HeadlessRadioGroup>
);

export const RadioGroupOption: React.FC<RadioGroupOptionProps> = ({
  className,
  radioClassName,
  children,
  ...props
}) => (
  <HeadlessRadioGroup.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={({ checked, disabled, ...d }: any) =>
      clsx(styles.radioOption, typeof className === "function" ? className({ checked, disabled, ...d }) : className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })
    }
  >
    <>
      <div className={clsx(styles.radio, radioClassName)} aria-hidden />
      {children}
    </>
  </HeadlessRadioGroup.Option>
);
