import { useContext } from "react";
import { type License } from "src/components/pages/Home/license";
import { TokenContext } from "src/providers/token-provider";

export const useGetLicense = () => {
  const { token } = useContext(TokenContext);

  if (token && token.license_type && token.license_iat) {
    return {
      type: token.license_type,
      features: token.license_features,
      // license_exp is in seconds, Date constructor expects milliseconds
      expiration: token.license_exp ? new Date(token.license_exp * 1000) : undefined,
      // license_iat is in seconds, Date constructor expects milliseconds
      issuedAt: token.license_iat ? new Date(token.license_iat * 1000) : undefined,
      trial: Boolean(token.license_trial),
    } as License;
  }

  return null;
};
