import { IdSelectStep } from "./id-select";
import { ManageSeatsStep } from "./manage-seats";

const tours = [
  {
    id: "user-select-tour",
    steps: [IdSelectStep],
  },
  {
    id: "business-id-tour",
    steps: [ManageSeatsStep],
  },
];

export default tours;
