import { decodeActivationKey } from "src/services/activationKey";
import { useActivationKey } from "./useActivationKey";

/**
 * Get `Activation` by decoding the activation key
 */
export const useActivation = () => {
  const activationKey = useActivationKey();
  let activation;
  let error;

  if (activationKey) {
    try {
      activation = decodeActivationKey(activationKey);
    } catch (err) {
      activation = undefined;
      error = err;
    }
  }

  return { activation, error };
};
