import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { usePrevious } from "./usePrevious";

export type Compare = (current: string, previous: string) => boolean;

export const useScrollToTop = (compare?: Compare) => {
  const location = useLocation();
  const current = location.pathname;
  const previous = usePrevious(current);

  useEffect(() => {
    if (previous && (!compare || compare(current, previous))) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [compare, current, previous]);
};
