import { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useKeycloak } from "@react-keycloak/web";
import { Button, ButtonBar, Notification } from "@k8slens/lds";
import Layout from "src/components/Layout/Layout";
import { useGetLicense } from "src/hooks/useGetLicense";
import { ProfileContext } from "src/providers/profile-provider";
import { TokenContext } from "src/providers/token-provider";
import { useQuery } from "src/hooks/useQuery";
import { useLogout } from "src/hooks/useLogout";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { useDownloadActivationCode } from "src/hooks/useDownloadActivationCode";
import { DownloadOfflineActivationCodeModal } from "src/components/DownloadOfflineActivationModal/DownloadOfflineActivationCodeModal";
import { UserSSOBanner } from "src/components/UserSSOBanner/UserSSOBanner";

import styles from "src/components/pages/Home/Home.module.css";
import { OFFLINE_ACTIVATION } from "src/constants";
import { useHistory, useLocation } from "react-router-dom";
import { useGetUserSSO } from "src/hooks/useGetUserSSO";
import { LicenseContainer } from "./LicenseContainer";

interface LocationState {
  invitationId: string;
  referrer: string;
  routeChangeBy: string;
}

const Home = () => {
  const history = useHistory();
  const location = useLocation<LocationState | undefined>();
  const { token } = useContext(TokenContext);
  const { profile } = useContext(ProfileContext);
  const keycloak = useKeycloak();
  const query = useQuery();
  const [downloaded, setDownloaded] = useState(false);
  const { currentSubscriptionSeat } = useGetSubscriptionSeats(profile?.username);
  const license = useGetLicense();
  const downloadOfflineActivationCode = useDownloadActivationCode();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { userSSOAccountsConnectionLinks } = useGetUserSSO();

  useEffect(() => {
    if (
      query.get("client_id") &&
      query.get("action") === "download" &&
      !downloaded &&
      keycloak?.keycloak?.clientId === "lens-extension" &&
      currentSubscriptionSeat?.id &&
      profile?.username
    ) {
      downloadOfflineActivationCode(profile.username, currentSubscriptionSeat.id);
      setDownloaded(true);

      return;
    }

    if (!location.state?.invitationId) {
      // don't redirect to root again to avoid infinite loop of / > /home > / > /home
      // don't redirect to root if the route change is by id-select
      const shouldRedirectToRoot =
        location.state?.referrer !== "/" &&
        location.state?.routeChangeBy !== "id-select" &&
        query.get("action") !== "download" &&
        process.env.NODE_ENV !== "test";

      if (shouldRedirectToRoot) {
        history.push("/");
      }
    }
  }, [
    query,
    downloaded,
    keycloak.keycloak.clientId,
    currentSubscriptionSeat,
    license?.type,
    downloadOfflineActivationCode,
    profile?.username,
    location.state?.invitationId,
    location.state?.referrer,
    location.state?.routeChangeBy,
    history,
  ]);

  const handleDownload = () => {
    if (query.get("client_id") && query.get("action") === "download") {
      setDownloaded(false);
      setIsModalOpened(false);
    } else {
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = `${window.location.origin}${window.location.pathname}?client_id=lens-extension&action=download`;
    }
  };

  const logout = useLogout();

  const canDownloadOfflineActivationCode = useMemo(
    () => Boolean(license?.features?.includes(OFFLINE_ACTIVATION)),
    [license?.features],
  );

  const isPersonalLicense = useMemo(() => token?.license_type === "personal", [token?.license_type]);

  return (
    <Layout className="bg-secondary" logout={logout} profile={profile}>
      {location.state?.invitationId && (
        <Notification
          level="info"
          type="flash"
          message="You have successfully accepted the Lens Business ID invitation."
        />
      )}
      <div className={styles.home}>
        <div>
          {profile && (
            <>
              <UserSSOBanner userSSOLinks={userSSOAccountsConnectionLinks} />
              <section className="mb-6">
                <h2 className={clsx("lds-h2", styles.welcome)}>Manage your Lens ID</h2>
                <p>Lens ID is your personal identity in the Lens universe.</p>

                <h3 className={styles.title}>Your Active Lens Subscription</h3>
                <p>
                  Some products and services (such as Lens Desktop and Lens Cloud Services) made available to you may
                  require active Lens Subscription. Your currently active subscription is displayed below.
                </p>
              </section>
              <section className={styles.subscriptions}>
                <LicenseContainer />
              </section>
              {token?.license_type ? (
                <section className="mt-6">
                  <h3 className={styles.title}>Lens ID offline activation code</h3>
                  <p>
                    Activate Lens Desktop with Activation Code. This activation method works for offline and air-gapped
                    environments. This activation code is for your use and should not be shared.
                  </p>
                  <ButtonBar className={styles.offlineButtonBar}>
                    <Button
                      label="Download Activation Code"
                      disabled={!canDownloadOfflineActivationCode}
                      primary
                      onClick={() => setIsModalOpened(true)}
                    />
                  </ButtonBar>
                  {!canDownloadOfflineActivationCode && !isPersonalLicense && (
                    <p>
                      <i>
                        <small>Your administrator has disabled the use of offline activation.</small>
                      </i>
                    </p>
                  )}
                  {!canDownloadOfflineActivationCode && isPersonalLicense && (
                    <p>
                      <small>
                        Offline activation is available with a Lens Pro subscription. Upgrade{" "}
                        <a className="primary" href="subscribe">
                          here.
                        </a>
                      </small>
                    </p>
                  )}
                </section>
              ) : null}
            </>
          )}
        </div>
      </div>
      {canDownloadOfflineActivationCode && (
        <DownloadOfflineActivationCodeModal
          isOpened={isModalOpened}
          onDownload={handleDownload}
          onCancel={() => setIsModalOpened(false)}
        />
      )}
    </Layout>
  );
};

export default Home;
