import { addSearchParams } from "src/utils/url";
import { PassThroughKeys } from "src/hooks/usePassThroughKeys";
import { activationKeyParameter } from "src/hooks/useActivationKey";
import { invitationKeyParameter } from "src/hooks/useInvitationKey";
import { businessInvitationKeyParameter } from "src/hooks/useBusinessInvitationKey";
import { businessChildInvitationTokenParameter } from "src/hooks/useBusinessChildInvitationToken";
import { purchaseQuantityKeyParameter } from "src/hooks/usePurchaseQuantityKey";

/**
 * Converts "/signin" to "/signin?[key-type]-key=<Key>" if Key is truthy.
 * Additional query parameters can be specified with queryParameters object.
 */
export const addPassThroughKeys = (
  url: string,
  {
    invitationKey,
    activationKey,
    purchaseQuantity,
    businessIdInvitationKey,
    businessChildInvitationToken,
  }: Partial<PassThroughKeys>,
  queryParameters?: Record<string, string | number | boolean>,
) =>
  addSearchParams(
    url,
    [
      { key: invitationKeyParameter, value: invitationKey },
      { key: activationKeyParameter, value: activationKey },
      { key: purchaseQuantityKeyParameter, value: purchaseQuantity },
      { key: businessInvitationKeyParameter, value: businessIdInvitationKey },
      { key: businessChildInvitationTokenParameter, value: businessChildInvitationToken },
    ],
    queryParameters,
  );
