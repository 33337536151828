import { useCallback } from "react";
import { ButtonBar } from "@k8slens/lds";
import { idp } from "@k8slens/lds-icons";

import Button from "src/components/Button/TrackedButton";

import { SignupState } from "./form";

const { GithubIcon, GoogleIcon } = idp;

export interface Props {
  signupState: SignupState;
  signUpWithGithub(): Promise<void>;
  signUpWithGoogle(): Promise<void>;
  disabled?: boolean;
}

const IDPButtons: React.FC<Props> = ({ disabled, signupState, signUpWithGithub, signUpWithGoogle }) => {
  const handleSignUpWithGithub = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
      event.preventDefault();
      signUpWithGithub();
    },
    [signUpWithGithub],
  );

  const handleSignUpWithGoogle = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
      event.preventDefault();
      signUpWithGoogle();
    },
    [signUpWithGoogle],
  );

  return (
    <ButtonBar type="grid">
      <Button
        icon={GithubIcon}
        disabled={disabled || signupState === "signing-up-with-github"}
        label={signupState === "signing-up-with-github" ? "Redirecting to Github" : "Sign Up with Github"}
        onClick={handleSignUpWithGithub}
        primary
        discreet
      />
      <Button
        icon={GoogleIcon}
        disabled={disabled || signupState === "signing-up-with-google"}
        label={signupState === "signing-up-with-google" ? "Redirecting to Google" : "Sign Up with Google"}
        onClick={handleSignUpWithGoogle}
        primary
        discreet
      />
    </ButtonBar>
  );
};

export default IDPButtons;
