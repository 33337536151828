import React, { useCallback, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Panel } from "@k8slens/lds";

import { addPassThroughKeys } from "src/services/passThroughKey";
import { ProfileContext } from "src/providers/profile-provider";
import { useQuery } from "src/hooks/useQuery";
import { useLogout } from "src/hooks/useLogout";

import PublicLayout from "src/components/PublicLayout/PublicLayout";
import StepBar from "src/components/StepBar/StepBar";
import { FormState } from "src/components/SignUp/form";
import SignUp, { githubParam, googleParam } from "src/components/SignUp/SignUp";
import SinglePageForm from "src/components/SignUp/SinglePageForm";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import { LocationState } from "src/App";

import { usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { Success } from "./Success";
import { getSteps } from "./getSteps";

import styles from "./SignUpPage.module.css";
import SignInForm, { GetRedirectUri } from "../../SignIn/SignIn";
import { getSignInRedirectUri } from "../../../utils/url";
import { useGetSignUpRedirectPath } from "../../../hooks/useGetRedirectPath";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { useOauthDanceQueries } from "src/hooks/useOauthDanceQueries";

const SignUpPage = () => {
  const history = useHistory<LocationState | undefined>();
  const query = useQuery();
  const passThroughKeys = usePassThroughKeys();
  const success = history.location.pathname === "/signup/success";
  const oauthDanceQueries = useOauthDanceQueries();

  const isOAuthSignUp = history.location.pathname.startsWith("/signup/realms/lensCloud/protocol/openid-connect/auth");

  const initialFormState: FormState = "credentials";
  const [formState, setFormState] = useState<FormState | undefined>(initialFormState);

  const handleFormStateChange = useCallback((state: FormState) => setFormState(state), []);

  const getKeyCloakRedirectUri: GetRedirectUri = useCallback(
    (redirectUri, loginOrigin) => getSignInRedirectUri(redirectUri, loginOrigin, passThroughKeys),
    [passThroughKeys],
  );

  const { getDescription, getFooter, ...currentStep } = getSteps.find(({ id }) => {
    if (success) {
      return id === "success";
    }

    if (formState === "complete") {
      return id === "email";
    }

    if (history.location.pathname === "/signup/signin") {
      return id === "signin";
    }

    return "identity";
  })!;

  const signoutAction = useLogout();
  const { keycloak } = useKeycloak();
  const keycloakLoading = useKeycloakLoading();
  const { profile } = useContext(ProfileContext);
  const signinPath =
    addPassThroughKeys("/signin/realms/lensCloud/protocol/openid-connect/auth", passThroughKeys) + oauthDanceQueries;
  const homePath = "/home";
  let type: "github" | "google" | undefined;

  if (query.get(githubParam)) {
    type = "github";
  } else if (query.get(googleParam)) {
    type = "google";
  }
  const signingUpFromIdp = Boolean(type);

  const keycloakTimeout = useKeycloakTimeout(
    // Wait longer if sign up from IDP
    // to allow us to check if `!keycloak.tokenParsed && initialized === true` => user did not accept TOC
    signingUpFromIdp ? 30_000 : undefined,
  );

  const isAuthenticatedWithIDP = !signingUpFromIdp && keycloak?.authenticated;
  const redirectPath = useGetSignUpRedirectPath();
  const [idpDone, setIdpDone] = useState<boolean>(false);

  const filteredSteps = getSteps.filter(({ id }) => {
    // Don't show email step for IDP process
    if (signingUpFromIdp && id === "email") {
      return false;
    }

    //  Signin is combined with email. Show confirm email first.
    if (id === "signin" && history.location.pathname !== "/signup/signin") {
      return false;
    }

    //  Signin is combined with email. Show login form after user confirms email
    if (id === "email" && history.location.pathname === "/signup/signin") {
      return false;
    }

    return true;
  });

  useEffect(() => {
    let mounted = true;

    if (!idpDone && isAuthenticatedWithIDP) {
      if (mounted) {
        setIdpDone(true);
        history.replace(redirectPath);
      }
    }

    return () => {
      mounted = false;
    };
  }, [redirectPath, isAuthenticatedWithIDP, history, idpDone]);

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  return (
    <PublicLayout
      footer={getFooter({
        profile,
        signinPath,
        signoutAction,
        homePath,
      })}
    >
      {/* Preload success image */}
      <link rel="preload" as="image" href="/static/media/screenshot-hero-faded.png" />
      <Panel
        className={styles.signup}
        header={
          <>
            <h2>Create Lens ID</h2>
            <p>{getDescription({ profile })} </p>
          </>
        }
      >
        <StepBar label="Signup progress" currentStep={currentStep} steps={filteredSteps} />
        <article>
          <Switch>
            {/* TODO: Add route guard */}
            <Route path="/signup/success">
              <Success />
            </Route>

            <Route path="/signup/signin">
              <SignInForm
                inApp={false}
                redirectUri={`${window.location.origin}/signup/success`}
                getKeyCloakRedirectUri={getKeyCloakRedirectUri}
                formWrapperClassName={styles.loginForm}
                footerClassName={styles.loginForm}
              />
            </Route>
            <Route path={["/signup/invitation-key/:invitationKey", "/signup"]}>
              <SignUp
                signinPath={redirectPath}
                isOAuthSignUp={isOAuthSignUp}
                githubRedirectPath={addPassThroughKeys(`${window.location.origin}/signup`, passThroughKeys, {
                  [githubParam]: true,
                })}
                googleRedirectPath={addPassThroughKeys(`${window.location.origin}/signup`, passThroughKeys, {
                  [googleParam]: true,
                })}
                passThroughKeys={{ ...passThroughKeys }}
                redirectPath={redirectPath}
                form={SinglePageForm}
                wrapperClassName={styles.signupForm}
                onFormStateChange={handleFormStateChange}
                initialFormState={initialFormState}
                disabled={keycloakLoading}
              />
            </Route>
            <Redirect to={{ ...history.location, pathname: "/signup" }} />
          </Switch>
        </article>
      </Panel>
    </PublicLayout>
  );
};

export default SignUpPage;
