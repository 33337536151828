import { ReactNode } from "react";
import { UserWithEmail } from "lens-platform-sdk";

import { Step as StepBarStep } from "src/components/StepBar/StepBar";

import { FooterProps } from "./common";
import { SuccessFooter } from "./SuccessFooter";
import { SignUpFooter } from "./SignUpFooter";
import { SuccessSignInFooter } from "./SuccessSignInFooter";

/* eslint-disable react/jsx-props-no-spreading */

type Step = "identity" | "email" | "signin" | "success";

export const getSteps: Array<
  StepBarStep<Step> & {
    getDescription(d: { profile?: UserWithEmail | null }): string | ReactNode;
    getFooter(d: FooterProps): ReactNode;
  }
> = [
  {
    id: "identity",
    label: "Identity",
    getDescription: () => "Provide your Lens ID credentials and profile information.",
    getFooter: (props) => <SignUpFooter {...props} />,
  },
  {
    id: "email",
    label: "Confirm Lens ID",
    getDescription: () => "Confirm your email address.",
    getFooter: (props) => <SignUpFooter {...props} />,
  },
  {
    id: "signin",
    label: "Confirm Lens ID",
    getDescription: () => "Finalize your new Lens ID by logging in.",
    getFooter: () => null,
  },
  {
    id: "success",
    label: "Success",
    getDescription: ({ profile }) => (
      <>
        Successfully created Lens ID
        {profile && (
          <>
            {": "}
            {profile?.username}
          </>
        )}
      </>
    ),
    getFooter: ({ profile, ...props }) =>
      profile ? <SuccessFooter profile={profile} {...props} /> : <SuccessSignInFooter profile={profile} {...props} />,
  },
];
