import { encodeActivationKey } from "src/services/activationKey";
import { useParams } from "react-router-dom";
import { useQuery } from "./useQuery";

export const activationKeyParameter = "activation-key";

/**
 * Get the activation key from query string parameter activation-key
 * or separate query string parameters subscriptionId, planName, planCode, company
 */
export const useActivationKey = () => {
  // First look for data in query
  // The activation key can be defined with "?activation-key=value" in the url
  // The activation key can be defined with "/:activationKey" route parameter
  // The value is undefined if there is no such route parameter
  const { activationKey: activationKeyRouteParam } = useParams<{
    activationKey?: string;
  }>();

  // The invitation key can be defined with "?invitation-key=value" in the url
  const query = useQuery();
  const activationKeyQueryParameter = query.get(activationKeyParameter);

  const activationKey = activationKeyQueryParameter || activationKeyRouteParam || "";

  if (activationKey) {
    return activationKey;
  }

  // TODO: Create the activation key in backend (getSubscriptionActivationUrl) so we don't need to do it here
  // Try to create activation key from separate query parameters

  return encodeActivationKey({
    subscriptionId: query.get("subscriptionId"),
    planCode: query.get("planCode"),
    planName: query.get("planName"),
    company: query.get("company"),
    invitationId: query.get("invitationId"),
    businessId: query.get("businessId"),
  });
};
