import { useKeycloak } from "@react-keycloak/web";
import { useContext } from "react";
import { ProfileContext } from "src/providers/profile-provider";

/**
 * Extends KeycloakTokenParsed to add real world attributes
 */
interface TokenParsed extends Keycloak.KeycloakTokenParsed {
  preferred_username?: string;
  name?: string;
  given_name?: string;
}

const fallBackUsername = (tokenParsed: TokenParsed | undefined) => {
  if (tokenParsed) {
    // `tokenParsed.preferred_username` is from Github
    // most users are sign-up from Github, prefer Github username
    // `tokenParsed.name` seems to be the same as `tokenParsed.given_name`
    const username = tokenParsed.preferred_username ?? tokenParsed.name ?? tokenParsed.given_name ?? "";

    return username;
  }

  return "";
};

export const useUsernameFromToken = () => {
  const { keycloak } = useKeycloak();

  return fallBackUsername(keycloak.tokenParsed);
};

export const useUsername = () => {
  const { profile } = useContext(ProfileContext);

  const usernameFromToken = useUsernameFromToken();

  // fallback to the username in token if the profile is still loading
  return profile?.username ? profile?.username : usernameFromToken;
};
