import { useKeycloak } from "@react-keycloak/web";

export const useKeycloakLoading = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return true;
  }

  return false;
};
