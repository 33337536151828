import { useMemo } from "react";
import { useQuery } from "./useQuery";

export const useOauthDanceQueries = () => {
  const query = useQuery();
  const clientIdQuery = query.get("client_id");
  const scopeQuery = query.get("scope");
  const responseTypeQuery = query.get("response_type");
  const responseModeQuery = query.get("response_mode");
  const redirectUriQuery = query.get("redirect_uri");
  const machineIdQuery = query.get("machine_id");
  const ssoQuery = query.get("sso");
  const sigQuery = query.get("sig");
  const stateQuery = query.get("state");
  const oauthDanceQueries = useMemo(() => {
    let value = "";

    if (redirectUriQuery) {
      value = `?redirect_uri=${encodeURIComponent(redirectUriQuery)}`;
    }

    if (clientIdQuery) {
      value = `${value}&client_id=${encodeURIComponent(clientIdQuery)}`;
    }

    if (scopeQuery) {
      value = `${value}&scope=${encodeURIComponent(scopeQuery)}`;
    }

    if (responseTypeQuery) {
      value = `${value}&response_type=${encodeURIComponent(responseTypeQuery)}`;
    }

    if (responseModeQuery) {
      value = `${value}&response_mode=${encodeURIComponent(responseModeQuery)}`;
    }

    if (stateQuery) {
      value = `${value}&state=${encodeURIComponent(stateQuery)}`;
    }

    if (machineIdQuery) {
      value = `${value}&machine_id=${encodeURIComponent(machineIdQuery)}`;
    }

    if (ssoQuery) {
      value = `${value}&sso=${encodeURIComponent(ssoQuery)}`;
    }

    if (sigQuery) {
      value = `${value}&sig=${encodeURIComponent(sigQuery)}`;
    }

    return value;
  }, [
    clientIdQuery,
    scopeQuery,
    responseTypeQuery,
    responseModeQuery,
    redirectUriQuery,
    stateQuery,
    machineIdQuery,
    ssoQuery,
    sigQuery,
  ]);

  return oauthDanceQueries;
};
