import Spinner from "src/components/Spinner/Spinner";
import { useDiscourseUrl } from "src/hooks/useDiscourseUrl";
import styles from "./DiscourseSSO.module.css";

export const DiscourseSSO = () => {
  const { isLoading, data: discourseUrl, error, isSuccess } = useDiscourseUrl();

  if (isSuccess && discourseUrl) {
    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = discourseUrl;

    return (
      <Spinner center>
        <span className={styles.message}>Launching Lens Forums...</span>
      </Spinner>
    );
  }

  if (isLoading) {
    return (
      <Spinner center>
        <span className={styles.message}>Loading...</span>
      </Spinner>
    );
  }

  return (
    <Spinner center>
      <span className={styles.errorMessage}>{error?.message ?? "Unknown error"}</span>
    </Spinner>
  );
};
