import { Input } from "@k8slens/lds";
import { ChangeEvent, useRef } from "react";
import { UploadAvatarResponse } from "lens-platform-sdk/dist/cjs/UserService";
import Button from "src/components/Button/TrackedButton";
import clsx from "clsx";
import styles from "./AvatarUpload.module.css";

export interface AvatarUploadProps {
  onAvatarUpload: (file: File) => Promise<UploadAvatarResponse | undefined>;
  loading: boolean;
  onAvatarSelected?: (file: File) => void;
  className?: string;
  onAvatarError?: (error: Error) => void;
}

const maxAvatarSize = 3 * 1000 * 1000; // 3MB

export const AvatarUpload = ({
  loading,
  onAvatarUpload,
  onAvatarSelected,
  className,
  onAvatarError,
}: AvatarUploadProps) => {
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const handleAvatarChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files;

    if (!file) {
      return;
    }

    if (file[0].size > maxAvatarSize) {
      onAvatarError && onAvatarError(new Error("Image size too big. File should not be bigger than 3mb"));

      return;
    }

    onAvatarSelected && onAvatarSelected(file[0]);

    await onAvatarUpload(file[0]);
  };

  const selectAvatar = () => {
    avatarInputRef && avatarInputRef.current?.click();
  };

  return (
    <form className={clsx(styles.avatarForm, className)}>
      <Input
        className={styles.userAvatarInput}
        type="file"
        ref={avatarInputRef}
        onChange={handleAvatarChange}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Button
        discreet
        loading={loading}
        label="Upload Image"
        id="upload-avatar"
        type="primary"
        onClick={selectAvatar}
        primary
      />
    </form>
  );
};
