import { Buffer } from "buffer";
import { useLocation } from "react-router-dom";
import { useUserAvatar } from "./useUserAvatar";
import type { LocationState } from "src/App";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { backend } from "src/constants";
import { useKeycloak } from "@react-keycloak/web";
import { getDiscourseUrl } from "src/services/discuss/discourse";

const missingQueryParamsError = "Search query parameters sso or sig is required";
const loadingUserAvatarError = "Loading user avatar";
const backendApiError = "Error launching Lens Forums, please try again";

export const useDiscourseUrl = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation<LocationState>();

  const { userAvatar, loadingUserAvatar } = useUserAvatar();

  const { isLoading, error, data, isSuccess } = useQuery<string, Error>({
    queryKey: ["discourse-url", location.search, backend, userAvatar, keycloak.token, loadingUserAvatar],
    queryFn: async () => {
      const queryParams = queryString.parse(location.search);

      if (loadingUserAvatar) {
        throw new Error(loadingUserAvatarError);
      }

      if (!queryParams.sso || !queryParams.sig) {
        throw new Error(missingQueryParamsError);
      }

      const discourseSSOServiceUrl = `${backend}/discourse-sso`;

      const requestInit = {
        method: "POST",
        body: JSON.stringify({
          sso: queryParams.sso,
          sig: queryParams.sig,
          avatar: userAvatar,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak.token}`,
        },
      };

      const response = await fetch(discourseSSOServiceUrl, requestInit);

      if (response.status < 200 || response.status > 299) {
        throw new Error(backendApiError);
      }

      const json = await response.json();

      return getDiscourseUrl(json.sso, json.sig, {
        search: Buffer.from(queryParams.sso as string, "base64").toString("utf8"),
      });
    },
    retry: (count, error) => {
      if (error.message === backendApiError && process.env.NODE_ENV !== "test") {
        return true;
      }

      return false;
    },
  });

  return { isLoading, error, data, isSuccess };
};
