import { usePurchaseQuantityKey } from "./usePurchaseQuantityKey";

/**
 * Get the purchase quantity using the purhcase quantity key
 */
export const usePurchaseQuantity = () => {
  const quantityString = usePurchaseQuantityKey();

  if (quantityString) {
    return parseInt(quantityString, 10);
  }

  return undefined;
};
