export const backend = process.env.REACT_APP_BACKEND_URL ?? "http://localhost";
export const recurly = process.env.REACT_APP_RECURLY_URL;
export const pricingUrl = process.env.REACT_PRICING_URL ?? "https://k8slens.dev/pricing";
export const zendeskDomain = process.env.REACT_APP_ZENDESK_DOMAIN;
export const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
export const lens5LoginExpireDate = "2023-01-02T00:00:00.000"; // Mon Jan 02 2023 in user timezone
export const isStaging = process.env.REACT_APP_BACKEND_URL?.includes("staging");
export const isStagingOrTest = process.env.NODE_ENV === "test" || isStaging;
export const isDevelopment = process.env.NODE_ENV === "development";
export const AIRGAPPED = "Airgapped";
export const OFFLINE_ACTIVATION = "Offline_Activation";
export const AWS_EKS = "AWS_EKS";
export const START_TIME = new Date().getTime();
export const SUPPORT_EMAIL = "support@k8slens.dev";
export const checkoutBaseUrl =
  process.env.REACT_APP_CHECKOUT_BASE_URL ||
  (isStaging ? "https://checkout.lc-staging1.staging-k8slens.cloud" : "https://checkout.k8slens.dev");
