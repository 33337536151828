import React from "react";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import { FooterProps } from "./common";

export const SignUpFooter: React.FC<FooterProps> = ({ signinPath }) => (
  <div>
    {"Already have an Lens ID? "}
    <TrackedLink to={signinPath}>Login</TrackedLink>
  </div>
);
