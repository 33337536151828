import { base } from "@k8slens/lds-icons";

import styles from "./KeycloakLoadingContent.module.css";

const { SpinnerIcon } = base;

export const KeycloakLoadingContent = ({ error }: { error?: string }) => (
  <div className={styles.keycloakLoading}>
    {!error && <SpinnerIcon size="xl" />}
    {error && <span>{error}</span>}
  </div>
);
