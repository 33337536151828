import { useCallback } from "react";

import { useAnalytics } from "src/hooks/useAnalytics";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import { FooterProps } from "./common";

import styles from "./SuccessFooter.module.css";

const logoutLabel = "Logout";

export const SuccessFooter: React.FC<FooterProps> = ({ signoutAction, homePath, profile }) => {
  const { trackButtonClicked } = useAnalytics();

  const handleLogout = useCallback(() => {
    trackButtonClicked(logoutLabel);
    signoutAction();
  }, [trackButtonClicked, signoutAction]);

  return (
    <div>
      <div className={styles.signedInFooter}>
        <span>
          Logged in as <strong>{profile?.username}</strong>
        </span>
        <TrackedLink to={homePath}>Lens ID Portal</TrackedLink>
        <button tabIndex={0} role="link" type="button" onClick={handleLogout}>
          {logoutLabel}
        </button>
      </div>
    </div>
  );
};
