import Checkbox from "src/components/Checkbox/Checkbox";

interface Props {
  marketingSubscribed: boolean;
  setMarketingSubscribed(value: boolean): void;
  className?: string;
}

const MarketingConsent = ({ marketingSubscribed, setMarketingSubscribed, className }: Props) => (
  <div className={className}>
    <Checkbox id="marketing" value={marketingSubscribed} onChange={setMarketingSubscribed} />
    <label htmlFor="marketing">
      Please subscribe me to product updates, news, and other marketing communications from Mirantis, Inc.
    </label>
  </div>
);

export default MarketingConsent;
