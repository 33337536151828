import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { LicensedToken } from "src/components/pages/Home/license";

export interface TokenData {
  refreshToken: string | null;
  idToken: string | null;
  accessToken: string | null;
  token: LicensedToken | null | undefined;
}

export interface LicenseTokenContext extends TokenData {
  updateToken: (minValidity?: number) => void;
}

export const TokenContext = React.createContext<LicenseTokenContext>({
  updateToken: () => {},
  token: undefined,
  idToken: null,
  accessToken: null,
  refreshToken: null,
});

interface Props {
  children?: React.ReactNode;
}

export const TokenProvider: React.FC<Props> = ({ children }) => {
  // Keep undefined until it has been defined (token has been parsed)
  // so we can track the status
  const [tokenData, setTokenData] = useState<TokenData | null | undefined>(undefined);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak?.token) {
      if (keycloak.idToken && keycloak.refreshToken) {
        setTokenData({
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
          token: keycloak.tokenParsed as unknown as LicensedToken,
        });
      }
    } else {
      setTokenData(null);
    }
  }, [keycloak?.token, keycloak?.tokenParsed, keycloak?.idToken, keycloak?.refreshToken]);

  const updateToken = useCallback(
    async (minValidity = 5000) => {
      const updated = await keycloak.updateToken(minValidity);

      if (keycloak?.token && updated) {
        setTokenData((tokenData) => ({
          ...(tokenData as TokenData),
          token: keycloak.tokenParsed as unknown as LicensedToken,
        }));
      } else if (updated) {
        setTokenData(null);
      }
    },
    [keycloak],
  );

  return (
    <TokenContext.Provider
      value={{
        updateToken,
        token: tokenData === undefined ? undefined : tokenData?.token || null,
        refreshToken: tokenData?.refreshToken || null,
        idToken: tokenData?.idToken || null,
        accessToken: tokenData?.accessToken || null,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
