import { useMemo, useState, useCallback } from "react";
import findIndex from "lodash/findIndex";
import clsx from "clsx";

import type { Tour } from "@k8slens/lds-tips/lib/es/Tips/Tips";

const storeKey = "tours";

type State = {
  skipAll: boolean;
  activeSteps: {
    [key: string]: number;
  };
};
type TourStore = { [key: string]: Tour & { index: number } };

const getState = (): State => {
  const defaultState: State = {
    activeSteps: {},
    skipAll: false,
  };

  try {
    const state = localStorage.getItem(storeKey);

    return state ? JSON.parse(state) : defaultState;
  } catch (error) {
    return defaultState;
  }
};

const setState = (state: State): void => {
  try {
    const json = JSON.stringify(state);

    localStorage.setItem(storeKey, json);
  } catch (error) {
    // do nothing
  }
};

export interface TipStore {
  tours: Tour[];
  skipAll: boolean;
  setSkipAll: () => void;
  skipStepIfActive: (stepId: string) => boolean;
  setNextStepNumber: (tourId: string) => number;
  getActiveStep: (tourId: string) => number;
  showStep: (stepId: string) => void;
  hideStep: (stepId: string) => void;
  setStepIsVisible: (stepId: string, visible: boolean) => void;
}

export const useTipStore = (initialTours: Array<Tour>): TipStore => {
  const intialState = getState();
  const [skipAll, setSkipAll] = useState<State["skipAll"]>(intialState.skipAll);
  const [activeSteps, setActiveSteps] = useState<State["activeSteps"]>(intialState.activeSteps);

  const updateState = useCallback((state: State) => {
    setActiveSteps(state.activeSteps);
    setSkipAll(state.skipAll);
    setState(state);
  }, []);

  const [toursObject, setTours] = useState<TourStore>(
    initialTours.reduce((d, tour, index) => ({ ...d, [tour.id]: { ...tour, index } }), {}),
  );

  const setNextStepNumber = useCallback(
    (id: string) => {
      const nextStep = id in activeSteps ? activeSteps[id] + 1 : 1;

      updateState({ skipAll, activeSteps: { ...activeSteps, [id]: nextStep } });

      return nextStep;
    },
    [skipAll, activeSteps, updateState],
  );

  const getActiveStep = useCallback(
    (id: string) => {
      const step = id in activeSteps ? activeSteps[id] : 0;

      return step;
    },
    [activeSteps],
  );

  const getTourForStepId = useCallback(
    (stepId: string) => initialTours.find(({ steps }) => steps.find(({ id }) => id === stepId)),
    [initialTours],
  );

  const skipStepIfActive = useCallback(
    (stepId: string) => {
      const tour = getTourForStepId(stepId);

      if (!tour) {
        return false;
      }
      const activeStepIndex = getActiveStep(tour.id);
      const activeStep = tour.steps[activeStepIndex];

      if (activeStep?.id === stepId) {
        setNextStepNumber(tour.id);

        return true;
      }

      return false;
    },
    [getTourForStepId, getActiveStep, setNextStepNumber],
  );

  const setStepIsVisible = useCallback(
    (stepId: string, visible: boolean) => {
      const { tourId, tourIndex, stepIndex } = initialTours.reduce(
        (d, tour, tourIndex) => {
          const stepIndex = findIndex(tour.steps, { id: stepId });

          if (stepIndex !== -1) {
            return { tourIndex, tourId: initialTours[tourIndex].id, stepIndex };
          }

          return d;
        },
        {
          tourId: undefined,
          tourIndex: -1,
          stepIndex: -1,
        } as {
          tourId: undefined | string;
          tourIndex: number;
          stepIndex: number;
        },
      );

      if (!tourId || tourIndex < 0) {
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.warn(`No step found with id ${stepId}`);
        }

        return;
      }

      setTours((current: TourStore) => {
        const pointerClassName = clsx(initialTours[tourIndex].steps[stepIndex].pointerClassName, {
          hidden: !visible,
        });

        if (current[tourId] && current[tourId].steps[stepIndex].pointerClassName !== pointerClassName) {
          return {
            ...current,
            [tourId]: {
              ...current[tourId],
              steps: current[tourId].steps.map((step, index) => {
                if (index === stepIndex) {
                  return {
                    ...step,
                    pointerClassName,
                  };
                }

                return step;
              }),
            },
          };
        }

        return current;
      });
    },
    [initialTours],
  );

  const showStep = useCallback(
    (stepId: string) => {
      setStepIsVisible(stepId, true);
    },
    [setStepIsVisible],
  );

  const hideStep = useCallback(
    (stepId: string) => {
      setStepIsVisible(stepId, false);
    },
    [setStepIsVisible],
  );

  return {
    tours: useMemo(
      () =>
        Object.values(toursObject).reduce((a, { index, ...tour }) => {
          a[index] = tour;

          return a;
        }, [] as Array<Tour>),
      [toursObject],
    ),
    skipAll,
    setSkipAll: useCallback(() => {
      updateState({ activeSteps, skipAll: true });
    }, [updateState, activeSteps]),
    skipStepIfActive,
    setNextStepNumber,
    getActiveStep,
    showStep,
    hideStep,
    setStepIsVisible,
  };
};
