import { useIsDesktop } from "src/hooks/useIsDesktop";

import MainNavigationLink from "src/components/MainNavigation/MainNavigationItem";

const LensLauncherButton = () => {
  const isDesktop = useIsDesktop();

  if (!isDesktop) {
    return <></>;
  }

  return <MainNavigationLink title="Lens Desktop" to="/lens-launcher" openInNewTab />;
};

// Fake SideNavItem to suppress warnings for now
LensLauncherButton.displayName = "SideNavItem";

export default LensLauncherButton;
