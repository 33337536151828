import { Notification } from "@k8slens/lds";

import styles from "./Complete.module.css";

type Props = {
  errorMessage?: string;
  signingUpFromGithub: boolean;
  signingUpFromGoogle: boolean;
};

const Complete = ({ signingUpFromGithub, signingUpFromGoogle, errorMessage }: Props) => (
  <div className={styles.congratulateBlock}>
    {errorMessage && <Notification className={styles.error} level="error" type="flash" message={errorMessage} />}
    <img aria-hidden alt="Email verification sent" src="/static/media/email-verification-temp.png" />
    {!signingUpFromGithub && !signingUpFromGoogle && (
      <p>We have sent a confirmation email to the address you have provided. Please check your email!</p>
    )}
  </div>
);

export default Complete;
