import clsx from "clsx";
import { useContext } from "react";
import { ProfileContext } from "src/providers/profile-provider";
import { useBillingPageToken } from "src/hooks/useBillingPageToken";
import { useGetInvoices } from "src/hooks/useGetInvoices";
import { useGetSubscriptions } from "src/hooks/useGetSubscriptions";
import { useGetBillingInformation } from "src/hooks/useGetBillingInfo";
import { useBillingPageUrl } from "src/hooks/useBillingPageUrl";
import styles from "./BillingDetails.module.css";
import { Billing } from "./Billing";
import { Invoices } from "./Invoices";
import { BillingSubscriptions } from "./BillingSubscriptions";

export const UserBillingDetails = () => {
  const { profile } = useContext(ProfileContext);
  const { invoices, loading } = useGetInvoices(profile?.username);
  const { subscriptions, loading: loadingSubscriptions } = useGetSubscriptions(profile?.username);
  const { billingInfoUrl } = useBillingPageUrl(profile?.username);
  const { billingInfo } = useGetBillingInformation();
  const { token } = useBillingPageToken(profile?.username);

  return (
    <section>
      <div className={styles.pageDescription}>
        <h2 className={clsx("lds-h2-caps", styles.welcome)}>Billing details</h2>
        <p>Manage your billing, subscriptions and payment methods here.</p>
      </div>
      <section className={styles.tabContent}>
        <h3 className="lds-h3">Payment method</h3>
        <p>Manage payment method for your Lens Subscriptions.</p>
        <Billing billingInfo={billingInfo} billingInfoUrl={billingInfoUrl} />
      </section>
      <section className={styles.tabContent}>
        <h3 className="lds-h3">Subscriptions</h3>
        <p>Manage your Lens subscriptions.</p>
        <BillingSubscriptions subscriptions={subscriptions} billingPageToken={token} loading={loadingSubscriptions} />
      </section>
      <section className={styles.tabContent}>
        <h3 className="lds-h3">Billing history</h3>
        <p>Review you billing history and manage your invoices.</p>
        <Invoices billingPageToken={token} invoices={invoices} loading={loading} />
      </section>
    </section>
  );
};
