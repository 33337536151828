import { useQuery } from "./useQuery";

/**
 * Check if the there is an search query fromEmailVerificationPage in the url indicating that
 * it was redirected from Keycloak's email verification page
 *
 * @remarks
 * You need to ensure that the query parameter name in keycloak/template/log/template.ftl
 * is the same as in used here.
 */
export const useFromEmailVerificationPage = () => {
  const query = useQuery();
  const fromEmailVerificationPage = query.get("fromEmailVerificationPage");

  return Boolean(fromEmailVerificationPage);
};
