import styles from "./WarningSide.module.css";

export function WarningSide() {
  return (
    <div className={styles.warningSide}>
      <header className={styles.header}>
        <h1 className={styles.title}>
          This version of Lens Desktop is
          <br className={styles.titleBreak} /> No Longer Maintained!
        </h1>
      </header>
      <div className={styles.content} data-testid="upgrade-warning-description">
        <div className={styles.descriptionWithRobot}>
          <p>
            The version of Lens Desktop you are currently using is no longer maintained and is subject to security
            vulnerabilities.{" "}
            {/* <strong>
              <a href="https://k8slens.dev/" rel="noreferrer" target="_blank">
                Learn more »
              </a>
            </strong> */}
          </p>
          <p>
            On <strong>January 2nd, 2023</strong> connections to compatible backend services will stop and Lens Desktop
            may not function properly unless upgraded.
          </p>
        </div>
      </div>
    </div>
  );
}
