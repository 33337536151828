import { Buffer } from "buffer";

/**
 * Remove falsy values from object AND encode object into a based64 string.
 * Return empty string if object has no truthy values.
 */
export const encodeBase64 = (obj: Object) => {
  // Remove falsy values from `activation`
  const reduced = Object.entries(obj).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      ...(value ? { [key]: value } : {}),
    }),
    {},
  );

  // If there are no truthy values left, no need to encode, return empty string
  if (Object.keys(reduced).length === 0) {
    return "";
  }
  const base64 = Buffer.from(JSON.stringify(reduced), "utf8").toString("base64");

  return base64;
};

/**
 * Decode the based-encoded activation key back to object.
 */
export const decodeBase64 = (key: string): object => {
  const json = Buffer.from(key, "base64").toString("utf8");

  return JSON.parse(json);
};
