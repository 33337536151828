import { useActivationKey } from "./useActivationKey";
import { useBusinessInvitationKey } from "./useBusinessInvitationKey";
import { useInvitationKey } from "./useInvitationKey";
import { usePurchaseQuantityKey } from "./usePurchaseQuantityKey";
import { useBusinessChildInvitationToken } from "./useBusinessChildInvitationToken";

export type PassThroughKeys = {
  invitationKey: ReturnType<typeof useInvitationKey>;
  activationKey: ReturnType<typeof useActivationKey>;
  purchaseQuantity: ReturnType<typeof usePurchaseQuantityKey>;
  businessIdInvitationKey: ReturnType<typeof useBusinessInvitationKey>;
  businessChildInvitationToken: ReturnType<typeof useBusinessChildInvitationToken>;
};

export const usePassThroughKeys = () => {
  const invitationKey = useInvitationKey();
  const activationKey = useActivationKey();
  const purchaseQuantity = usePurchaseQuantityKey();
  const businessIdInvitationKey = useBusinessInvitationKey();
  const businessChildInvitationToken = useBusinessChildInvitationToken();

  return {
    invitationKey,
    activationKey,
    purchaseQuantity,
    businessIdInvitationKey,
    businessChildInvitationToken,
  };
};
