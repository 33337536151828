import Invitation from "src/components/Invitation/Invitation";
import { AcceptBusinessChildrenInvitation } from "src/components/pages/AcceptBusinessChildrenInvitation/AcceptBusinessChildrenInvitation";
import { AcceptBusinessInvitation } from "src/components/pages/AcceptBusinessInvitation/AcceptBusinessInvitation";
import Activate from "src/components/pages/Activate/Activate";
import Home from "src/components/pages/Home/Home";
import { usePassThroughKeys } from "src/hooks/usePassThroughKeys";

export const HomePage = () => {
  const { invitationKey, activationKey, businessIdInvitationKey, businessChildInvitationToken } = usePassThroughKeys();

  return (
    <>
      {businessIdInvitationKey && <AcceptBusinessInvitation />}
      {businessChildInvitationToken && <AcceptBusinessChildrenInvitation />}
      {activationKey && <Activate />}
      {!activationKey && !businessIdInvitationKey && !businessChildInvitationToken && invitationKey && <Invitation />}
      {!activationKey && !businessIdInvitationKey && !businessChildInvitationToken && !invitationKey && <Home />}
    </>
  );
};
