import { decodeBusinessInvitationKey } from "src/services/businessIdInvitationKey";
import { useBusinessInvitationKey } from "./useBusinessInvitationKey";

/**
 * Get `BusinessInvitation` by decoding the invitation key
 */
export const useBusinessInvitation = () => {
  const invitationKey = useBusinessInvitationKey();
  let invitation;
  let error;

  if (invitationKey) {
    try {
      invitation = decodeBusinessInvitationKey(invitationKey);
    } catch (err) {
      invitation = undefined;
      error = err;
    }
  }

  return { invitation, error };
};
