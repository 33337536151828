import { LinkProps } from "react-router-dom";
import clsx from "clsx";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import styles from "./StepBar.module.css";

export type Step<T> = { id: T; label: string; url?: LinkProps<object>["to"] };

export interface Props<T extends string> {
  currentStep?: Step<T>;
  steps: Array<Step<T>>;
  label?: string;
  className?: string;
  /** Default true */
  allowNavigation?: boolean;
}

export default function StepBar<T extends string>({
  label = "Progress",
  steps = [],
  currentStep,
  className,
  allowNavigation = true,
}: Props<T>) {
  return (
    <nav aria-label={label} className={clsx(styles.stepBar, className)}>
      <ol>
        {steps.map((step) => {
          const isPastStep = currentStep && steps.indexOf(step) < steps.indexOf(currentStep);
          const asLink = isPastStep && step.url;

          return (
            <li
              className={clsx({
                [styles.active]: currentStep && step.id === currentStep.id,
              })}
              key={step.id}
            >
              {allowNavigation && asLink && <TrackedLink to={step.url!}>{step.label}</TrackedLink>}
              {!(allowNavigation && asLink) && <span aria-current="step">{step.label}</span>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
