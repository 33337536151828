import formStyles from "src/components/styles/form.module.css";
import Page from "src/components/Page/Page";
import styles from "./KeycloakLoading.module.css";

export const KeycloakTimeout = () => (
  <Page>
    <div className={formStyles.bg}>
      <div className={formStyles.size}>
        <div className={styles.container}>
          <h1 className={formStyles.title}>Lens ID</h1>
          <h2 className={formStyles.subtitle}>
            <span className={styles.error}>"Oops! Something went wrong. Please try again in a few minutes!"</span>
          </h2>
        </div>
      </div>
    </div>
  </Page>
);
