import clsx from "clsx";
import { Panel, ButtonBar } from "@k8slens/lds";
import { misc } from "@k8slens/lds-icons";

import { useAnalytics } from "src/hooks/useAnalytics";

import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import TrackedLinkButton from "src/components/Button/TrackedLinkButton";

import Aside, { panelProps, AsideProps } from "../Aside";

import styles from "./ChoosePlanAside.module.css";

const { AwesomeIcon } = misc;

const managerEmailSubject = "Request for purchasing Lens Pro subscription";
const managerEmailBody = `\
Hi!

I've been using Lens Desktop software (https://k8slens.dev) for a while. It makes working with Kubernetes a lot easier. Can we purchase Lens Pro subscription?

Here's the link for pricing and purchasing: https://app.k8slens.dev/subscribe

Thanks!`;
const managerEmail = `mailto:?subject=${encodeURIComponent(managerEmailSubject)}&body=${encodeURIComponent(
  managerEmailBody,
)}`;

export interface ChoosePlanAsideProps {
  highlightManagerEmail?: boolean;
  panelClassName?: AsideProps["panelClassName"];
}

const ChoosePlanAside = ({ panelClassName, highlightManagerEmail }: ChoosePlanAsideProps) => {
  const { track } = useAnalytics();

  return (
    <Aside
      openedLabel="Show less"
      panelClassName={clsx(styles.choosePlanAsidePanel, panelClassName)}
      closedLabel={<>Click here to read more about other purchasing options.</>}
      onToggle={(open) => track("Toggle Read more", { open })}
    >
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...panelProps}
        contentProps={{
          ...(panelProps.contentProps || {}),
          className: clsx(styles.managerEmailContent, panelProps.contentProps?.className),
        }}
        header={<h5>Want your manager to purchase a Lens Subscription for you?</h5>}
      >
        <ButtonBar type="stacked">
          <TrackedLinkButton
            className={styles.managerEmailButton}
            to={{ pathname: managerEmail }}
            label="Send them an email"
            icon={AwesomeIcon}
            discreet={!highlightManagerEmail}
            target="_blank"
            textTransform="uppercase"
            primary
          />
        </ButtonBar>
      </Panel>
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...panelProps}
        header={<h5>Need large quantities, purchase via reseller or paper invoicing?</h5>}
      >
        <p>
          If you need large quantities, purchase via reseller or paper invoicing, please{" "}
          <TrackedAnchor
            className="primary"
            href="https://k8slens.dev/purchase-request"
            target="_blank"
            rel="noreferrer"
          >
            contact sales
          </TrackedAnchor>
          .
        </p>
      </Panel>
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...panelProps}
        header={<h5>How to redeem a coupon code?</h5>}
      >
        <p>
          {/* eslint-disable-next-line max-len */}
          Coupon codes are redeemed at the final step of the checkout. Select your subscription and payment plan to
          proceed to checkout.
        </p>
      </Panel>
    </Aside>
  );
};

export default ChoosePlanAside;
