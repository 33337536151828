import React from "react";

import { misc } from "@k8slens/lds-icons";

const { AwesomeIcon } = misc;

interface TipContentProps {
  title: string | React.ReactNode;
  content: string | Array<string | React.ReactNode>;
}
export const TipContent: React.FC<TipContentProps> = ({ title, content }) => (
  <>
    <h3>
      <span className="flex gap-md items-center">
        <AwesomeIcon size="3xl" color="on-dark" className="shrink-0" />
        {title}
      </span>
    </h3>
    {
      // eslint-disable-next-line react/no-array-index-key
      (typeof content === "string" ? [content] : content).map((item, index) => (
        <p key={index}>{item}</p>
      ))
    }
  </>
);
