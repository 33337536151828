import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

import { backend } from "src/constants";
import { useInvitation } from "src/hooks/useInvitation";
import { useQuery } from "src/hooks/useQuery";

import { PassThroughKeys } from "src/hooks/usePassThroughKeys";
import { addPassThroughKeys } from "src/services/passThroughKey";
import type { CredentialsType } from "./Credentials";
import type { ProfileType } from "./Profile";
import SinglePageForm from "./SinglePageForm";
import { FormState, SignupState, FormComponentProps } from "./form";
import { useOauthDanceQueries } from "src/hooks/useOauthDanceQueries";

const getValues = (object: CredentialsType | ProfileType) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value?.value,
    }),
    {},
  );

export const githubParam = "signingUpFromGithub";
export const googleParam = "signingUpFromGoogle";

export interface Props {
  signinPath: string;
  githubRedirectPath: string;
  googleRedirectPath: string;
  isOAuthSignUp?: boolean;
  disabled?: boolean;
  passThroughKeys?: PassThroughKeys;
  wrapperClassName?: string;
  form?: React.FC<FormComponentProps>;
  getSubmitButtonLabel?: FormComponentProps["getSubmitButtonLabel"];
  onFormStateChange?(formState: FormState): void;
  onSignupStateChange?(signupState: SignupState): void;
  redirectPath?: string;
  initialFormState?: FormState;
}

const SignUp: React.FC<Props> = ({
  signinPath,
  githubRedirectPath,
  googleRedirectPath,
  isOAuthSignUp,
  passThroughKeys = {},
  wrapperClassName,
  disabled,
  form: Form = SinglePageForm,
  getSubmitButtonLabel,
  onFormStateChange,
  onSignupStateChange,
  redirectPath,
  initialFormState = "credentials",
}) => {
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak();

  const oauthDanceQueries = useOauthDanceQueries();
  // the destination for the oauth dance when singing up from Github/Google is completed,
  // and user clicks on "Create Lens ID" > redirect to this url.
  const oauthDanceDestination =
    window.origin +
    addPassThroughKeys("/signin/realms/lensCloud/protocol/openid-connect/auth", passThroughKeys) +
    oauthDanceQueries;

  const query = useQuery();
  const redirectUriQuery = query.get("redirect_uri");
  const stateQuery = query.get("state");
  const ssoQuery = query.get("sso");
  const sigQuery = query.get("sig");
  const search = useMemo(() => {
    let value = "";

    if (redirectUriQuery) {
      if (isOAuthSignUp) {
        // redirect to sign in page after sign up. User should be already logged in,
        // so redirect to original redirect uri should happen
        const signInRedirectUri =
          window.origin + history.location.pathname.replace(/signup/, "signin") + history.location.search;

        value = `${value}?redirect_uri=${encodeURIComponent(encodeURIComponent(signInRedirectUri))}`;
      } else {
        value = `${value}?redirect_uri=${encodeURIComponent(redirectUriQuery)}`;
      }
    }

    if (stateQuery) {
      value = `${value}&state=${encodeURIComponent(stateQuery)}`;
    }

    if (ssoQuery) {
      value = `${value}&sso=${encodeURIComponent(ssoQuery)}`;
    }

    if (sigQuery) {
      value = `${value}&sig=${encodeURIComponent(sigQuery)}`;
    }

    return value;
  }, [redirectUriQuery, stateQuery, ssoQuery, sigQuery, isOAuthSignUp]);
  let initialSignupState: SignupState | undefined;

  if (query.get(githubParam)) {
    initialSignupState = "return-from-github";
  } else if (query.get(googleParam)) {
    initialSignupState = "return-from-google";
  }
  const [signupState, updateSignupState] = useState<SignupState>(initialSignupState || "initial");
  const setSignupState = useCallback(
    (state: SignupState) => {
      updateSignupState(state);

      // Publish form state changes
      if (typeof onSignupStateChange === "function") {
        onSignupStateChange(state);
      }
    },
    [onSignupStateChange],
  );

  const [resendingEmail, setIsResendingEmail] = useState(false);
  // the `as` is to overide type tokenParsed which doesn't have email/preferred_username/name fields
  const keyCloakTokenParsed = keycloak?.tokenParsed as {
    email?: string;
    preferred_username?: string;
    name?: string;
    family_name?: string;
    given_name?: string;
  };
  const keycloakTokenEmail = keyCloakTokenParsed?.email ?? "";
  const keycloakTokenPerferredUsername = keyCloakTokenParsed?.preferred_username ?? "";
  const keycloakTokenName = keyCloakTokenParsed?.name ?? "";

  const keycloakTokenFirstName = keyCloakTokenParsed?.given_name ?? "";
  const keycloakTokenLastName = keyCloakTokenParsed?.family_name ?? "";

  const [formState, updateFormState] = useState<FormState>(initialFormState);
  const setFormState = useCallback(
    (state: FormState) => {
      updateFormState(state);

      // Publish form state changes
      if (typeof onFormStateChange === "function") {
        onFormStateChange(state);
      }
    },
    [onFormStateChange],
  );

  const { invitation, error: invitationError } = useInvitation();
  const [credentials, setCredentials] = useState<CredentialsType>({
    username: { value: "" },
    password: { value: "" },
    firstName: { value: "" },
    lastName: { value: "" },
    email: invitation?.invitedEmail
      ? {
          value: invitation?.invitedEmail,
          valid: true,
        }
      : {
          value: "",
        },
  });
  const [profile, setProfile] = useState<ProfileType>({
    company: { value: "" },
  });
  const [marketing, setMarketing] = useState<boolean>(false);

  const [fetchErrorMessage, setFetchErrorMessage] = useState<string | undefined>();
  const errorMessage = fetchErrorMessage || invitationError?.message || "";
  const handleFetchException = useCallback((exception: unknown) => {
    if (exception instanceof Error) {
      switch (exception.constructor) {
        // thrown by fetch() if network request failed
        case TypeError:
          setFetchErrorMessage(exception.message);
          break;
        // thrown by response.json()
        case SyntaxError:
          setFetchErrorMessage("Unexpected response format");
          break;
        default:
          setFetchErrorMessage(exception.message);
      }
    } else {
      // unlikely will reach here
      throw exception;
    }
  }, []);
  const fetchWithErrorHandling = useCallback(
    async (requestInfo: RequestInfo, requestInit?: RequestInit | undefined) => {
      try {
        setFetchErrorMessage(undefined);
        const response = await fetch(requestInfo, requestInit);

        if (!response.ok) {
          const json = await response.json();

          setFetchErrorMessage(json?.message);

          return response;
        }

        return response;
      } catch (exception) {
        handleFetchException(exception);
      }

      return undefined;
    },
    [handleFetchException],
  );

  const signUp = useCallback(async () => {
    const requestBody = {
      ...getValues(credentials),
      attributes: { ...getValues(profile) },
    };

    if (signupState === "return-from-github") {
      const patchUserResponse = await fetchWithErrorHandling(`${backend}/users/${credentials.username.value}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak.token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (patchUserResponse?.ok) {
        const oauthDanceDestination = localStorage.getItem("oauthDanceDestination");

        if (oauthDanceDestination) {
          localStorage.removeItem("oauthDanceDestination");
          // redirect to the oauth dance destination if present
          // eslint-disable-next-line xss/no-location-href-assign
          window.location.href = oauthDanceDestination;
        }
        history.push(signinPath);
      }
    } else if (signupState === "return-from-google") {
      keycloak.updateToken(-1);
      const oauthDanceDestination = localStorage.getItem("oauthDanceDestination");

      if (oauthDanceDestination) {
        localStorage.removeItem("oauthDanceDestination");
        // redirect to the oauth dance destination if present
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = oauthDanceDestination;
      }
      history.push(signinPath);
    } else {
      let redirectPathQuery = redirectPath ? `?redirectPath=${redirectPath.replace(/^\//, "")}` : "";

      // In addition to redirectPath, we also need to pass the search query redirect_uri together to the backend
      // When the user clicks verification, they will get redirected to the redirectPath with the redirect_uri query
      if (redirectUriQuery) {
        redirectPathQuery = `${redirectPathQuery}${search}`;
      }
      let url = `${backend}/users${redirectPathQuery}`;
      const hasPassThroughKeys = Object.values(passThroughKeys).some((value) => (value as string).length > 0);

      if (hasPassThroughKeys) {
        url = `${backend}${addPassThroughKeys("/users", passThroughKeys)}`;
      }

      const createUserBody = {
        ...requestBody,
        marketing,
      };

      const response = await fetchWithErrorHandling(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createUserBody),
      });

      if (response?.ok) {
        // @ts-expect-error missing type definitions, but window.PasswordCredential is valid in Chrome
        // Why not just check navigator.credentials https://web.dev/security-credential-management/#check-credential-management-api-browser-support
        if (window.PasswordCredential) {
          try {
            const credential = await window.navigator.credentials?.create?.({
              // @ts-expect-error missing type definitions, but `password` is valid in Chrome
              // https://developer.mozilla.org/en-US/docs/Web/API/CredentialsContainer/create
              password: {
                password: credentials.password.value,
                id: credentials.username.value,
              },
            });

            if (credential) {
              await window.navigator.credentials?.store?.(credential);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error);
          }
        }
        // Push to history to make sure the browser's password manager is updated
        history.push(history.location);
        setFormState("complete");
      }
    }
  }, [
    signinPath,
    history,
    credentials,
    passThroughKeys,
    keycloak,
    profile,
    signupState,
    marketing,
    fetchWithErrorHandling,
    setFormState,
    redirectPath,
    redirectUriQuery,
    search,
  ]);

  const handleSubmit = async (): Promise<void> => {
    const currentState = signupState;

    setSignupState("signing-up");

    try {
      await signUp();
    } finally {
      setSignupState(currentState);
    }
  };
  const handleLogin = async (): Promise<void> => {
    history.push(signinPath);
  };
  const handleResendEmailVerification = async (): Promise<void> => {
    setIsResendingEmail(true);

    await fetchWithErrorHandling(
      addPassThroughKeys(
        `${backend}/users/${encodeURIComponent(credentials.email.value)}/email/confirmation`,
        passThroughKeys,
      ),
      { method: "PUT" },
    );

    setIsResendingEmail(false);
  };
  const signUpWithGithub = async (): Promise<void> => {
    if (oauthDanceQueries.length > 0) {
      localStorage.setItem("oauthDanceDestination", oauthDanceDestination);
    }
    setSignupState("signing-up-with-github");
    /*
     * keycloak.login() might seems strange here
     * but it will trigger Github's authorization page
     * and redirect back to /signup?signingUpFromGithub=true
     *
     * !! Note that the user is already signed in when visiting
     * !! /signup?signingUpFromGithub=true
     *
     * The complete process in sequence diagram.
     * https://tinyurl.com/3pa469ac
     *
     */
    keycloak?.login({
      idpHint: "github",
      redirectUri: githubRedirectPath,
    });
  };
  const signUpWithGoogle = async (): Promise<void> => {
    if (oauthDanceQueries.length > 0) {
      localStorage.setItem("oauthDanceDestination", oauthDanceDestination);
    }
    setSignupState("signing-up-with-google");
    /*
     * keycloak.login() might seems strange here
     * but it will trigger Google's authorization page
     * and redirect back to /signup?signingUpFromGoogle=true
     *
     * !! Note that the user is already signed in when visiting
     * !! /signup?signingUpFromGoogle=true
     *
     * The complete process in sequence diagram.
     * https://tinyurl.com/3pa469ac
     *
     */
    keycloak?.login({
      idpHint: "google",
      redirectUri: googleRedirectPath,
    });
  };

  useEffect(() => {
    if (signupState === "return-from-github") {
      setCredentials({
        password: { value: "" },
        firstName: { value: keycloakTokenFirstName || keycloakTokenName, valid: true },
        lastName: { value: keycloakTokenLastName, valid: true },
        // set { valid: true } is hack to set the ValidityState directly
        // bypassing native validation on HTML
        email: { value: keycloakTokenEmail, valid: true },
        username: { value: keycloakTokenPerferredUsername, valid: true },
      });
    }

    if (signupState === "return-from-google") {
      setCredentials({
        password: { value: "" },
        firstName: { value: keycloakTokenFirstName || keycloakTokenName, valid: true },
        lastName: { value: keycloakTokenLastName, valid: true },
        email: { value: keycloakTokenEmail, valid: true },
        username: { value: keycloakTokenEmail.replace(/@.*$/, ""), valid: true },
      });
    }

    // the state when using sign up with github or google but declined the TOC
    // redirect theme back to the root page
    if (
      (signupState === "return-from-google" || signupState === "return-from-github") &&
      !keycloak.tokenParsed &&
      initialized === true
    ) {
      history.push("/");
    }
  }, [
    keycloak,
    history,
    initialized,
    keycloakTokenEmail,
    keycloakTokenPerferredUsername,
    keycloakTokenName,
    keycloakTokenFirstName,
    keycloakTokenLastName,
    signupState,
  ]);

  return (
    <>
      {/* Preload email verification image */}
      {/* TODO: Use SVG for email verification image */}
      <link rel="preload" as="image" href="/static/media/email-verification-temp.png" />
      <Form
        disabled={disabled}
        wrapperClassName={wrapperClassName}
        formState={formState}
        setFormState={setFormState}
        credentials={credentials}
        setCredentials={setCredentials}
        profile={profile}
        setProfile={setProfile}
        marketing={marketing}
        setMarketing={setMarketing}
        onSubmit={handleSubmit}
        onResendEmailVerification={handleResendEmailVerification}
        onLogin={handleLogin}
        signupState={signupState}
        signUpWithGithub={signUpWithGithub}
        signUpWithGoogle={signUpWithGoogle}
        errorMessage={errorMessage}
        resendingEmail={resendingEmail}
        getSubmitButtonLabel={getSubmitButtonLabel}
      />
    </>
  );
};

export default SignUp;
