import { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";

import { useGetBusinessInvoices } from "src/hooks/useGetBusinessInvoices";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useBusinessBillingPageToken } from "src/hooks/useBillingPageToken";
import { useGetBusinessBillingInformation } from "src/hooks/useGetBusinessBillingInfo";
import { useBusinessBillingPageUrl } from "src/hooks/useBillingPageUrl";
import { useBusinessChildren } from "src/hooks/useBusinessChildren";

import { BusinessContext } from "src/components/Business/Base";
import { AutoJoinSwitch } from "src/components/Business/AutoJoinSwitch";
import { Invoices } from "./Invoices";
import { Billing } from "./Billing";

import styles from "./BillingDetails.module.css";
import { Button, ButtonBar } from "@k8slens/lds";
import { useConfirmDialog } from "src/components/ConfirmDialog/ConfirmDialog";
import { PlanCode, isLensProPlan } from "src/services/plans";
import { openUrl } from "src/utils/url";
import { checkoutBaseUrl } from "src/constants";
import { BillingSubscriptions } from "./BillingSubscriptions";

export const BusinessBillingDetails = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { invoices, loading } = useGetBusinessInvoices(businessId);
  const { businessSubscriptions } = useGetBusinessSubscriptions(businessId, true);
  const lensProSubscriptions = useMemo(
    () => businessSubscriptions.filter(({ planCode, state }) => isLensProPlan(planCode) && state !== "expired"),
    [businessSubscriptions],
  );
  const { businessChildren } = useBusinessChildren(businessId);
  const { token } = useBusinessBillingPageToken(businessId);
  const { billingInfo } = useGetBusinessBillingInformation(businessId);
  const { billingInfoUrl } = useBusinessBillingPageUrl(businessId);
  const showNewSubscriptionButton = Boolean(business?.reseller);
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const handleNewSubscriptions = useCallback(async () => {
    const planCode: PlanCode = "pro-yearly";

    if (lensProSubscriptions.length > 0) {
      const confirmed = await confirm(
        "Create New Subscription?",
        <p>
          Your Lens Business ID already has a Lens Pro subscription associated with it.
          {/* eslint-disable-next-line max-len */}
          If you wish to purchase more seats you may{" "}
          <strong>modify the existing subscription to increase the number of seats</strong>. You can read more about
          adding and changing Lens subscriptions{" "}
          <a
            className="primary"
            target="_blank"
            href="https://docs.k8slens.dev/lens-id/lens-business-id/subscriptions/add-subscriptions/"
            rel="noreferrer"
          >
            here
          </a>
          .<p></p>
          Are you sure you want to create new subscription?
        </p>,
        { confirmText: "Create" },
      );

      if (!confirmed) {
        return;
      }
    }

    openUrl(`${checkoutBaseUrl}/subscribe/${planCode}/${businessId}`, true);
  }, [businessId, confirm, lensProSubscriptions.length]);

  return (
    <section>
      <div className={styles.pageDescription}>
        <h2 className={clsx("lds-h2-caps", styles.welcome)}>Billing details</h2>
        <p>
          Manage your Lens Business ID <strong>{`(${business?.handle || "-"})`}</strong> billing, subscriptions and
          payment methods here.
        </p>
      </div>
      <section className={styles.tabContent}>
        <h3 className="lds-h3">Payment method</h3>
        <p>Manage payment method for your Lens Subscriptions.</p>
        <Billing billingInfo={billingInfo} billingInfoUrl={billingInfoUrl} />
      </section>
      <section className={styles.tabContent}>
        <h3 className="lds-h3">Subscriptions</h3>
        <p>Manage your Lens subscriptions.</p>
        <BillingSubscriptions
          businessChildren={businessChildren}
          subscriptions={businessSubscriptions}
          billingPageToken={token}
          loading={loading}
        />
        <ButtonBar type="grid" className={styles.buttonBar}>
          {ConfirmDialog}
          {showNewSubscriptionButton ? (
            <Button label={"Create New Subscription"} primary discreet onClick={() => handleNewSubscriptions()} />
          ) : null}
        </ButtonBar>
      </section>
      <section className={styles.tabContent}>
        <AutoJoinSwitch />
      </section>
      {business?.external ? null : (
        <section className={styles.tabContent}>
          <h3 className="lds-h3">Billing history</h3>
          <p>Review you billing history and manage your invoices.</p>
          <Invoices
            businessChildren={businessChildren}
            billingPageToken={token}
            invoices={invoices}
            loading={loading}
          />
        </section>
      )}
    </section>
  );
};
