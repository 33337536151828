import { GetSubmitButtonLabel } from "./form";

const getSubmitButtonLabel: GetSubmitButtonLabel = ({ formState, signupState }) => {
  switch (formState) {
    case "complete":
      if (signupState === "return-from-github" || signupState === "return-from-google") {
        return "Log In";
      }

      return "Resend Email";
    default:
      return "Create Lens ID";
  }
};

export default getSubmitButtonLabel;
