import clsx from "clsx";
import { Panel, ButtonBar } from "@k8slens/lds";
import { SubscriptionChangePreview } from "lens-platform-sdk";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getPriceData, PlanCode, PlanMetadata } from "src/services/plans";
import { formatCurrency } from "src/utils/currency";

import Button from "src/components/Button/TrackedButton";

// TODO: Move shared components to shared folder
import Aside, { panelProps } from "src/components/pages/Subscribe/Aside";

import styles from "./ChangeSeatsOrderSummary.module.css";

export interface OrderSummaryProps {
  periodStartedAt: string;
  periodEndsAt: string;
  orderSummary?: SubscriptionChangePreview;
  loading?: boolean;
  planCode: PlanCode;
  planData: PlanMetadata;
  quantity: number;
  oldQuantity: number;
  updateDisabled?: boolean;
  onUpdate?(): Promise<void>;
}

const ChangeSeatsOrderSummary: React.FC<OrderSummaryProps> = ({
  periodStartedAt,
  periodEndsAt,
  orderSummary,
  loading,
  planCode,
  planData,
  quantity: seatCount,
  oldQuantity: oldSeatCount,
  updateDisabled,
  onUpdate,
}) => {
  const { track } = useAnalytics();
  const priceData = getPriceData(planCode);

  // Helpers for calculating price
  const seatCountPlural = (orderSummary?.quantity as number) - oldSeatCount > 1;
  const periodStart = new Date(periodStartedAt);
  const periodEnd = new Date(periodEndsAt);

  if (!orderSummary) {
    return null;
  }

  return (
    <Aside
      className={styles.aside}
      toggleClassName={styles.asideToggle}
      openedLabel="Show less"
      closedLabel={
        <span className={styles.closedLabel}>
          <strong>
            Total: {formatCurrency(orderSummary.balance)} {priceData.perText}
          </strong>
          <span>Show more</span>
        </span>
      }
      onToggle={(open) => track("Toggle Order Summary", { open })}
    >
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...panelProps}
        className={clsx(panelProps.className, styles.shoppingCart)}
        header={<h5>Order Summary</h5>}
        footerComponent={ButtonBar}
        footerProps={{
          as: "footer",
          type: "stacked",
        }}
        footer={
          onUpdate && (
            <Button
              loading={loading}
              label="Update subscription"
              primary
              textTransform="uppercase"
              loadingStateOnPromise
              disabled={updateDisabled}
              onClick={onUpdate}
            />
          )
        }
      >
        <>
          <div role="heading" aria-level={6} aria-flowto="seats total tax">
            {priceData.title}
          </div>
          <p aria-live="polite" aria-atomic="true">
            <span
              id="seats"
              className={styles.priceBreakdown}
              aria-label={`${orderSummary.quantity! - oldSeatCount} ${
                seatCountPlural ? planData.quantity.plural : planData.quantity.singular
              } at ${formatCurrency(orderSummary.unitAmount)} ${seatCountPlural ? "each " : ""}`}
            >
              <span aria-hidden>
                {orderSummary.quantity! - oldSeatCount}{" "}
                {seatCountPlural ? planData.quantity.plural : planData.quantity.singular}{" "}
              </span>
            </span>
            <span id="period" className={styles.priceBreakdown}>
              <span aria-hidden>
                {periodStart.toLocaleDateString()} - {periodEnd.toLocaleDateString()}
              </span>
              <span aria-hidden>{formatCurrency(orderSummary.subtotal)}</span>
            </span>
            <span
              id="tax"
              className={styles.priceBreakdown}
              aria-label={`including vat / sales tax of ${formatCurrency(orderSummary.tax)} `}
            >
              <span aria-hidden>VAT / Sales Tax ({(orderSummary.taxRate || 0) * 100}%)</span>
              <span aria-hidden>{formatCurrency(orderSummary.tax)}</span>
            </span>
            {(orderSummary.credit || 0) > 0 && (
              <span
                id="credit"
                className={styles.priceBreakdown}
                aria-label={`credit ${formatCurrency(orderSummary.credit)} `}
              >
                <span aria-hidden>Credits</span>
                <span aria-hidden>-{formatCurrency(orderSummary.credit)}</span>
              </span>
            )}
            {seatCount !== oldSeatCount && (
              <span
                id="total"
                className={clsx(styles.priceBreakdown, styles.total)}
                aria-label={`for the total price of ${formatCurrency(orderSummary.balance)} ${priceData.perText} `}
              >
                <strong aria-hidden>Total</strong>
                <strong aria-hidden>{formatCurrency(orderSummary.balance)}</strong>
              </span>
            )}
          </p>
        </>
      </Panel>
    </Aside>
  );
};

export default ChangeSeatsOrderSummary;
