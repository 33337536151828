import { SUPPORT_EMAIL } from "src/constants";

import CurrentUser from "src/components/CurrentUser/CurrentUser";
import TrackedLink from "src/components/TrackedLink/TrackedLink";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import styles from "./CheckoutFooter.module.css";

const supportSubject = "Help with purchasing Lens Subscription";
const supportEmail = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(supportSubject)}`;

export const CheckoutFooter = ({ username }: { username?: string | String }) => (
  <>
    {username && <CurrentUser />}
    {!username && (
      <div>
        You are not logged in.{" "}
        <TrackedLink className="primary" to="/signin">
          Login to your Lens ID
        </TrackedLink>
      </div>
    )}

    <div className={styles.footer}>
      Need help?{" "}
      <TrackedAnchor className="primary" target="_blank" href="https://k8slens.dev/purchase-request" rel="noreferrer">
        Contact Sales
      </TrackedAnchor>
      <TrackedAnchor className="primary" target="_blank" href={supportEmail} rel="noreferrer">
        Support
      </TrackedAnchor>
      <TrackedAnchor className="primary" target="_blank" href="https://docs.k8slens.dev/faq" rel="noreferrer">
        FAQ
      </TrackedAnchor>
    </div>
  </>
);
