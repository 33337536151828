import { UploadAvatarResponse } from "lens-platform-sdk/dist/cjs/UserService";
import { User } from "lens-platform-sdk";

import { getUserAvatar } from "src/utils/user";
import useAvatarUrl from "src/hooks/useAvatarUrl";

import { AvatarUpload } from "src/components/AvatarUpload/AvatarUpload";
import Avatar from "src/components/Avatar/Avatar";

import styles from "./ProfileAvatar.module.css";

export interface ProfileAvatarProps {
  onAvatarUpload: (file: File) => Promise<UploadAvatarResponse | undefined>;
  loading: boolean;
  user: User;
  onAvatarError?: (error: Error) => void;
}

export const ProfileAvatar = ({ user, loading, onAvatarUpload, onAvatarError }: ProfileAvatarProps) => {
  const avatarImage = getUserAvatar(user);
  const avatar = useAvatarUrl(avatarImage && !loading ? avatarImage : undefined);

  return (
    <div className={styles.avatarContainer}>
      <Avatar name={user.username ?? ""} className={styles.profileAvatar} size="md" image={avatar} />
      <AvatarUpload
        className={styles.avatarUploadForm}
        loading={loading}
        onAvatarUpload={onAvatarUpload}
        onAvatarError={onAvatarError}
      />
    </div>
  );
};
