import { useMemo, useState } from "react";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import { type BusinessHierarchyInvitation } from "lens-platform-sdk";
import { action } from "@k8slens/lds-icons";
import { useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { Notification } from "@k8slens/lds";

import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";
import { defaultDateTimeFormatOptions } from "src/utils/date";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import DateCell, { dateColDef } from "src/components/TableCells/DateCell";
import { stateColDef } from "src/components/TableCells/StateCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

const { DeleteIcon, CopyPasteIcon } = action;

const pendingTableColumnHelper = createColumnHelper<BusinessHierarchyInvitation>();

const caption = "Pending children invitations";

export const PendingChildrenTable = () => {
  const { businessId: parentBusinessId } = useParams<{ businessId: string }>();
  const { businessChildrenInvitations, loadingBusinessChildrenInvitations, cancelChildInvitation } =
    useBusinessChildInvitation(parentBusinessId);
  const [copiedLink, setCopiedLink] = useState<string>();

  const pendingChildrenTableColumns = useMemo(
    () => [
      pendingTableColumnHelper.accessor("state", { ...stateColDef }),
      pendingTableColumnHelper.accessor("createdAt", {
        ...dateColDef,
        header: () => <span>Invitation Date</span>,
        cell: ({ getValue }) => (
          <DateCell
            getValue={getValue}
            options={{
              ...defaultDateTimeFormatOptions,
              hour: "numeric",
              minute: "numeric",
            }}
          />
        ),
      }),
      pendingTableColumnHelper.accessor("updatedAt", {
        ...dateColDef,
        header: () => <span>Updated At</span>,
        meta: { ...dateColDef.meta, text: "secondary" },
        cell: ({ getValue, row }) => {
          const updated = row.original.createdAt !== row.original.updatedAt;
          const options: Intl.DateTimeFormatOptions = {
            ...defaultDateTimeFormatOptions,
            hour: "numeric",
            minute: "numeric",
          };

          // n/a if not updated
          const getter = updated ? getValue : () => null;

          return <DateCell getValue={getter} options={options} noDataText="N/A" />;
        },
      }),
      pendingTableColumnHelper.accessor("id", {
        ...contextMenuColDef,
        cell: ({ getValue, row }) =>
          row.original.state === "pending" ? (
            <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu for child invitation" }}>
              <ContextMenuItem
                label="Cancel"
                icon={DeleteIcon}
                onClick={() => cancelChildInvitation({ parentBusinessId, invitationId: getValue() })}
              />
              <ContextMenuItem
                label="Copy"
                icon={CopyPasteIcon}
                onClick={() => {
                  const invitationId = getValue();
                  const invitation = businessChildrenInvitations.find(({ id }) => id === invitationId);

                  if (invitation) {
                    const link = `${window.location.origin}/home/business-child-invitation-token/${invitation?.token}`;

                    copy(link);
                    setCopiedLink(link);
                  }
                }}
              />
            </ContextMenuCell>
          ) : null,
      }),
    ],
    [cancelChildInvitation, parentBusinessId, businessChildrenInvitations],
  );

  const { sorting, onSortingChange } = useTrackedTableSort({ table: caption });

  const sortedBusinessChildrenInvitations = useMemo(
    () =>
      (businessChildrenInvitations ?? []).sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    [businessChildrenInvitations],
  );
  const pendingChildrenTable = useReactTable({
    data: sortedBusinessChildrenInvitations,
    columns: pendingChildrenTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const pendingChildrenRowModel = pendingChildrenTable.getRowModel();
  const pendingChildrenRows = useMemo(() => pendingChildrenRowModel.rows, [pendingChildrenRowModel]);

  return (
    <>
      {copiedLink ? (
        <div className="mt-2 mb-6">
          <Notification
            level="success"
            message={
              <span>
                Invitation link <a href={copiedLink}>{copiedLink}</a> copied to clipboard
              </span>
            }
          />
        </div>
      ) : null}
      <Table<BusinessHierarchyInvitation>
        caption={caption}
        loading={loadingBusinessChildrenInvitations}
        rows={pendingChildrenRows}
        columns={pendingChildrenTable.getAllColumns()}
        headerGroups={pendingChildrenTable.getHeaderGroups()}
        noDataText="No pending invitations"
      />
    </>
  );
};
