import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { base } from "@k8slens/lds-icons";

import { useGetSubscriptions } from "src/hooks/useGetSubscriptions";
import { useGetLicense } from "src/hooks/useGetLicense";
import { useLicenseActivation } from "src/hooks/useActivateLicense";
import { TokenContext } from "src/providers/token-provider";
import { ProfileContext } from "src/providers/profile-provider";
import {
  getRecurlyHostedPaymentPageUrl,
  getRecurlyHostedPaymentPageUrlForLBID,
  type LensPlanCode,
  lensPlanCodes,
} from "src/services/plans";
import { getTrialState } from "src/utils/license";
import { usePurchaseQuantity } from "src/hooks/usePurchaseQuantity";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useScrollToTop } from "src/hooks/useScrollToTop";

import PublicLayout from "src/components/PublicLayout/PublicLayout";

import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { NotFoundException } from "lens-platform-sdk";
import BusinessID from "./BusinessID";
import Checkout, { type Step, Props as CheckoutProps, knownStep } from "./Checkout";
import Configure from "./Configure";
import SignUpStep from "./SignUpStep";
import LoginStep from "./LoginStep";
import { CheckoutFooter } from "./CheckoutFooter";
import { ThankYouFooter } from "./ThankYouFooter";
import ThankYou from "./ThankYou";
import {
  newSubscriptionForBusinesURLSearchParamsKey,
  useQueryNewSubscriptionForBusiness,
} from "src/hooks/useQueryNewSubscriptionForBusiness";
import { openUrl } from "src/utils/url";
import { pricingUrl } from "src/constants";
import { Notification } from "@k8slens/lds";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import styles from "./Subscribe.module.css";

const { SpinnerIcon } = base;

const pathMatcher = new RegExp(`/subscribe/(${lensPlanCodes.join("|")})`);
const scrollTopCompare = (to: string, from: string) => {
  const previous = from.replace(pathMatcher, "base");
  const current = to.replace(pathMatcher, "base");

  return previous !== current;
};

const Subscribe = () => {
  const { trackRedirect } = useAnalytics();
  const history = useHistory();
  const { initialized, keycloak } = useKeycloak();

  const isAuthenticated = initialized ? keycloak?.authenticated : undefined;
  const { activateLicense, licenseError } = useLicenseActivation();
  const { token, updateToken } = useContext(TokenContext);
  const [selectedPlanCode, setSelectedPlanCode] = useState<LensPlanCode | null>(null);
  const { loading: profileLoading, profile } = useContext(ProfileContext);
  const { loading: subscriptionLoading, subscriptions } = useGetSubscriptions(profile?.username);
  const loading = profileLoading || subscriptionLoading;
  const lensPlatformClient = useLensPlatformClient();
  const newSubscriptionForBusiness = useQueryNewSubscriptionForBusiness();

  useScrollToTop(scrollTopCompare);

  /**
   * License category / Trial -states
   */
  const license = useGetLicense();
  const trialState = getTrialState({
    license,
    error: licenseError,
  });
  const trialBlocked = trialState !== "available";

  /**
   * Checkout
   */
  const initialQuantity = usePurchaseQuantity();
  const [quantity, setQuantity] = useState<number>(initialQuantity || 1);
  const activateAndRedirect = useCallback(
    async (plan: "personal" | "pro-trial") => {
      const active = await activateLicense("", plan);

      if (active) {
        updateToken(-1);
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = `${window.location.origin}/subscribe/${plan}/thank-you?client_id=lens-extension`;

        return true;
      }

      // TODO: Handle error
      return false;
    },
    [activateLicense, updateToken],
  );

  const redirectToRecurly = useCallback(
    (plan: "pro-yearly" | "pro-monthly") => {
      if (token && profile) {
        const url = getRecurlyHostedPaymentPageUrl(plan, profile, token);

        trackRedirect(url);
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = url;

        return Promise.resolve(true);
      }

      // TODO: Handle error
      return Promise.resolve(false);
    },
    [token, profile, trackRedirect],
  );

  const redirectToRecurlyForLBID = useCallback(
    (plan: "pro-yearly" | "pro-monthly", businessId: string, businessName: string) => {
      if (token && profile) {
        const url = getRecurlyHostedPaymentPageUrlForLBID(plan, profile, businessId, businessName, quantity);

        trackRedirect(url);
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = url;

        return Promise.resolve(true);
      }

      return Promise.reject(new Error("User not logged in"));
    },
    [trackRedirect, token, profile, quantity],
  );

  /**
   * Purchase
   */
  const handleCheckoutForLID = useCallback(
    (planCode: LensPlanCode) => {
      switch (planCode) {
        case "pro-monthly":
        case "pro-yearly":
          return redirectToRecurly(planCode);
        case "personal":
        case "pro-trial":
          return activateAndRedirect(planCode);
        default:
          throw Error(`Unknown plan: ${planCode}`);
      }
    },
    [redirectToRecurly, activateAndRedirect],
  );

  const handleCheckoutForLBID = useCallback(
    async (planCode: "pro-yearly" | "pro-monthly", businessId: string) => {
      const business = await lensPlatformClient.business.getOne(businessId);
      const businessName = business?.name ?? "";

      if (!business?.id) {
        throw new Error(`Business ${businessId} not found`);
      }

      try {
        // if the business already has a parent, the parent should already has billing info
        // and the subscription will be billed to the parent, therefore we should redirect to <ThankYou />
        if (await lensPlatformClient.business.getParent(business.id)) {
          // auto create subscription that will be billed to the parent LBID
          await lensPlatformClient.business.createSubscription(business.id, planCode, quantity);
          history.push(`/subscribe/thank-you?client_id=lens-extension&account_code=${business.id}&plan=${planCode}`);

          return true;
        }
      } catch (error) {
        if (!(error instanceof NotFoundException)) {
          // unknown error, rethrow
          throw error;
        }
      }

      return redirectToRecurlyForLBID(planCode, businessId, businessName);
    },
    [lensPlatformClient.business, redirectToRecurlyForLBID, history, quantity],
  );

  const handlePurchase = useCallback(
    (planCode: LensPlanCode, businessId?: string) => {
      if (businessId && (planCode === "pro-yearly" || planCode === "pro-monthly")) {
        return handleCheckoutForLBID(planCode, businessId);
      }

      return handleCheckoutForLID(planCode);
    },
    [handleCheckoutForLID, handleCheckoutForLBID],
  );

  /**
   * Login / signup
   */
  const handleLogin = useCallback(
    (planCode: LensPlanCode) => {
      if (planCode === "pro-trial" || planCode === "personal") {
        setSelectedPlanCode(planCode);

        return Promise.resolve(true);
      }
      // TODO: Figure out why history.replace fails
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = addPassThroughKeys(`${window.location.origin}/subscribe/${planCode}/choose-business-id`, {
        purchaseQuantity: quantity.toString(),
      });
      // setQuantity(quantity);
      // history.replace("/subscribe/:planCode/choose-business-id", {
      //   planCode
      // });
      // history.replace(`/subscribe/${planCode}/choose-business-id`);

      return Promise.resolve(true);
    },
    [
      quantity,
      // history,
      // setQuantity
    ],
  );

  /**
   * Do after profile is loaded
   */
  useEffect(() => {
    // need to wait for profile.username to be loaded before we can activate license
    if (selectedPlanCode && profile?.username) {
      handleCheckoutForLID(selectedPlanCode).then((result) => {
        setSelectedPlanCode(null);
      });
    }
  }, [selectedPlanCode, profile?.username, handleCheckoutForLID, history]);

  const loadingProfile = selectedPlanCode !== null;

  /**
   * Configure
   */
  const handleConfigure = useCallback(
    (planCode: LensPlanCode): Promise<boolean> => {
      if (token && profile) {
        if (newSubscriptionForBusiness) {
          // pass newSubscriptionForBusiness to /choose-business-id if it is a string
          history.push(
            `/subscribe/${planCode}/choose-business-id?${newSubscriptionForBusinesURLSearchParamsKey}=${newSubscriptionForBusiness}`,
          );
        } else {
          history.push(`/subscribe/${planCode}/choose-business-id`);
        }

        return Promise.resolve(true);
      }
      history.push(
        addPassThroughKeys(`/subscribe/${planCode}/login`, {
          purchaseQuantity: quantity.toString(),
        }),
      );

      return Promise.resolve(true);
    },
    [token, profile, history, quantity, newSubscriptionForBusiness],
  );

  /**
   * Routing helpers
   */
  const isThankYouPage = useMemo(() => history.location.pathname.match(/\/thank-you$/), [history.location.pathname]);
  const isSubscribePage = useMemo(() => history.location.pathname.match(/\/subscribe$/), [history.location.pathname]);

  const checkoutSteps: Required<CheckoutProps>["steps"] = useMemo(() => {
    const isLoggedIn = !profileLoading && profile !== null;

    return [
      {
        id: "configure",
        label: "Configure",
      },
      !isLoggedIn
        ? {
            id: "login",
            label: "Log In / Sign Up",
          }
        : undefined,
      {
        id: "choose-business-id",
        label: "Business ID",
      },
      {
        id: "payment-details",
        label: "Payment Details",
      },
    ].filter((d) => d !== undefined) as Required<CheckoutProps>["steps"];
  }, [profileLoading, profile]);

  /**
   * Footer
   */
  let footer = <CheckoutFooter username={profile?.username} />;

  if (isThankYouPage) {
    footer = <ThankYouFooter />;
  }

  // Redirect to Pricing page when hitting the /subscribe page
  if (isSubscribePage) {
    openUrl(pricingUrl);

    return null;
  }

  return (
    <PublicLayout title="Lens Store" footer={!loadingProfile && footer} size="xl">
      {licenseError && (
        <Notification type="flash" level="error" message={licenseError} className={styles.errorNotification} />
      )}
      {trialState === "used" && (
        <div className={styles.trialEndNotification}>
          {/* eslint-disable-next-line max-len */}
          Need help convincing your boss? Need more time to onboard your teams? We’ll figure out something that works
          for you and your team.{" "}
          <TrackedAnchor href="https://public.refiner.io/s/eolx2e/9669d4a0-7a07-11ed-b065-2f290bc11892" target="_blank">
            Let us help!
          </TrackedAnchor>
        </div>
      )}
      <Switch>
        <Route path={["/subscribe/thank-you", "/subscribe/:plan/thank-you"]}>
          <ThankYou loading={profileLoading} />
        </Route>
        <Route path={["/subscribe/:plan/:currentStep", "/subscribe/:plan"]}>
          {({ match }) => {
            const params = match?.params as {
              plan: LensPlanCode;
              currentStep?: Step | "signup";
            };
            const { plan } = params;
            let steps = checkoutSteps.map(({ id, label }) => ({
              id,
              label,
              url: `/subscribe/${plan}/${id}`,
            }));
            let currentStep = steps.find(({ id }) => id === params.currentStep);

            if (params.currentStep === "signup") {
              currentStep = steps.find(({ id }) => id === "login");
            }

            const requiresPurchase = plan !== "personal" && plan !== "pro-trial";

            if (!requiresPurchase) {
              // Directly settable plans have only login/signup as an option
              if (currentStep && currentStep.id !== "login") {
                history.replace("/subscribe");

                return "";
              }

              if (params.currentStep && params.currentStep !== "signup" && !knownStep.includes(params.currentStep)) {
                return (
                  <div data-testid="loading-spinner-unknown-step">
                    <SpinnerIcon size="xl" />
                  </div>
                );
              }
              steps = [];
            }

            if (!plan || !lensPlanCodes.includes(plan)) {
              history.replace("/subscribe");

              return "";
            }

            const trialViolation = plan === "pro-trial" && trialBlocked;

            if (currentStep?.id === "configure" && trialViolation) {
              history.replace(`/subscribe/pro-monthly/${currentStep.id}`);

              return "";
            }

            if (currentStep?.id === "thank-you") {
              steps = [];
            }

            const allowNavigation = currentStep?.id !== "choose-business-id";

            const redirectPath = `/subscribe/${plan}/login/purchase-quantity/${quantity}`;

            return (
              <Checkout steps={steps} currentStep={currentStep} allowNavigation={allowNavigation}>
                <Switch>
                  <Route path="/subscribe/:plan/configure">
                    <Configure
                      quantity={quantity}
                      setQuantity={setQuantity}
                      planCode={plan}
                      onConfigure={handleConfigure}
                      loading={loading}
                    />
                  </Route>
                  {/* Purchase quantity path used in verification emails */}
                  <Route path="/subscribe/:planCode/login/purchase-quantity/:purchaseQuantity">
                    {({ match }) => {
                      const { planCode, purchaseQuantity } = match?.params as {
                        planCode: LensPlanCode;
                        purchaseQuantity: string;
                      };

                      // If the users click the verification link in the email and planCode are 'personal'
                      // or 'pro-trial' then we want to redirect them to /login step as there is `activateRedirect`
                      // will be trigger immediately after logged in.
                      if (planCode === "personal" || planCode === "pro-trial") {
                        return <Redirect to={`/subscribe/${planCode}/login`} />;
                      }

                      let parsedQuantity = parseInt(purchaseQuantity, 10);

                      if (Number.isNaN(parsedQuantity)) {
                        parsedQuantity = 1; // Default to one if there's a problem
                      }
                      setQuantity(parsedQuantity);
                      const toPath = addPassThroughKeys(`/subscribe/${planCode}/choose-business-id`, {
                        purchaseQuantity: parsedQuantity.toString(),
                      });

                      return <Redirect push={false} to={toPath} />;
                    }}
                  </Route>
                  <Route path="/subscribe/:plan/login">
                    <LoginStep showCart={requiresPurchase} planCode={plan} onLogin={handleLogin} />
                  </Route>
                  <Route path="/subscribe/:plan/signup">
                    <SignUpStep
                      showCart={requiresPurchase}
                      planCode={plan}
                      onSignup={handleLogin}
                      redirectPath={redirectPath}
                    />
                  </Route>
                  <Route path="/subscribe/:plan/choose-business-id">
                    {({ match }) => {
                      const { plan } = match?.params as {
                        plan?: LensPlanCode;
                        currentStep?: Step | "signup";
                      };

                      if (isAuthenticated === false) {
                        history.replace(
                          addPassThroughKeys(`/subscribe/${plan}/login`, {
                            purchaseQuantity: quantity.toString(),
                          }),
                        );

                        return "";
                      }

                      // No trials or personal plans for teams
                      if (plan !== "pro-monthly" && plan !== "pro-yearly") {
                        history.replace("/subscribe");

                        return "";
                      }

                      return (
                        <BusinessID
                          quantity={quantity}
                          license={loadingProfile ? undefined : license}
                          planCode={plan}
                          onPurchase={handlePurchase}
                          loading={loading}
                        />
                      );
                    }}
                  </Route>
                  <Redirect to="/subscribe/:plan/configure" />
                </Switch>
              </Checkout>
            );
          }}
        </Route>
        <Route path="/subscribe-personal">
          <Redirect to="/subscribe" />
        </Route>
      </Switch>
    </PublicLayout>
  );
};

export default Subscribe;
