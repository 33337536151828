import { useParams } from "react-router-dom";
import styles from "src/components/Business/SCIMTokens.module.css";
import { CopyToClipboard } from "src/components/CopyToClipboard/CopyToClipboard";
import { useBusinessSCIMToken } from "src/hooks/useBusinessSCIMToken";

import { backend } from "src/constants";
import { VisibilityToggle } from "src/components/VisibilityToggle/VisibilityToggle";
import { useState } from "react";
import { BusinessSCIMToken } from "lens-platform-sdk/dist/cjs/BusinessService";

export const SCIMTokens = () => {
  const { businessId } = useParams<{ businessId?: string }>();
  const { scimTokens } = useBusinessSCIMToken(businessId);

  const baseUrl = `${backend}/businesses/${businessId}/scim/v2`;

  return (
    <>
      <dl className={styles.scim}>
        <dt>
          <strong>Base URL:</strong>
        </dt>
        <dd>
          <span title={baseUrl}>{baseUrl}</span>
          <CopyToClipboard text={baseUrl} />
        </dd>
        {scimTokens.length > 0 ? (
          <dt>
            <strong>API Token:</strong>
          </dt>
        ) : null}
        {scimTokens.map((token) => (
          <Token token={token} />
        ))}
      </dl>
    </>
  );
};

function Token({ token }: { token: BusinessSCIMToken }) {
  const [hideTokenString, setHideTokenString] = useState(process.env.NODE_ENV === "test" ? false : true);

  return (
    <dd key={token.id}>
      <span
        id="scim-token"
        title={token.token}
        className="font-mono"
        aria-live="polite"
        aria-label={hideTokenString ? "obscured" : token.token}
      >
        {hideTokenString ? "*".repeat(token.token.length) : token.token}
      </span>
      <VisibilityToggle
        buttonProps={{
          "aria-label": "Show / hide token",
          "aria-controls": "scim-token",
          className: styles.visibilityButton,
        }}
        onClick={() => {
          setHideTokenString((prev) => !prev);
        }}
        autoHide
        onHide={() => {
          setHideTokenString(true);
        }}
      />
      <CopyToClipboard text={token.token} />
    </dd>
  );
}
