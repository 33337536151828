import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import type { SubscriptionSeat } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { getCurrentSubscriptionSeat } from "src/services/getCurrentSubscriptionSeat";
import { getSubscriptionSeatWithBillingPeriod } from "src/utils/license";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get user's subscription seats by username
 */
export const useGetSubscriptionSeats = (username?: string) => {
  const lensPlatformClient = useLensPlatformClient();

  const [subscriptionSeats, setSubscriptionSeats] = useState<SubscriptionSeat[]>([]);

  const [error, setError] = useState<LensSDKException | null>(null);
  const [loading, setLoading] = useState(false);

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getSubscriptionSeats = useCallback(async () => {
    try {
      if (mounted.current) {
        setLoading(true);
      }

      if (username) {
        const response = await lensPlatformClient.user.getUserSubscriptionsSeats(username);

        if (mounted.current) {
          setSubscriptionSeats(response);
          setError(null);
        }
      }
    } catch (exception) {
      if (mounted.current && exception instanceof LensSDKException) {
        setError(exception);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [lensPlatformClient.user, username]);

  useEffect(() => {
    getSubscriptionSeats();
  }, [getSubscriptionSeats]);

  const currentSubscriptionSeat = useMemo(() => getCurrentSubscriptionSeat(subscriptionSeats), [subscriptionSeats]);
  const currentSubscriptionSeatWithBillingPeriod = useMemo(() => {
    if (currentSubscriptionSeat) {
      return getSubscriptionSeatWithBillingPeriod(currentSubscriptionSeat);
    }

    return undefined;
  }, [currentSubscriptionSeat]);

  return {
    loading,
    error,
    subscriptionSeats,
    currentSubscriptionSeat,
    currentSubscriptionSeatWithBillingPeriod,
    reload: getSubscriptionSeats,
  };
};
