import { memo, useCallback, useContext } from "react";

import { useLogout } from "src/hooks/useLogout";
import { useAnalytics } from "src/hooks/useAnalytics";
import { ProfileContext } from "src/providers/profile-provider";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import styles from "./ThankYouFooter.module.css";

const logoutButtonLabel = "Logout";

export const ThankYouFooter = memo(() => {
  const { trackButtonClicked } = useAnalytics();
  const { profile } = useContext(ProfileContext);
  const signoutAction = useLogout();

  const handleLogout = useCallback(() => {
    trackButtonClicked(logoutButtonLabel);
    signoutAction();
  }, [trackButtonClicked, signoutAction]);

  return (
    <div>
      <div className={styles.thankYouFooter}>
        <span>
          Logged in as <strong>{profile?.username}</strong>
        </span>
        <TrackedLink className="primary" to="/home">
          Lens ID Portal
        </TrackedLink>
        <button tabIndex={0} role="link" type="button" onClick={handleLogout}>
          {logoutButtonLabel}
        </button>
      </div>
    </div>
  );
});
