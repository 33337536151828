import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Panel } from "@k8slens/lds";

import { usePurchaseQuantity } from "src/hooks/usePurchaseQuantity";
import { usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { PlanCode } from "src/services/plans";
import { useAnalytics } from "src/hooks/useAnalytics";

import { LocationState } from "src/App";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import SignIn from "src/components/SignIn/SignIn";

import { KeycloakLoadingContent } from "./KeycloakLoadingContent";
import OrderSummary from "./OrderSummary";

import styles from "./LoginStep.module.css";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { KeycloakLoading } from "src/components/KeycloakLoading/KeycloakLoading";

const signupLabel = "Sign Up for Lens ID";

export interface Props {
  showCart: boolean;
  planCode: PlanCode;
  onLogin(planCode: PlanCode): Promise<boolean>;
}

const LoginStep: React.FC<Props> = ({ showCart, planCode, onLogin }) => {
  const { trackButtonClicked } = useAnalytics();
  const history = useHistory<LocationState | undefined>();

  const {
    location: { pathname },
  } = history;
  const { keycloak } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const keycloakLoading = useKeycloakLoading();

  const passThroughKeys = usePassThroughKeys();
  const quantity = usePurchaseQuantity() || 1;
  const signupPath = addPassThroughKeys("signup", passThroughKeys);

  const isAuthenticated = keycloak?.authenticated;
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  useEffect(() => {
    if (!loggingIn && isAuthenticated) {
      onLogin(planCode);
      setLoggingIn(true);
    }

    return () => {};
  }, [isAuthenticated, planCode, onLogin, loggingIn]);

  if (keycloakTimeout) {
    return (
      <Panel className={styles.login} contentComponent="div">
        <KeycloakTimeout />
      </Panel>
    );
  }

  if (keycloakLoading) {
    return (
      <Panel className={styles.login} contentComponent="div">
        <KeycloakLoading content={KeycloakLoadingContent} />
      </Panel>
    );
  }

  const header = "Login with Lens ID";

  return (
    <Panel className={styles.login} contentComponent="div">
      <h3 className="lds-h1">{header}</h3>
      <aside className={styles.info}>
        Don’t have Lens ID?{" "}
        <button
          type="button"
          onClick={() => {
            trackButtonClicked(signupLabel);
            history.replace(signupPath);
          }}
        >
          {signupLabel}
        </button>
      </aside>
      <SignIn
        hideSSO={true}
        disabled={isAuthenticated}
        redirectUri={pathname}
        getKeyCloakRedirectUri={() => addPassThroughKeys(`${window.location.origin}${pathname}`, passThroughKeys)}
        formWrapperClassName={styles.loginForm}
        footerClassName={styles.loginFooter}
        loginButtonLabel="Login to continue"
      />
      {showCart && <OrderSummary quantity={quantity} planCode={planCode} />}
    </Panel>
  );
};

export default LoginStep;
