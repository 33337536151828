import React, { type PropsWithChildren, useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import type { Business, LensSDKException, UserWithEmail } from "lens-platform-sdk";
import { navigation } from "@k8slens/lds-icons";

import { useAnalytics } from "src/hooks/useAnalytics";
import { NewBusiness, useCreateBusiness } from "src/hooks/useCreateBusiness";

import Select from "src/components/Select/Select";

import OpenModalButton from "./OpenModalButton";
import CreateBusinessModal from "./CreateBusinessModal";
import IDSelectItem from "./IDSelectItem";

import styles from "./IDSelect.module.css";
import { type QueryObserverResult } from "@tanstack/react-query";
import { useUsername } from "src/hooks/useUsername";
import { useBooleanFlagValue } from "@openfeature/react-sdk";

const { ArrowDownIcon } = navigation;

interface Props {
  businessId?: string;
  sideBarOpen: boolean;
  profile: UserWithEmail;
  businesses: Array<Business>;
  loadingBusinesses: boolean;
  reloadBusiness(): Promise<QueryObserverResult<Business[], LensSDKException>>;
}

/**
 * id to be used in the select options
 */
export const usernameOptionId = "usernameOptionId";

/**
 * The shape of the option data in the selector
 */
interface Option {
  id: typeof usernameOptionId | Business["id"];
  /** = profile.username or business.name */
  label: string;
  subtitle?: string;
}

/**
 * The select component for the user to switch between their own account or their "Lens Business ID"
 */
const IDSelect: React.FC<PropsWithChildren<Props>> = ({
  businessId,
  profile,
  sideBarOpen,
  businesses,
  loadingBusinesses,
  reloadBusiness,
}) => {
  const { track } = useAnalytics();

  const history = useHistory();
  const { createBusiness, loading: creatingBusiness, error: errorCreateBusiness } = useCreateBusiness();

  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const username = useUsername();
  const usernameOption = useMemo(() => {
    // fallback to the username in token if the profile is still loading
    if (!profile.username) {
      return {
        id: usernameOptionId,
        label: `@${username}`,
      };
    }

    return {
      id: usernameOptionId,
      label: `@${profile.username}`,
    };
  }, [username, profile.username]);

  /**
   * "Lens Business Id" entities + username = all options
   */
  const businessOptions = useMemo(
    () => businesses.map(({ id, handle, name }) => ({ id, label: `@${handle}`, subtitle: name })),
    [businesses],
  );

  const allOptions = useMemo<Option[]>(
    () => [usernameOption, ...businessOptions.sort((a, b) => a.label.localeCompare(b.label))],
    [usernameOption, businessOptions],
  );

  const [selected, setSelected] = useState<Option>(
    // default to select username
    usernameOption,
  );
  const options = useMemo<Option[]>(
    () =>
      allOptions.length > 1
        ? // Show only if more than one available, sort by label alphabetically
          allOptions
        : [],
    [allOptions],
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setCreateModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);
  const loading = loadingBusinesses || creatingBusiness;

  useEffect(() => {
    const business = businesses.find(({ id }) => id === businessId);

    if (businessId && business) {
      // set selected as the business id from /business/:businessId
      // on component mounts
      setSelected({
        id: business.id,
        label: `@${business.handle}`,
        subtitle: business.name,
      });
    }
  }, [usernameOption, businessId, businesses]);

  const handleChange = useCallback(
    (value: Option) => {
      setSelected(value);

      // if the user selects a "Lens Business ID", redirect to /business/:businessId
      if (value.id !== usernameOptionId) {
        history.push(`/business/${value.id}`, { routeChangeBy: "id-select" });
      }

      // if the user selects his/her username, ensure the pathname is /home
      if (value.id === usernameOptionId && history.location.pathname !== "/home") {
        history.push("/home", { routeChangeBy: "id-select" });
      }
    },
    [history],
  );

  const handleClickCreate = useCallback(
    async (business: Partial<NewBusiness>) => {
      const newBusiness = await createBusiness(business as NewBusiness);

      if (newBusiness) {
        track("BusinessID created", { businessId: newBusiness.id });
        await reloadBusiness();
        closeModal();
        history.push(`/business/${newBusiness.id}`);
      }
    },
    [closeModal, createBusiness, history, reloadBusiness, track],
  );

  const renderButton = useCallback(
    (item: Option, open: boolean) => (
      <Listbox.Button
        ref={selectButtonRef}
        className={clsx(styles.item, {
          [styles.selectOpen]: open,
        })}
        data-testid="id-select-button"
      >
        <IDSelectItem item={item} profile={profile} />
        <ArrowDownIcon className={styles.dropDownIcon} />
      </Listbox.Button>
    ),
    [profile],
  );

  const showCreateLBIDButtonFlag = useBooleanFlagValue("show-create-lbid-button", false);
  const showCreateLBIDButton = showCreateLBIDButtonFlag;

  return (
    <>
      <Select
        className={clsx(styles.idSelect, { [styles.sideBarOpen]: sideBarOpen })}
        optionsClassName={styles.options}
        onChange={handleChange}
        loading={loading}
        value={selected}
        options={options}
        renderButton={renderButton}
        buttonRef={selectButtonRef}
        optionClassName={(state) => clsx({ [styles.selected]: state?.selected, [styles.active]: state?.active })}
        renderOption={(option) => <IDSelectItem item={option} profile={profile} />}
        renderAfterLastOption={() => {
          if (showCreateLBIDButton) {
            return <OpenModalButton handleClick={() => openModal()} />;
          }

          return null;
        }}
        renderEmptyOption={() => (
          <>
            <div className={styles.emptyOptionTextWrapper}>
              <p className={styles.emptyOptionText}>You are currently not an admin of any existing Lens Business ID.</p>
            </div>
            {showCreateLBIDButton ? (
              <OpenModalButton
                handleClick={() => {
                  openModal();
                }}
              />
            ) : null}
          </>
        )}
      />
      <CreateBusinessModal
        open={createModalOpen}
        disabled={creatingBusiness}
        loading={creatingBusiness}
        error={errorCreateBusiness}
        onModalClose={() => {
          closeModal();
        }}
        onClickCreate={handleClickCreate}
      />
    </>
  );
};

export default IDSelect;
