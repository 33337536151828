import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAnalytics } from "src/hooks/useAnalytics";
import { queryClient } from "src/queryClient";
import { UserCommunications } from "lens-platform-sdk/dist/cjs/UserService";

/**
 * Get user communication preferences
 */
export const useUserCommunicationPreferences = () => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingUserCommunicationPreferences,
    error: errorLoadingUserCommunicationPreferences,
    data: userCommunicationPreferences,
    refetch: refetchUserCommunicationPreferences,
  } = useQuery<UserCommunications, LensSDKException>({
    queryKey: ["user-communication-preferences"],
    queryFn: async () => {
      try {
        const preferences = await lensPlatformClient.user.getUserCommunicationsPreferences();

        track("Email communication preferences retrieved for user");

        return preferences;
      } catch (error) {
        throw new Error("Fetching user communication preferences failed");
      }
    },
    initialData: {
      marketing: false,
      onboarding: true,
    } as UserCommunications,
  });

  const {
    isPending: updateUserCommunicationPreferencesLoading,
    error: errorUpdateUserCommunictaionPreferences,
    mutateAsync: updateUserCommunicationPreferences,
  } = useMutation<UserCommunications, LensSDKException, UserCommunications>({
    mutationFn: async (preferences: UserCommunications) => {
      try {
        const updatedPreferences = await lensPlatformClient.user.updateUserCommunicationsPreferences(preferences);

        return updatedPreferences;
      } catch (error) {
        throw new Error("Updating user communication preferences failed");
      }
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user-communication-preferences"] });
    },
  });

  return {
    userCommunicationPreferences,
    refetchUserCommunicationPreferences,
    loadingUserCommunicationPreferences,
    errorLoadingUserCommunicationPreferences,
    updateUserCommunicationPreferencesLoading,
    errorUpdateUserCommunictaionPreferences,
    updateUserCommunicationPreferences,
  };
};
