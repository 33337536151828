import React from "react";
import clsx from "clsx";

import styles from "./Checkbox.module.css";

export interface Props<T> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "value"> {
  value: T;
  onChange(value: T, evt: React.ChangeEvent<HTMLInputElement>): void;
}

export default function Checkbox({ value, onChange, ...inputProps }: Props<boolean>) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(!value, e);
  }

  return (
    <input
      type="checkbox"
      checked={value}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...inputProps}
      className={clsx(styles.checkbox, inputProps?.className || "")}
    />
  );
}
