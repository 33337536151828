import { useQuery } from "./useQuery";

export const purchaseQuantityKeyParameter = "purchase-quantity";

/**
 * Get the purchase quantity from
 * query string parameter purchase-quantity
 */
export const usePurchaseQuantityKey = () => {
  // The purchase quantity can be defined with "?purchase-quantity=value" in the url
  const query = useQuery();
  const purchaseSeatsKeyQueryParameter = query.get(purchaseQuantityKeyParameter);

  const quantity = purchaseSeatsKeyQueryParameter || "";

  return quantity;
};
