import { Route, Redirect } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";

import { addPassThroughKeys } from "src/services/passThroughKey";
import { usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { KeycloakLoading } from "src/components/KeycloakLoading/KeycloakLoading";

const PrivateRoute = (props: RouteProps) => {
  const { keycloak } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const keycloakLoading = useKeycloakLoading();
  const { location } = props;
  const passThroughKeys = usePassThroughKeys();

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  if (keycloakLoading) {
    return <KeycloakLoading />;
  }

  if (keycloak?.authenticated) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...props} />
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/signin",
        state: { from: location },
        ...(passThroughKeys
          ? {
              search: addPassThroughKeys("", passThroughKeys),
            }
          : {}),
      }}
    />
  );
};

export default PrivateRoute;
