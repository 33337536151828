import React, { useContext, useMemo } from "react";
import { FormInput } from "@k8slens/lds-form";

import TrackedButton from "src/components/Button/TrackedButton";

import styles from "./SamlSSOProviderForm.module.css";
import { ButtonBar } from "@k8slens/lds";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";
import { BusinessContext } from "src/components/Business/Base";

interface Props {
  onSubmit: () => void;
  samlSSOServiceUrl: InputState;
  samlSSOIdpEntityId: InputState;
  dispatch: React.Dispatch<{
    key: SAMLFormFieldKey;
    value: SAMLFormField[SAMLFormFieldKey]["value"];
    valid: SAMLFormField[SAMLFormFieldKey]["valid"];
  }>;
}

type InputState = {
  value: string;
  valid: boolean;
};

export type SAMLFormField = {
  samlSSOServiceUrl: { value: string; valid: boolean };
  samlSSOIdpEntityId: { value: string; valid: boolean };
};

export type SAMLFormFieldKey = keyof SAMLFormField;

export const SamlSSOProviderForm = ({ onSubmit, samlSSOServiceUrl, samlSSOIdpEntityId, dispatch }: Props) => {
  const { businessId } = useContext(BusinessContext);
  const { creatingBusinessSSO } = useBusinessSSO(businessId);

  const valid = useMemo(
    () => [samlSSOServiceUrl, samlSSOIdpEntityId].every(({ valid }) => valid !== false),
    [samlSSOServiceUrl, samlSSOIdpEntityId],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onSubmit();
  };

  return (
    <form aria-label="Saml SSO Provider settings" onSubmit={handleSubmit}>
      <fieldset>
        <FormInput
          wrapperProps={{ className: styles.singleSignOnServiceUrl }}
          label="SSO Service URL"
          type="url"
          name="singleSignOnServiceUrl"
          placeholder="https://accounts.google.com/o/saml2/idp?idpid=xyz"
          value={samlSSOServiceUrl.value}
          onChange={(value, valid) => dispatch({ key: "samlSSOServiceUrl", value, valid })}
          required
        />
        <FormInput
          wrapperProps={{ className: styles.idpEntityId }}
          label="Identity provider Entity ID"
          type="url"
          name="idpEntityId"
          placeholder="https://accounts.google.com/o/saml2?idpid=xyz"
          value={samlSSOIdpEntityId.value}
          onChange={(value, valid) => dispatch({ key: "samlSSOIdpEntityId", value, valid })}
          required
        />
        <ButtonBar type="grid" className={styles.buttonBar}>
          <TrackedButton
            label="Save single sign-on settings"
            onClick={handleSubmit}
            primary
            disabled={!valid}
            loading={creatingBusinessSSO}
            textTransform="capitalize"
          />
        </ButtonBar>
      </fieldset>
    </form>
  );
};
