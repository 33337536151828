import { useParams } from "react-router-dom";
import { useQuery } from "./useQuery";

export const invitationKeyParameter = "invitation-key";

/**
 * Get the invitation key from either a route parameter :invitationKey or
 * query string parameter invitation-key
 */
export const useInvitationKey = () => {
  // The invitation key can be defined with "/:invitationKey" route parameter
  // The value is undefined if there is no such route parameter
  const { invitationKey: invitationKeyRouteParam } = useParams<{
    invitationKey: string;
  }>();

  // The invitation key can be defined with "?invitation-key=value" in the url
  const query = useQuery();
  const invitationKeyQueryParameter = query.get(invitationKeyParameter);

  const invitationKey = invitationKeyQueryParameter || invitationKeyRouteParam || "";

  return invitationKey;
};
