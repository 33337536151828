import { useQuery } from "./useQuery";

export const newSubscriptionForBusinesURLSearchParamsKey = "new-subscription-for-business";

/**
 * Get the search parameter `?new-subscription-for-business` in the URL
 */
export const useQueryNewSubscriptionForBusiness = () => {
  const query = useQuery();
  const newSubscriptionForBusinesURLSearchParamsValue = query.get(newSubscriptionForBusinesURLSearchParamsKey);

  return newSubscriptionForBusinesURLSearchParamsValue;
};
