import { Switch, Notification } from "@k8slens/lds";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";
import styles from "./SSOSwitch.module.css";
import { useContext } from "react";
import { BusinessContext } from "./Base";
import { useBusiness } from "src/hooks/useBusiness";
import { useBusinessSCIMToken } from "src/hooks/useBusinessSCIMToken";
import omit from "lodash/omit";

export const SSOSwitch = ({
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { businessId, business } = useContext(BusinessContext);
  const { deleteBusinessSSO, deletingBusinessSSO, businessSSO, errorDeletingBusinessSSO } = useBusinessSSO(businessId);
  const { replaceBusiness, updatingBusiness, replacingBusiness, errorUpdatingBusiness, errorReplacingBusiness } =
    useBusiness(businessId);
  const { scimTokens, errorDeletingSCIMToken, deleteSCIMToken, deletingSCIMToken } = useBusinessSCIMToken(businessId);

  return (
    <div className={styles.ssoSwitchWrapper}>
      <section className="self-center">
        <h3 className="lds-h3">Single Sign-On (SSO)</h3>
        <p className="leading-normal">
          Enable single sign-on for your Lens Business ID managed domains to allow your users to securely authenticate
          with SSO using SAML or OIDC.
        </p>
        {errorUpdatingBusiness ? (
          <Notification level="error" message={errorUpdatingBusiness.message} type="closable" className="my-5" />
        ) : null}
        {errorReplacingBusiness ? (
          <Notification level="error" message={errorReplacingBusiness.message} type="closable" className="my-5" />
        ) : null}
        {errorDeletingBusinessSSO ? (
          <Notification level="error" message={errorDeletingBusinessSSO.message} type="closable" className="my-5" />
        ) : null}
        {errorDeletingSCIMToken ? (
          <Notification level="error" message={errorDeletingSCIMToken.message} type="closable" className="my-5" />
        ) : null}
      </section>
      <Switch
        className={styles.switch}
        disabled={updatingBusiness || replacingBusiness || deletingBusinessSSO || deletingSCIMToken}
        onChange={(newChecked) => {
          setChecked(newChecked);

          if (!newChecked && businessSSO && businessId) {
            deleteBusinessSSO(businessId);

            if (business) {
              replaceBusiness({
                ...omit(business, [
                  "id",
                  "createdAt",
                  "updatedAt",
                  "external",
                  "businessIdLiteSubscriptionId",
                  "businessUsers",
                  "createdById",
                ]),
                // disable auto join if SSO is disabled
                ssoAutoJoin: false,
                // drop all verified domains if the SSO is disabled
                verifiedDomains: [],
              });

              // remove all SCIM tokens if the SSO is disabled
              for (const token of scimTokens) {
                deleteSCIMToken({
                  businessId,
                  tokenId: token.id,
                });
              }
            }
          }
        }}
        checked={checked}
      />
    </div>
  );
};
