import Keycloak from "keycloak-js";
import queryString from "query-string";
import { isStaging } from "src/constants";

const keycloakOrigin = process.env.REACT_APP_KEYCLOAK_URL || window.location.origin;
const keycloakClientId = queryString.parse(window.location.search).client_id || "lensCloudFrontend";
const keycloakRealm =
  window.location.pathname.split("/")[2] === "realms" ? window.location.pathname.split("/")[3] : "lensCloud";

export const options = {
  url: `${keycloakOrigin}/auth`,
  realm: `${keycloakRealm?.toString()}`,
  clientId: `${keycloakClientId?.toString()}`,
};

const getInitOptions = (pathname: string, responseMode: string = "fragment") => ({
  enableLogging: process.env.NODE_ENV === "development" || isStaging,
  responseMode,
  // The page at the silent check-sso redirect uri is loaded in the iframe after
  // successfully checking your authentication state and retrieving the tokens from
  // the Keycloak server. It has no other task than sending the received tokens to
  // the main application.
  // (the file is in public/keycloak-silent-check-sso.html)
  silentCheckSsoRedirectUri: `${window.location.origin}/keycloak-silent-check-sso.html`,
  // If silent check-sso is not supported and falls back to regular (non-silent)
  // we disable the fallback to ensure there is no full redirect to the Keycloak server
  // => check-sso will be completely disabled if restrictive browser behavior is detected.
  //
  // see https://www.keycloak.org/docs/latest/securing_apps/#_modern_browsers
  silentCheckSsoFallback: false,
  // `checkLoginIframe: false` https://www.keycloak.org/docs/latest/securing_apps/#session-status-iframe
  //
  // THis disables session checking iframe to have consistent behavior on
  // modern browsers (where iframe might not work).
  // https://www.keycloak.org/docs/latest/securing_apps/#browsers-with-blocked-third-party-cookies
  //
  // With this off, the keycloak JS adapter can NOT use session cookie for Single Sign-Out
  // detection and have to rely purely on tokens. This implies that when user logs out
  // in another window, the application using JavaScript adapter won’t be logged out
  // until it tries to refresh the Access Token. Therefore,
  // !! it is recommended to set Access Token Lifespan to relatively short time,
  // !! so that the logout is detected rather sooner than later.
  // !! see https://www.keycloak.org/docs/latest/server_admin/#_timeouts
  checkLoginIframe: false,
  // We use "check-sso" + "silentCheckSsoRedirectUri" to enable __silent___ check-sso
  // to avoid a full redirect to the keycloak server (we want to handle routing only
  // in React SPA), but this action will be performed in a hidden iframe.
  //
  // check-sso is disabled in Keycloak SPA,
  // as that would break Keycloak due to unsetting certain KC_ cookie
  //
  // https://www.keycloak.org/docs/latest/securing_apps/#methods
  ...(pathname.startsWith("/auth") ? {} : { onLoad: "check-sso" }),
});

const keycloak = Keycloak(options);

// Expose keycloak instance to window.keycloak in development
if (process.env.NODE_ENV === "development") {
  (window as any).keycloak = keycloak;
}

export { getInitOptions };
export default keycloak;
