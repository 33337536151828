import { useCallback, useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { TokenContext } from "src/providers/token-provider";
import { createOfflineActivationCodeDownload, getActivationCode } from "src/utils/activation-code";

export const useDownloadPersonalSubscriptionOfflineActivationCode = () => {
  const { idToken, refreshToken, accessToken } = useContext(TokenContext);
  const keycloak = useKeycloak();

  const downloadPersonalSubscriptionOfflineActivationCode = useCallback(() => {
    if (accessToken && refreshToken && idToken && keycloak.keycloak.clientId === "lens-extension") {
      createOfflineActivationCodeDownload(getActivationCode(accessToken, refreshToken, idToken));
    }

    return null;
  }, [idToken, refreshToken, accessToken, keycloak.keycloak.clientId]);

  return downloadPersonalSubscriptionOfflineActivationCode;
};
