import { PropsWithChildren, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Spinner from "src/components/Spinner/Spinner";
import { backend } from "src/constants";
import { getZendeskUrl } from "src/services/support/zendesk";
import { useLocation } from "react-router-dom";
import type { LocationState } from "src/App";
import styles from "./ZendeskSSO.module.css";

const ZendeskSSO = ({ children }: PropsWithChildren<{}>) => {
  const { keycloak } = useKeycloak();
  const location = useLocation<LocationState | undefined>();
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    let isCancelled = false;
    const fetchZendeskJwtTokenAndRedirect = async () => {
      const zendeskJwtServiceUrl = `${backend}/zendesk-jwt/sso`;
      const requestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak.token}`,
        },
      };

      if (!isCancelled) {
        const response = await fetch(zendeskJwtServiceUrl, requestInit);

        if (response.status < 200 || response.status > 299) {
          setError("Error launching Lens support portal, please verify your subscription validity and try again.");
        } else {
          setError(null);
          const json = await response.json();
          const zendeskJwt = json.access_token;

          // eslint-disable-next-line xss/no-location-href-assign
          window.location.href = getZendeskUrl(zendeskJwt, location);
        }
      }
    };

    fetchZendeskJwtTokenAndRedirect().catch((error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

    return () => {
      isCancelled = true;
    };
  }, [keycloak.token, location]);

  return (
    <div>
      <Spinner center>
        {error ? <span className={styles.errorMessage}>{error}</span> : <span>Launching Lens support portal ...</span>}
        {children}
      </Spinner>
    </div>
  );
};

export default ZendeskSSO;
