import React, { PropsWithChildren, useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";
import clsx from "clsx";
import type { Business, UserWithEmail } from "lens-platform-sdk";
import { SideNav, SideNavGroup, SideNavItem } from "@k8slens/lds";
import { base, navigation } from "@k8slens/lds-icons";

import { useGetBusinesses } from "src/hooks/useGetBusinesses";
import { useAnalytics } from "src/hooks/useAnalytics";
import { TipsContext } from "src/providers/tip-provider";

import { selectIdTipId, selectIdStepId } from "src/components/Tips/id-select";
import LauncherButton from "src/components/LensLauncherButton/LensLauncherButton";

import IDSelect from "./IDSelect";

import styles from "./MainNavigation.module.css";

const breakpointLg = 1024;

export interface Props {
  businessId?: string;
  business?: Business;
  profile: UserWithEmail;
  className?: string;
  logout(): void;
}

const { LogoIcon } = base;
const { MenuIcon } = navigation;

const logoutButtonLabel = "Log Out";

const MainNavigation: React.FC<PropsWithChildren<Props>> = ({
  businessId,
  business,
  profile,
  logout,
  className,
  children,
}) => {
  const { trackButtonClicked } = useAnalytics();
  const { businesses, loading: loadingBusinesses, reload: reloadBusinesses } = useGetBusinesses();
  const isBusinessID = Boolean(businessId);

  const { hideStep, showStep } = useContext(TipsContext);

  const sidebarRef = useRef<null | HTMLDivElement>(null);
  const toggleButtonRef = useRef<null | HTMLDivElement>(null);
  const idSelectRef = useRef<null | HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleLogout = useCallback(() => {
    trackButtonClicked(logoutButtonLabel);
    logout();
  }, [trackButtonClicked, logout]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const targetEl = e.target && "className" in e.target ? (e.target as Element) : null;

      const targetIsSidebar = sidebarRef?.current && sidebarRef.current.contains(targetEl);
      const targetIsIdSelectRef = idSelectRef?.current && idSelectRef.current.contains(targetEl);
      const targetIsToggleButton = sidebarRef?.current && (toggleButtonRef.current as any).contains(targetEl);
      const targetIsTip = Boolean(targetEl && targetEl.className.match && targetEl.className.match(/lds-tip/));
      const isScreenLg = window.innerWidth >= breakpointLg;

      if (targetIsToggleButton) {
        setOpen(!open);

        return;
      }

      if (!isScreenLg && targetIsSidebar) {
        if (!open) {
          // Sidebar closed, open it and prevent any bubbling
          setOpen(true);

          return;
        }

        // Don't close the sidebar if the user clicked on the IDSelect
        if (!targetIsIdSelectRef) {
          // Sidebar open, close it
          setOpen(false);

          return;
        }
      }

      if (!isScreenLg && open && !targetIsSidebar && !targetIsIdSelectRef && !targetIsTip) {
        // Clicked outside when open –> close sidebar
        setOpen(false);
      }
    },
    [open],
  );

  // Update all businesses if current business has changed
  useEffect(() => {
    if (business) {
      reloadBusinesses();
    }
  }, [business, reloadBusinesses]);

  /**
   * Ensure `open` is set to correct boolean by window.innerWidth value
   */
  const ensureOpenOnLg = useCallback(() => {
    if (window.innerWidth >= breakpointLg) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const handleResize = useCallback(() => {
    // CLose menu when resizing, opens when resizing back to lg
    ensureOpenOnLg();
  }, [ensureOpenOnLg]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClick, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClick);
    };
  }, [handleResize, handleClick]);

  /**
   * Ensure `open` is set to correct value on mount
   */
  useEffect(() => {
    ensureOpenOnLg();
  }, [ensureOpenOnLg]);

  const userName = useMemo(() => {
    const currentBusiness = businesses.find(({ id }) => id === businessId);

    if (currentBusiness?.id) {
      if (currentBusiness) {
        // set selected as the business id from /business/:businessId
        // on component mounts
        return currentBusiness.name;
      }

      return "";
    }

    return `@${profile.username}`;
  }, [profile, businessId, businesses]);

  useEffect(() => {
    let mounted = true;

    if (hideStep && showStep) {
      if (open) {
        // Wait for the animation to finish
        setTimeout(() => {
          if (mounted) {
            showStep(selectIdStepId);
          }
        }, 250);
      } else {
        hideStep(selectIdStepId);
      }
    }

    return () => {
      mounted = false;
    };
  }, [hideStep, showStep, open]);

  return (
    <>
      <div className={styles.topbar}>
        <div className={styles.username} aria-hidden>
          {userName}
        </div>
        <h1 className={styles.logo}>
          <LogoIcon color="accent" />
          {isBusinessID ? "Lens Business ID" : "Lens ID"}
        </h1>
        <div ref={toggleButtonRef} className={styles.toggle} aria-hidden>
          <MenuIcon />
        </div>
      </div>
      <div className={clsx(styles.sidebarWrapper, className)}>
        <div className={clsx(styles.sidebar, { [styles.open]: open })} ref={sidebarRef}>
          <h1 className={styles.logoOnSideNav}>
            <LogoIcon color="accent" />
            {isBusinessID ? "Lens Business ID" : "Lens ID"}
          </h1>
          <div className={styles.toggle} aria-hidden>
            <MenuIcon />
          </div>
          <div id={open ? selectIdTipId : undefined} ref={idSelectRef}>
            <IDSelect
              businessId={businessId}
              profile={profile}
              sideBarOpen={open}
              businesses={businesses}
              loadingBusinesses={loadingBusinesses}
              reloadBusiness={reloadBusinesses}
            />
          </div>
          <SideNav className={styles.navigation}>
            {children}
            <SideNavGroup aria-label="Actions">
              <LauncherButton />
              <SideNavItem componentProps={{ onClick: handleLogout, role: "button", href: "#" }} type="danger">
                {logoutButtonLabel}
              </SideNavItem>
            </SideNavGroup>
          </SideNav>
        </div>
      </div>
    </>
  );
};

export default MainNavigation;
