import { LoadingIndicator } from "@k8slens/lds";
import { Redirect } from "react-router-dom";
import { useGetBusinesses } from "src/hooks/useGetBusinesses";

export const HomeOrBusiness = () => {
  const { loading, businesses, isFetched } = useGetBusinesses();

  if (!isFetched || loading) {
    return <LoadingIndicator size="2xl" />;
  }

  if (businesses.length > 0) {
    return (
      <Redirect
        to={{
          pathname: "/business",
          state: { referrer: "/" },
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/home",
        state: { referrer: "/" },
      }}
    />
  );
};
