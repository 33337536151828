import { Redirect, useLocation } from "react-router-dom";
import { useAnalytics } from "src/hooks/useAnalytics";

export const SubscribeForTeamsPage = () => {
  const { track } = useAnalytics();
  const location = useLocation();

  track("Legacy path '/subscribe-for-teams'", { location });
  const to = {
    ...location,
    pathname: location.pathname.replace(/^\/subscribe-for-teams/, "/subscribe"),
  };

  return <Redirect to={to} />;
};
