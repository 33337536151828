import { useKeycloak } from "@react-keycloak/web";
import pick from "lodash/pick";
import { useCallback, useContext, useEffect } from "react";
import { LicensedToken } from "src/components/pages/Home/license";
import { AnalyticsContext } from "src/providers/analytics-provider";

export const useAnalyticIdentifyUser = () => {
  const { analytics } = useContext(AnalyticsContext);
  const {
    keycloak: { tokenParsed },
  } = useKeycloak();

  const identifyUser = useCallback(
    (token: LicensedToken | null) => {
      const currentId = analytics?.user().id();
      const { sub: id, ...user } = pick(token, [
        "license_type",
        "license_exp",
        "license_iat",
        "license_trial",
        "preferred_username",
        "email",
        "email_verified",
        "sub",
      ]);

      if (currentId === id) {
        // No change in user
        return Promise.resolve();
      }

      if (id) {
        // User updated, has id, identify
        return analytics?.identify(id, { id, ...user }, { context: { direct: true } }).catch(() => {});
      }

      // User updated, no id, unidentify (reset)
      return analytics?.reset();
    },
    [analytics],
  );

  useEffect(() => {
    if (tokenParsed && analytics?.user().id() !== tokenParsed.sub) {
      // Track changes in user
      identifyUser(tokenParsed as unknown as LicensedToken);
    }
  }, [analytics, identifyUser, tokenParsed]);
};
