import { useCallback } from "react";
import { misc } from "@k8slens/lds-icons";
import { FormInput, common } from "@k8slens/lds-form";

import { passwordValidator } from "src/services/validators";

import formStyles from "src/components/styles/form.module.css";

const { defaultErrorParser } = common;

const { EmailIcon, LockIcon, PersonIcon } = misc;

interface CredentialsType {
  username: {
    value: string;
    valid?: boolean;
  };
  firstName: {
    value: string;
    valid?: boolean;
  };
  lastName: {
    value: string;
    valid?: boolean;
  };
  password: {
    value: string;
    valid?: boolean;
  };
  email: {
    value: string;
    valid?: boolean;
  };
}

type Props = {
  credentials: CredentialsType;
  onCredentialsChange: (name: string, value: string, valid: boolean) => void;
  signingUpFromGithub: boolean;
  signingUpFromGoogle: boolean;
};

const userNameLabel = "Username";
const firstNameLabel = "First Name";
const lastNameLabel = "Last Name";
const passwordLabel = "Password";
const emailLabel = "Email";

const Credentials = ({ credentials, onCredentialsChange, signingUpFromGithub, signingUpFromGoogle }: Props) => (
  <>
    <FormInput
      id="username"
      name="username"
      label={userNameLabel}
      type="text"
      value={credentials.username?.value}
      onChange={(value, valid) => onCredentialsChange("username", value, valid)}
      minLength={3}
      autoComplete="username"
      pattern="[a-zA-Z\d_-]{0,}"
      required
      className={formStyles.input}
      icon={PersonIcon}
      errorParser={useCallback((value: string, error: string) => {
        if (error === "patternMismatch") {
          return 'Allowed characters A-Z, a-z, 0-9, "_" and "-".';
        }

        return defaultErrorParser(
          value,
          error,
          {
            minLength: 3,
          },
          userNameLabel,
        );
      }, [])}
    />
    <FormInput
      id="firstName"
      name="firstName"
      label={firstNameLabel}
      type="text"
      value={credentials.firstName?.value}
      onChange={(value, valid) => onCredentialsChange("firstName", value, valid)}
      autoComplete="given-name"
      required
      className={formStyles.input}
      icon={PersonIcon}
    />
    <FormInput
      id="lastName"
      name="lastName"
      label={lastNameLabel}
      type="text"
      value={credentials.lastName?.value}
      onChange={(value, valid) => onCredentialsChange("lastName", value, valid)}
      autoComplete="family-name"
      required
      className={formStyles.input}
      icon={PersonIcon}
    />
    <FormInput
      label={passwordLabel}
      type="password"
      value={credentials.password?.value || ""}
      name="password"
      id="password"
      autoComplete="new-password"
      required
      onChange={(value, valid) => onCredentialsChange("password", value, valid)}
      icon={LockIcon}
      validate={useCallback(
        (value: string) =>
          value?.length > 0 && !passwordValidator.validate(value as string) ? "invalidPassword" : undefined,
        [],
      )}
      errorParser={useCallback((value: string, error: string) => {
        if (error === "invalidPassword") {
          return passwordValidator.message();
        }

        return defaultErrorParser(value, error, {}, passwordLabel);
      }, [])}
    />
    <FormInput
      label={emailLabel}
      type="email"
      value={credentials.email?.value}
      role="textbox"
      name="email"
      id="email"
      autoComplete="email"
      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
      required
      onChange={(value, valid) => onCredentialsChange("email", value, valid)}
      disabled={signingUpFromGithub || signingUpFromGoogle}
      icon={EmailIcon}
      errorParser={useCallback((value: string, error: string) => {
        if (error !== "valueMissing") {
          return "Email is not valid.";
        }

        return defaultErrorParser(value, error, {}, emailLabel);
      }, [])}
    />
  </>
);

export type { CredentialsType };
export default Credentials;
