import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import Page from "src/components/Page/Page";
import { DiscourseSSO } from "./DiscourseSSO";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";

const Discuss = () => {
  const keycloakTimeout = useKeycloakTimeout();

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  return (
    <Page>
      <DiscourseSSO />
    </Page>
  );
};

export default Discuss;
