import { ChangeEvent, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { RadioGroup as HLessRadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { Panel, ButtonBar, Input } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getPlanData, getPriceData, PlanCode } from "src/services/plans";
import { formatCurrency } from "src/utils/currency";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedLinkButton";
import FeatureList from "src/components/FeatureList/FeatureList";
import LicenseImage from "src/components/LicenseImage/LicenseImage";
import { RadioGroup, RadioGroupOption } from "src/components/RadioGroup/RadioGroup";
import {
  newSubscriptionForBusinesURLSearchParamsKey,
  useQueryNewSubscriptionForBusiness,
} from "src/hooks/useQueryNewSubscriptionForBusiness";

import OrderSummary from "./OrderSummary";
import ChoosePlanAside from "./ChoosePlan/ChoosePlanAside";

import styles from "./Configure.module.css";

const checkoutActionLabel = "Continue";

export interface Props {
  loading?: boolean;
  planCode: PlanCode;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  onConfigure(planCode: PlanCode): Promise<boolean>;
}

const seatButtons = new Array(5).fill(0).map((_, i) => i + 1);
const inputMin = seatButtons[seatButtons.length - 1] + 1;

const isValidSeatQuantity = (quantity: number | undefined) => Boolean(quantity && quantity >= inputMin);

const Configure: React.FC<Props> = ({
  loading,
  planCode,
  quantity: seatCount,
  setQuantity: setSeatCount,
  onConfigure,
}) => {
  const { track } = useAnalytics();
  const history = useHistory();
  const newSubscriptionForBusiness = useQueryNewSubscriptionForBusiness();

  const planData = getPlanData(planCode);
  const priceData = getPriceData(planCode);

  // Seat input
  const initialInputValue = seatCount >= inputMin ? seatCount : undefined; // Remember val when switching between plans
  const [showSeatCountInput, setShowSeatCountInput] = useState(Boolean(initialInputValue));

  const [seatInputValue, setSeatInputValue] = useState<number | undefined>(initialInputValue);
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);

      setSeatInputValue(value);

      // Fall back to previous or default value if input is invalid
      const prevValue = seatCount && seatCount < inputMin && seatCount > 0 ? seatCount : inputMin - 1;
      const nextValue = isValidSeatQuantity(value) ? value : prevValue;

      setSeatCount(nextValue);
      prevValue !== nextValue && track("Set Seat Count", { value: nextValue, source: "input" });
    },
    [track, setSeatCount, seatCount],
  );
  const handleInputFocus = useCallback(() => {
    // Fall back to previous or default value if input is invalid
    const prevValue = seatCount && seatCount < inputMin && seatCount > 0 ? seatCount : inputMin - 1;
    const nextValue = seatInputValue && isValidSeatQuantity(seatInputValue) ? seatInputValue : prevValue;

    setSeatCount(nextValue);
    prevValue !== nextValue && track("Set Seat Count", { value: nextValue, source: "input" });
  }, [track, seatInputValue, setSeatCount, seatCount]);

  const handleSeatCountClick = useCallback(
    (value: number) => {
      setSeatCount(value);
      track("Set Seat Count", { value, source: "button" });
    },
    [track, setSeatCount],
  );

  const handleChoosePlan = useCallback(() => onConfigure(planCode), [planCode, onConfigure]);

  const handleBillingCycleChange = useCallback(
    (planCode: string) => {
      if (newSubscriptionForBusiness) {
        // keep newSubscriptionForBusiness when switching plan if it is a string
        history.push(
          `/subscribe/${planCode}/configure?${newSubscriptionForBusinesURLSearchParamsKey}=${newSubscriptionForBusiness}`,
        );
      } else {
        history.push(`/subscribe/${planCode}/configure`);
      }
    },
    [history, newSubscriptionForBusiness],
  );

  return (
    <Panel
      className={styles.configure}
      footer={
        <>
          <LinkButton
            to="/subscribe"
            label="Choose another plan"
            onClick={() => {
              // Reset seats
              setSeatCount(1);
            }}
            discreet
            textTransform="uppercase"
          />
          <Button
            loading={loading}
            label={checkoutActionLabel}
            primary
            textTransform="uppercase"
            loadingStateOnPromise
            onClick={handleChoosePlan}
          />
        </>
      }
      footerComponent={ButtonBar}
      footerProps={{
        as: "footer",
        className: "mt-0",
      }}
    >
      <ChoosePlanAside panelClassName={styles.aside} />
      <section className={styles.planInfo}>
        <LicenseImage type={planData.type === "pro" ? "pro" : "personal"} className={styles.licenseImage} />
        <FeatureList
          className={styles.featureList}
          title={`Included in Lens ${planData.nameShort}`}
          features={planData.features}
        />
      </section>
      <section className={styles.seats}>
        <h4>Seats</h4>
        <p>Choose the number of seats. A seat per user.</p>
        <div className={styles.seatCount}>
          <HLessRadioGroup
            className={styles.seatCountRadios}
            value={seatCount}
            onChange={handleSeatCountClick}
            aria-describedby="seatDescription"
            aria-label="Choose from one to five seats"
          >
            {seatButtons.map((value) => (
              <HLessRadioGroup.Option key={value} value={value} aria-label={`${value} seat${value > 1 ? "s" : ""}`}>
                {({ checked }) => (
                  <div
                    aria-hidden
                    className={clsx(styles.seatCountButton, {
                      [styles.active]: checked,
                    })}
                  >
                    {value}
                  </div>
                )}
              </HLessRadioGroup.Option>
            ))}
          </HLessRadioGroup>
          {!showSeatCountInput && (
            <Button
              className={clsx(styles.showInput)}
              label={<span aria-hidden>+</span>}
              aria-label="Add more seats manually"
              discreet
              onClick={() => {
                setShowSeatCountInput(true);
              }}
            />
          )}
          {showSeatCountInput && (
            <Input
              autoFocus={seatInputValue === undefined}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(seatInputValue !== undefined && !isValidSeatQuantity(seatInputValue)
                ? {
                    "aria-invalid": true,
                  }
                : {})}
              type="number"
              min={inputMin}
              aria-label="Type in the number of seats. Click to select again."
              value={seatInputValue || ""}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className={clsx(styles.seatCountInput, {
                [styles.active]: seatCount === seatInputValue,
              })}
            />
          )}
          <p id="seatDescription" className={styles.pricePerSeat}>
            <span>{formatCurrency(priceData.value)}</span>
            <span>{priceData.perSeatText}</span>
          </p>
        </div>
      </section>
      {(planCode === "pro-monthly" || planCode === "pro-yearly" || planCode === "pro-trial") && (
        <section className={styles.billingCycle}>
          <h4>Payment plan</h4>
          <p>Choose the payment plan that&apos;s right for you.</p>
          <RadioGroup value={planCode} onChange={handleBillingCycleChange}>
            <RadioGroupOption value="pro-monthly" className={styles.billingOption}>
              <div aria-label="Pro plan prepaid monthly">
                <strong aria-hidden>Monthly</strong>
                <span aria-hidden>Prepaid monthly</span>
              </div>
              <div>
                <strong aria-label={`for the price of ${formatCurrency(planData.price.monthly.value)}`}>
                  {formatCurrency(planData.price.monthly.value)}
                </strong>
                <span>per seat / month</span>
              </div>
            </RadioGroupOption>
            <RadioGroupOption value="pro-yearly" className={styles.billingOption}>
              <div aria-label="Pro plan prepaid annually">
                <strong aria-hidden>Annual</strong>
                <span aria-hidden>Prepaid yearly</span>
              </div>
              <div>
                <strong aria-label={`for the price of ${formatCurrency(planData.price.annual.value)}`}>
                  {formatCurrency(planData.price.annual.value)}
                </strong>
                <span>per seat / year</span>
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </section>
      )}
      <OrderSummary
        quantity={seatCount}
        planCode={planCode}
        onPurchase={handleChoosePlan}
        panelClassName={styles.orderSummary}
      />
    </Panel>
  );
};

export default Configure;
