import { PropsWithChildren, useCallback, useEffect } from "react";
import { Button, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";

import formStyles from "src/components/styles/form.module.css";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { ReactComponent as LockIcon } from "src/img/lock.svg";
import { ReactComponent as PersonIcon } from "src/img/person.svg";

import styles from "./SignInForm.module.css";

const forgotPasswordLabel = "Forgot Password?";

type Props = PropsWithChildren<{
  username: string;
  password: string;

  /** Label for login button */
  loginButtonLabel: string;

  /** Action url for the form */
  action?: string;

  loginDisabled?: boolean;
  isLoggingIn?: boolean;
  isRedirecting: boolean;
  loginError?: string;
  onUsernameChange?: (username: string) => void;
  onPasswordChange?: (password: string) => void;
  onLogin?: (event: React.FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  inApp?: boolean;
  resendEmailButton: React.ReactNode;
  resetPasswordUrl: string | null | undefined;
}>;

const SignInForm = ({
  username,
  password,
  loginButtonLabel,
  action = "",
  loginDisabled = false,
  isLoggingIn = false,
  isRedirecting,
  loginError = "",
  onUsernameChange,
  onPasswordChange,
  onLogin,
  resendEmailButton,
  resetPasswordUrl,
  inApp,
  disabled,
}: Props) => {
  const { trackButtonClicked } = useAnalytics();

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (onLogin) {
        onLogin(event);
      }
    },
    [onLogin],
  );

  const handleUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onUsernameChange) {
        onUsernameChange(event.target.value);
      }
    },
    [onUsernameChange],
  );
  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onPasswordChange) {
        onPasswordChange(event.target.value);
      }
    },
    [onPasswordChange],
  );

  useEffect(() => {
    let ignore = false;
    const restoreCredentials = async () => {
      // @ts-expect-error missing type definitions, but window.PasswordCredential is valid in Chrome
      if (window.PasswordCredential) {
        const credential = await window.navigator.credentials?.get?.({
          // @ts-expect-error missing type definitions, https://developer.mozilla.org/en-US/docs/Web/API/CredentialsContainer/get
          // a boolean value indicating that returned Credential instances should include user.
          password: true,
        });

        // we save id with value from username as id (see <SignUp />)
        if (credential?.id && onUsernameChange && !ignore) {
          onUsernameChange(credential.id);
        }

        // @ts-expect-error password is valid in PasswordCredential https://developer.mozilla.org/en-US/docs/Web/API/PasswordCredential
        const password = credential?.password;

        if (password && onPasswordChange && !ignore) {
          onPasswordChange(password);
        }
      }
    };

    // eslint-disable-next-line no-console
    restoreCredentials().catch((error) => {
      console.warn(error);
    });

    return () => {
      ignore = true;
    };
  }, [onUsernameChange, onPasswordChange]);

  const forgotPasswordTarget = inApp ? "_blank" : undefined;

  return (
    <form
      className={styles.signInForm}
      onSubmit={handleLogin}
      action={action}
      method="post"
      autoComplete="on"
      data-testid="signin-form"
    >
      {loginError && (
        <Notification
          className={styles.error}
          level="error"
          type={resendEmailButton ? "default" : "flash"}
          message={
            <div className={styles.errorMessage}>
              {loginError}
              {resendEmailButton}
              {/* ^resendEmailButton will be null if emailVerificationNeeded is false in <SignIn /> */}
            </div>
          }
        />
      )}
      <fieldset disabled={disabled}>
        <div>
          {/* TODO: use <label /> for better accessibility */}
          <span className={formStyles.inputLabel}>Username or Email Address</span>
          <div className={formStyles.inputWrapper}>
            <input
              type="text"
              value={username}
              role="textbox"
              id="username"
              name="username"
              aria-label="username"
              autoComplete="username"
              onChange={handleUsernameChange}
              className={styles.input}
            />
            <PersonIcon className={formStyles.icon} />
          </div>

          <span className={formStyles.inputLabel}>Password</span>
          <div className={formStyles.inputWrapper}>
            <input
              type="password"
              value={password}
              id="password"
              name="password"
              aria-label="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              className={styles.input}
            />
            <LockIcon className={formStyles.icon} />
          </div>
        </div>
        <div className={styles.buttonRow}>
          <span className={styles.resetPasswordLink}>
            {/* Don't use `<Link />` from react-router-dom here */}
            {/* because we need to go "outside" of `<App />` */}
            {/* and to keycloak template (`<KeycloakApp />`) */}
            {resetPasswordUrl ? (
              <TrackedAnchor
                href={resetPasswordUrl}
                className={formStyles.link}
                target={forgotPasswordTarget}
                rel="noreferrer"
              >
                {forgotPasswordLabel}
              </TrackedAnchor>
            ) : null}
          </span>
          <Button
            className={styles.loginBtn}
            loading={isRedirecting || isLoggingIn}
            buttonType="submit"
            primary
            disabled={loginDisabled}
            label={loginButtonLabel}
            onClick={() => {
              trackButtonClicked(loginButtonLabel);

              return true;
            }}
          />
        </div>
      </fieldset>
    </form>
  );
};

export default SignInForm;
