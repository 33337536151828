import { useEffect, useState } from "react";
import { Invitation } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useInvitationKey } from "./useInvitationKey";

/**
 * Get the Invitation using the invitation key
 */
export const useInvitation = () => {
  const lensPlatformClient = useLensPlatformClient();
  const invitationKey = useInvitationKey();

  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let cancelled = false;
    const fetchData = async () => {
      try {
        const invitation = await lensPlatformClient.invitation.getOneByKey({
          key: invitationKey,
        });

        if (cancelled === false) {
          setInvitation(invitation);
          setError(null);
        }
      } catch (error: any) {
        if (cancelled === false) {
          setInvitation(null);
          setError(error);
        }
      }
    };

    if (invitationKey) {
      fetchData();
    }

    return () => {
      cancelled = true;
    };
  }, [lensPlatformClient.space, lensPlatformClient.invitation, invitationKey]);

  return { invitation, error };
};
