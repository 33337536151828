import { decodeBase64, encodeBase64 } from "src/utils/base64";

export type SearchParameter = ReturnType<URLSearchParams["get"]>;

export type Activation = {
  subscriptionId: SearchParameter;
  planCode: SearchParameter;
  planName: SearchParameter;
  company: SearchParameter;
  invitationId?: SearchParameter;
  businessId?: SearchParameter;
};

/**
 * Encode `Activation` into a based64 string.
 * Return empty string if `Activation` has no truthy values.
 */
export const encodeActivationKey = (activation: Activation) => {
  // only activation with subscriptionId is valid activation
  if (activation.subscriptionId) {
    return encodeBase64(activation);
  }

  return "";
};

/**
 * Decode the based-encoded activation key back to `Partial<ActivationData>`.
 */
export const decodeActivationKey = (activationKey: ReturnType<typeof encodeActivationKey>): Partial<Activation> =>
  decodeBase64(activationKey);
