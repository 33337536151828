import { createContext, PropsWithChildren } from "react";

import { Tips as LdsTips } from "@k8slens/lds-tips";

import { TipStore, useTipStore } from "src/hooks/useTipStore";
import { useAnalytics } from "src/hooks/useAnalytics";

import tipTours from "src/components/Tips/tours";

export const TipsContext = createContext<Partial<TipStore>>({});

const TipsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const store = useTipStore(tipTours);

  const { track } = useAnalytics();

  return (
    <TipsContext.Provider value={store}>
      {children}
      <LdsTips
        setNextStepNumber={store.setNextStepNumber}
        getActiveStep={store.getActiveStep}
        setSkipAll={store.setSkipAll}
        skipAll={store.skipAll}
        onPointerClick={(tourId, step) => track("Tip Opened", { tourId, tip: step.id })}
        onCloseTip={(tourId, step) => track("Tip Closed", { tourId, tip: step.id })}
        onNextClick={(tourId, step) => track("Tip Cleared", { tourId, tip: step.id })}
        onSkipAllClick={(tourId, step) => track("Skipped All Tips", { tourId, tip: step.id })}
        tours={store.tours}
      />
    </TipsContext.Provider>
  );
};

export default TipsProvider;
