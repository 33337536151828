import { parseUrl, stringifyUrl } from "query-string";
import { LoginOrigin } from "src/components/SignIn/SignIn";
import { invitationKeyParameter } from "src/hooks/useInvitationKey";
import { activationKeyParameter } from "src/hooks/useActivationKey";
import { purchaseQuantityKeyParameter } from "src/hooks/usePurchaseQuantityKey";
import { PassThroughKeys } from "src/hooks/usePassThroughKeys";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { businessInvitationKeyParameter } from "src/hooks/useBusinessInvitationKey";
import { businessChildInvitationTokenParameter } from "src/hooks/useBusinessChildInvitationToken";
import { isUUID } from "class-validator";

export const addSearchParams = (
  path: string,
  params: Array<{
    key:
      | typeof invitationKeyParameter
      | typeof activationKeyParameter
      | typeof purchaseQuantityKeyParameter
      | typeof businessInvitationKeyParameter
      | typeof businessChildInvitationTokenParameter;
    value?: PassThroughKeys[keyof PassThroughKeys];
  }>,
  queryParameters?: Record<string, string | number | boolean>,
) => {
  const { url, query } = parseUrl(path);

  return stringifyUrl({
    url,
    query: {
      ...query,
      ...params.reduce(
        (o, { key, value }) => ({
          ...o,
          ...(key && value ? { [key]: value } : {}),
        }),
        {},
      ),
      ...queryParameters,
    },
  });
};

export const openUrl = (url: string, isNewTab?: boolean) => {
  if (isNewTab) {
    window.open(url);
  } else {
    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = url;
  }
};

const urlParameter = {
  google: "loggingInFromGoogle",
  github: "loggingInFromGithub",
};

export const getSignInRedirectUri = (
  redirectUri: string | undefined,
  loginOrigin: LoginOrigin,
  passThroughKeys: PassThroughKeys,
) => {
  // Only set redirectUri to home if it's not set (by e.g. the extension for redirecting to local callback server)
  if (redirectUri) {
    return undefined;
  }

  // is IDP hint is UUID - the login is with business SSO, where IDPHint=businessID
  if (isUUID(loginOrigin)) {
    return `${window.location.origin}${addPassThroughKeys("/home", passThroughKeys, {
      businessSSOLogin: loginOrigin,
    })}`;
  } else if (loginOrigin === "google" || loginOrigin === "github") {
    return `${window.location.origin}${addPassThroughKeys("/home", passThroughKeys, {
      [urlParameter[loginOrigin]]: true,
    })}`;
  }
};
