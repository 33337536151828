import { memo } from "react";

import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

export interface Props {
  termsAccepted: boolean;
  className?: string;
}

const Terms = ({ termsAccepted, className }: Props) => (
  <div className={className}>
    <label htmlFor="accept-terms">
      {"By creating an account, I agree to the "}
      <TrackedAnchor rel="noreferrer" target="_blank" href="https://k8slens.dev/legal/tos">
        Terms of Service
      </TrackedAnchor>
      {" and "}
      <TrackedAnchor rel="noreferrer" target="_blank" href="https://www.mirantis.com/company/privacy-policy/">
        Privacy Policy
      </TrackedAnchor>
      .
    </label>
  </div>
);

export default memo(Terms);
