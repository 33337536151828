import type { CredentialsType } from "./Credentials";
import { FormState, SignupState } from "./form";

type Arguments = {
  formState: FormState;
  signupState: SignupState;
  credentials: CredentialsType;
  termsAccepted: boolean;
  resendingEmail: boolean;
  error: string | undefined;
};

/**
 * Return boolean if navigation between the steps of <SignUpForm />
 * should be disabled.
 */
const getNavDisabled = ({ formState, signupState, credentials, termsAccepted, resendingEmail }: Arguments) => {
  switch (formState) {
    case "credentials":
      if (!termsAccepted) {
        return true;
      }

      if (Object.values(credentials).some((field) => !field.valid)) {
        return true;
      }

      if (
        signupState === "signing-up" ||
        signupState === "signing-up-with-github" ||
        signupState === "signing-up-with-google"
      ) {
        return true;
      }

      return false;

    case "complete":
      if (resendingEmail) {
        return true;
      }

      return false;
    default:
      return false;
  }
};

export default getNavDisabled;
