import { LensPlatformClient } from "lens-platform-sdk";
import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { options as keycloakOptions } from "../services/keycloak";
import { backend } from "../constants";

export const useLensPlatformClient = () => {
  // Note: keycloak might not be initialized when LensPlatformClient calls getAccessToken
  const { keycloak } = useKeycloak();

  const lensPlatformClient = useMemo(
    () =>
      new LensPlatformClient({
        getAccessToken: async () => keycloak.token ?? "",
        keycloakRealm: keycloakOptions.realm,
        keyCloakAddress: keycloakOptions.url,
        apiEndpointAddress: backend ?? "",
      }),
    [keycloak.token],
  );

  return lensPlatformClient;
};
