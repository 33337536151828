export const origin = process.env.REACT_APP_KEYCLOAK_URL || window.origin;
export const originURL = new URL(origin);

export const getACSUrl = (entityID: string | undefined) =>
  entityID && `${origin}/auth/realms/lensCloud/broker/${entityID}/endpoint`;

export const getSamlSSOEntityID = (entityID: string | undefined) => entityID && `${origin}/auth/realms/lensCloud`;

export const getSSOLoginUrlByBusinessHandle = (businessHandle: string | undefined) =>
  businessHandle && `https://${originURL.host}/sso/${businessHandle}`;
export const getBusinessUrlByBusinessHandle = (businessHandle: string | undefined) =>
  businessHandle && `https://${businessHandle}.id.${originURL.host}`;
