import { useEffect, useState } from "react";
import customProtocolCheck from "custom-protocol-check";
import { Panel } from "@k8slens/lds";

import { getCustomProtocolURL, openLens } from "src/services/lens-app";
import { useAnalytics } from "src/hooks/useAnalytics";

import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LensDownloadSelect from "src/components/LensDownloadSelect/LensDownloadSelect";

import styles from "./LensLauncher.module.css";

export interface Props {
  launchOnStart?: boolean;
}

const LensLauncher = ({ launchOnStart = true }) => {
  const { track } = useAnalytics();
  const [autoLaunched, setAutoLaunched] = useState(false);

  useEffect(() => {
    if (launchOnStart && !autoLaunched) {
      customProtocolCheck(
        getCustomProtocolURL(),
        () => {
          // Lens not found
          track("Lens Desktop not found");
        },
        () => {
          // Lens launched, does this ever fire?
          track("Lens Desktop launched automatically");
        },
        5000,
      );
      setAutoLaunched(true);
    }
  }, [launchOnStart, autoLaunched, track]);

  return (
    <PublicLayout
      size="xl"
      footer={
        <div>
          Lens Desktop not starting automatically?
          <button
            className={styles.launchButton}
            type="button"
            aria-label="Launch Lens Desktop Manually"
            onClick={openLens}
          >
            Launch Lens Desktop Manually
          </button>
        </div>
      }
    >
      <Panel
        className={styles.lensLauncher}
        header={
          <>
            <h2>Lens Desktop Web Launcher</h2>
            <p>If you have Lens Desktop installed, it should open automatically in a moment.</p>
          </>
        }
        footer={
          <>
            <p className="text-right">Don’t have Lens Desktop installed?</p>
            <LensDownloadSelect />
          </>
        }
      >
        <img src="static/media/rocket-robot.svg" alt="Launching lens…" aria-hidden />
      </Panel>
    </PublicLayout>
  );
};

export default LensLauncher;
